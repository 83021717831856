import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { Col, Container, Row, Input, Label, Form, Button, Spinner } from "reactstrap"
import SearchDebounce from "./SearchDebounce";

var Languages = [
    {
        "language": "af",
        "language_name": "Afrikaans",
    },
    {
        "language": "ar",
        "language_name": "Arabic",
    },
    {
        "language": "az",
        "language_name": "Azerbaijani",
    },
    {
        "language": "ba",
        "language_name": "Bashkir",
    },
    {
        "language": "be",
        "language_name": "Belarusian",
    },
    {
        "language": "bg",
        "language_name": "Bulgarian",
    },
    {
        "language": "bn",
        "language_name": "Bengali",
    },
    {
        "language": "bs",
        "language_name": "Bosnian",
    },
    {
        "language": "ca",
        "language_name": "Catalan",
    },
    {
        "language": "cnr",
        "language_name": "Montenegrin",
    },
    {
        "language": "cs",
        "language_name": "Czech",
    },
    {
        "language": "cv",
        "language_name": "Chuvash",
    },
    {
        "language": "cy",
        "language_name": "Welsh",
    },
    {
        "language": "da",
        "language_name": "Danish",
    },
    {
        "language": "de",
        "language_name": "German",
    },
    {
        "language": "el",
        "language_name": "Greek",
    },
    {
        "language": "en",
        "language_name": "English",
    },
    {
        "language": "eo",
        "language_name": "Esperanto",
    },
    {
        "language": "es",
        "language_name": "Spanish",
    },
    {
        "language": "et",
        "language_name": "Estonian",
    },
    {
        "language": "eu",
        "language_name": "Basque",
    },
    {
        "language": "fa",
        "language_name": "Persian",
    },
    {
        "language": "fi",
        "language_name": "Finnish",
    },
    {
        "language": "fr",
        "language_name": "French",
    },
    {
        "country_code": "CA",
        "direction": "left_to_right",
        "identifiable": false,
        "language": "fr-CA",
        "language_name": "French (Canada)",
        "native_language_name": "Fran\u00e7ais Qu\u00e9b\u00e9cois",

    },
    {
        "country_code": "IE",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "ga",
        "language_name": "Irish",
        "native_language_name": "Gaeilge",

    },
    {
        "country_code": "IN",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "gu",
        "language_name": "Gujarati",
        "native_language_name": "\u0a97\u0ac1\u0a9c\u0ab0\u0abe\u0aa4\u0ac0",

    },
    {
        "country_code": "IL",
        "direction": "right_to_left",
        "identifiable": true,
        "language": "he",
        "language_name": "Hebrew",
        "native_language_name": "\u05e2\u05d1\u05e8\u05d9\u05ea",

    },
    {
        "country_code": "IN",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "hi",
        "language_name": "Hindi",
        "native_language_name": "\u0939\u093f\u0928\u094d\u0926\u0940",

    },
    {
        "country_code": "HR",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "hr",
        "language_name": "Croatian",
        "native_language_name": "Hrvatski",

    },
    {
        "country_code": "HT",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "ht",
        "language_name": "Haitian",
        "native_language_name": "Krey\u00f2l Ayisyen",

    },
    {
        "country_code": "HU",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "hu",
        "language_name": "Hungarian",
        "native_language_name": "Magyar Nyelv",

    },
    {
        "country_code": "AM",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "hy",
        "language_name": "Armenian",
        "native_language_name": "\u0570\u0561\u0575\u0565\u0580\u0565\u0576",

    },
    {
        "country_code": "ID",
        "direction": "left_to_right",
        "identifiable": false,
        "language": "id",
        "language_name": "Indonesian",
        "native_language_name": "Bahasa Indonesia",

    },
    {
        "country_code": "IS",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "is",
        "language_name": "Icelandic",
        "native_language_name": "\u00cdslenska",

    },
    {
        "country_code": "IT",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "it",
        "language_name": "Italian",
        "native_language_name": "Italiano",

    },
    {
        "country_code": "JP",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "ja",
        "language_name": "Japanese",
        "native_language_name": "\u65e5\u672c\u8a9e",
        "supported_as_source": true,
        "supported_as_target": true,
        "words_separated": false
    },
    {
        "country_code": "GE",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "ka",
        "language_name": "Georgian",
        "native_language_name": "\u10e5\u10d0\u10e0\u10d7\u10e3\u10da\u10d8",

    },
    {
        "country_code": "KZ",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "kk",
        "language_name": "Kazakh",
        "native_language_name": "\u049b\u0430\u0437\u0430\u049b \u0442\u0456\u043b\u0456",

    },
    {
        "country_code": "KH",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "km",
        "language_name": "Central Khmer",
        "native_language_name": "\u1797\u17b6\u179f\u17b6\u1781\u17d2\u1798\u17c2\u179a",

    },
    {
        "country_code": "KR",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "ko",
        "language_name": "Korean",
        "native_language_name": "\ud55c\uad6d\uc5b4",

    },
    {
        "country_code": "ku",
        "direction": "right_to_left",
        "identifiable": true,
        "language": "ku",
        "language_name": "Kurdish",
        "native_language_name": "\u06a9\u0648\u0631\u062f\u06cc",

    },
    {
        "country_code": "KG",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "ky",
        "language_name": "Kirghiz",
        "native_language_name": "\u043a\u044b\u0440\u0433\u044b\u0437\u0447\u0430",

    },
    {
        "country_code": "LA",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "lo",
        "language_name": "Lao",
        "native_language_name": "\u0e9e\u0eb2\u0eaa\u0eb2\u0ea5\u0eb2\u0ea7",

    },
    {
        "country_code": "LT",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "lt",
        "language_name": "Lithuanian",
        "native_language_name": "Lietuvi\u0173 Kalba",

    },
    {
        "country_code": "LV",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "lv",
        "language_name": "Latvian",
        "native_language_name": "Latvie\u0161u Valoda",

    },
    {
        "country_code": "IN",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "ml",
        "language_name": "Malayalam",
        "native_language_name": "\u0d2e\u0d32\u0d2f\u0d3e\u0d33\u0d02",

    },
    {
        "country_code": "MN",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "mn",
        "language_name": "Mongolian",
        "native_language_name": "\u041c\u043e\u043d\u0433\u043e\u043b \u0425\u044d\u043b",

    },
    {
        "country_code": "IN",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "mr",
        "language_name": "Marathi",
        "native_language_name": "\u092e\u0930\u093e\u0920\u0940",

    },
    {
        "country_code": "MY",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "ms",
        "language_name": "Malay",
        "native_language_name": "Bahasa Melayu",

    },
    {
        "country_code": "MT",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "mt",
        "language_name": "Maltese",
        "native_language_name": "Malti",

    },
    {
        "country_code": "MM",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "my",
        "language_name": "Burmese",
        "native_language_name": "\u1019\u103c\u1014\u103a\u1019\u102c\u1005\u102c",

    },
    {
        "country_code": "NO",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "nb",
        "language_name": "Norwegian Bokmal",
        "native_language_name": "Bokm\u00e5l",

    },
    {
        "country_code": "NP",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "ne",
        "language_name": "Nepali",
        "native_language_name": "\u0928\u0947\u092a\u093e\u0932\u0940 \u092d\u093e\u0937\u093e",

    },
    {
        "country_code": "NL",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "nl",
        "language_name": "Dutch",
        "native_language_name": "Nederlands",

    },
    {
        "country_code": "NO",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "nn",
        "language_name": "Norwegian Nynorsk",
        "native_language_name": "Nynorsk",

    },
    {
        "country_code": "IN",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "pa",
        "language_name": "Punjabi",
        "native_language_name": "\u0a2a\u0a70\u0a1c\u0a3e\u0a2c\u0a40",

    },
    {
        "country_code": "PK",
        "direction": "right_to_left",
        "identifiable": true,
        "language": "pa-PK",
        "language_name": "Punjabi (Shahmukhi script, Pakistan)",
        "native_language_name": "\u067e\u0646\u062c\u0627\u0628\u06cc",

    },
    {
        "country_code": "PL",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "pl",
        "language_name": "Polish",
        "native_language_name": "J\u0119zyk polski",

    },
    {
        "country_code": "PS",
        "direction": "right_to_left",
        "identifiable": true,
        "language": "ps",
        "language_name": "Pushto",
        "native_language_name": "\u067e\u069a\u062a\u0648",

    },
    {
        "country_code": "BR",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "pt",
        "language_name": "Portuguese",
        "native_language_name": "Portugu\u00eas",

    },
    {
        "country_code": "RO",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "ro",
        "language_name": "Romanian",
        "native_language_name": "Limba Rom\u00e2n\u0103",

    },
    {
        "country_code": "RU",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "ru",
        "language_name": "Russian",
        "native_language_name": "\u0420\u0443\u0441\u0441\u043a\u0438\u0439 \u044f\u0437\u044b\u043a",

    },
    {
        "country_code": "LK",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "si",
        "language_name": "Sinhala",
        "native_language_name": "\u0dc3\u0dd2\u0d82\u0dc4\u0dbd",

    },
    {
        "country_code": "SK",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "sk",
        "language_name": "Slovakian",
        "native_language_name": "Sloven\u010dina",

    },
    {
        "country_code": "SI",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "sl",
        "language_name": "Slovenian",
        "native_language_name": "Sloven\u0161\u010dina",

    },
    {
        "country_code": "SO",
        "direction": "right_to_left",
        "identifiable": true,
        "language": "so",
        "language_name": "Somali",
        "native_language_name": "\u0627\u0644\u0644\u063a\u0629 \u0627\u0644\u0635\u0648\u0645\u0627\u0644\u064a\u0629",

    },
    {
        "country_code": "AL",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "sq",
        "language_name": "Albanian",
        "native_language_name": "Shqip",

    },
    {
        "country_code": "RS",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "sr",
        "language_name": "Serbian",
        "native_language_name": "\u0421\u0440\u043f\u0441\u043a\u0438",

    },
    {
        "country_code": "SE",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "sv",
        "language_name": "Swedish",
        "native_language_name": "Svenska",

    },
    {
        "country_code": "IN",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "ta",
        "language_name": "Tamil",
        "native_language_name": "\u0ba4\u0bae\u0bbf\u0bb4\u0bcd",

    },
    {
        "country_code": "IN",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "te",
        "language_name": "Telugu",
        "native_language_name": "\u0c24\u0c46\u0c32\u0c41\u0c17\u0c41",

    },
    {
        "country_code": "TH",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "th",
        "language_name": "Thai",
        "native_language_name": "\u0e20\u0e32\u0e29\u0e32\u0e44\u0e17\u0e22",
        "supported_as_source": true,
        "supported_as_target": true,
        "words_separated": false
    },
    {
        "country_code": "PH",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "tl",
        "language_name": "Tagalog",
        "native_language_name": "\u170f\u1712\u1703\u1705\u1714 \u1706\u1704\u170e\u1713\u1704\u1714",

    },
    {
        "country_code": "TR",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "tr",
        "language_name": "Turkish",
        "native_language_name": "T\u00fcrk\u00e7e",

    },
    {
        "country_code": "UA",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "uk",
        "language_name": "Ukrainian",
        "native_language_name": "\u0423\u043a\u0440\u0430\u0457\u043d\u0441\u044c\u043a\u0430 \u043c\u043e\u0432\u0430",

    },
    {
        "country_code": "PK",
        "direction": "right_to_left",
        "identifiable": true,
        "language": "ur",
        "language_name": "Urdu",
        "native_language_name": "\u0627\u064f\u0631\u062f\u064f\u0648",

    },
    {
        "country_code": "VN",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "vi",
        "language_name": "Vietnamese",
        "native_language_name": "Ti\u1ebfng Vi\u1ec7t",

    },
    {
        "country_code": "CN",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "zh",
        "language_name": "Simplified Chinese",
        "native_language_name": "\u4e2d\u6587",
        "supported_as_source": true,
        "supported_as_target": true,
        "words_separated": false
    },
    {
        "country_code": "TW",
        "direction": "left_to_right",
        "identifiable": true,
        "language": "zh-TW",
        "language_name": "Traditional Chinese",
        "native_language_name": "\u53e4\u6587",
        "supported_as_source": true,
        "supported_as_target": true,
        "words_separated": false
    }
]


const TextTranslator = props => {
    const [adTagContent, setAdTagContent] = useState('')
    const [urlLink, setTCName] = useState('')
    const [sentences, setUrlLink] = useState(5)
    const [translatedText, setTranslatedText] = useState('')
    const [loader, setLoader] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('')
    const [sourceLanguage, setSourceLanguage] = useState('')

    const handleChange = (e) => {
        setAdTagContent(e.target.value);
    }

    const languageChange = (e) => {
        setSelectedLanguage(e.target.value);
    }

    const handleSubmit = async (e) => {
        getJSON().then((json) => {
            setLoader(false)
            console.log(json);
            setTranslatedText(json.translations[0].translation)
            Languages.map((item, index) => {
                if(item.language === json.detected_language){
                    setSourceLanguage(item.language_name)
                }
            })

        });
    }

    const getJSON = async (e) => {
        setLoader(true)
        const formdata = new FormData();
        formdata.append("apikey", "luwxmOQreVg2mrUvg3VhbOCy18STj1ia");
        // if(adTagContent){
        //     formdata.append("txt", adTagContent);
        // }
        // if(urlLink){
        //     formdata.append("url", urlLink);
        // }
        // formdata.append("sentences", sentences);

        const requestOptions = {
            method: 'POST',
            headers: formdata,
            redirect: 'follow',
            body: adTagContent
        };
        console.log(selectedLanguage)
        debugger
        let response = await fetch("https://api.apilayer.com/language_translation/translate?target="+ selectedLanguage, requestOptions);
        var json = await response.json();
        return json
        // .then(response => (
        //     {
        //     status: response.status,
        //     body: response.json()
        // }))
        // .then(({ status, body }) =>
        //  console.log(status, body),
        //  console.log(JSON.stringify(body)),
        //  console.log(JSON.stringify(body))
        //  )
        // .catch(error => console.log('error', error));

    }

    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
            <div className="page-content">
                <MetaTags>
                    <title>Text Translator | Bidsxchange</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Tools" breadcrumbItem="Text Translator" />
                    {loader &&
                        <Spinner className="ms-2 loader" color="primary" />
                    }
                    <SearchDebounce />
                    <div className="card card-body ">
                        <div className="row">
                            <div className="mb-3">
                                <Label className="form-label">Translate Text</Label>
                                <textarea id="input" name="adTagContent" value={adTagContent} onChange={handleChange} className="form-control" placeholder="Either type or paste your web browser code here" style={{ resize: 'none', display: 'block', height: '271.8px' }}></textarea>
                            </div>
                            {/* <h5>Or</h5>
                            <div className="mb-3">
                                <Label className="form-label">Translate Link</Label>
                                <Input
                                    name="tcname"
                                    type="text"
                                    value={urlLink}
                                    onChange={(e) => setTCName(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Number of Sentences</Label>
                                <Input
                                    name="tcname"
                                    type="number"
                                    value={sentences}
                                    onChange={(e) => setUrlLink(e.target.value)}
                                />
                            </div> */}
                        </div>
                        <Row className="" >
                            <Col xs={6}>
                                <div className="text-end">

                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                        onClick={handleSubmit}
                                    >
                                        Translate
                                    </button>

                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="d-flex text-end">

                                    <Label className="form-label col-6 mt-2">Select Language to Translate : </Label>&nbsp;
                                    <Input
                                        type="select"
                                        className="form-select"
                                        onChange={languageChange}
                                        name="language" value={selectedLanguage} 
                                    >
                                        {/* <option value=''> Select {item.label}</option> */}
                                        {Languages.map((item,index) => (
                                            <option key={index} value={item.language}>{item.language_name}</option>
                                        ))}
                                    </Input>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="card card-body ">
                        <div className="row">
                            <div className="col-12">
                                <Label className="form-label">Translated Text</Label>
                            </div>
                            <div className="col-12">
                                <Label className="form-label text-end">Translated Text Language From  : {sourceLanguage}</Label>
                            </div>
                            <div className="mb-3">

                                <textarea id="input" name="translatedText" value={translatedText} className="form-control" placeholder="Translated Text will be shown here..." style={{ resize: 'none', display: 'block', height: '271.8px' }}></textarea>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            {/* </div > */}
        </React.Fragment >
    )
}

TextTranslator.propTypes = {}

export default TextTranslator