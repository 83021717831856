import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";

import classNames from "classnames";
import axios from "axios";

// Pages Components
import WelcomeComp from "pages/Dashboard/WelcomeComp";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DateRangePickers from 'helpers/DateRangePicker'

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import moment from "moment";
import DateRangePickerDashboard from "helpers/DateRangePickerDashboard";
import AgencyStackColoumn from "./AgencyStackColoumn";
import Agency_LoadingDashboard from "pages/Agency_Console/Agency_LoadingDashboard";

var estCurrentDate = parseInt(moment().format('HHmm')) < 1030 ? 3 : 2

var fromDate = moment().subtract(estCurrentDate > 2 ? 8 : 7, 'days')
var toDate = moment().subtract(estCurrentDate > 2 ? 2 : 1, 'days')
var dateFromDate = moment().subtract(estCurrentDate > 2 ? 8 : 7, 'days')
var dateToDate = moment().subtract(estCurrentDate > 2 ? 2 : 1, 'days')

var xAxis = []

const AgencyDashBoard = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));

  const [chartData, setChartData] = useState([]);

  const [periodType, setPeriodType] = useState("day");

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setPeriodType("day")
      if (tab === "2") {
        getDashboardData("video", periodType)
      } else {
        getDashboardData("display", periodType)
      }
      setcustomActiveTab(tab);
    }
  };

  const [reportData, setReportData] = useState([
    { title: "Impressions", iconClass: "bx-bar-chart", description: "0", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "Clicks", iconClass: "bx-bar-chart", description: "0", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "CTR", iconClass: "bx-dollar-circle", description: "0%", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "Conversions", iconClass: "bx-dollar-circle", description: "$0", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "Conversions Ratio", iconClass: "bx-show-alt", description: "0%", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "Cost", iconClass: "bx-show-alt", description: "$0", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "CPM", iconClass: "bx-show-alt", description: "$0", yesterdayRate: '0%', isIncreased: 'zero' },
  ])

  const [inventoryType, setInventoryType] = useState('')
  const [userInventoryState, setUserInventoryState] = useState('both')

  const [switchLoader, setSwitchLoader] = useState(true)
  const [loading, setLoading] = useState(false)

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  useEffect(() => {
    console.log(userInfo)
    fromDate = moment().subtract(estCurrentDate > 2 ? 8 : 7, 'days')
    toDate = moment().subtract(estCurrentDate > 2 ? 2 : 1, 'days')
    dateFromDate = moment().subtract(estCurrentDate > 2 ? 8 : 7, 'days')
    dateToDate = moment().subtract(estCurrentDate > 2 ? 2 : 1, 'days')
    setUserInventoryState(userInfo.inventoryType)
    getDashboardData('display', 'day')
  }, []);


  const onChangeChartPeriod = pType => {
    setPeriodType(pType);
    if (customActiveTab === "2") {
      getOverViewData("video", pType)
    } else {
      getOverViewData("display", pType)
    }
  };


  const getDashboardData = (inventory, pType) => {
    setLoading(true)
    const body = {
      "url": "http://127.0.0.1:2003/v1.0/Query/Agency/DashBoards",
      "requestBody": {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        "startDate": moment(fromDate).format("YYYY-MM-DD"),
        "endDate": moment(toDate).format("YYYY-MM-DD"),
        name: userInfo.name,
        frequency: pType,
        inventoryType: inventory,
        currencyType:"USD"
      }
    }
    axios.post("/v1.0/connectOP", body,
      {
        headers: headers
      })
      .then((res) => {
        setLoading(false)
        if (res.data.success) {
          if (res.data && res.data.success) {
            if (res.data.previousDataImpressions) {
              let prevImpressions = res.data.impressions === 0 ? 0 : res.data.previousDataImpressions === 0 ? 0 : (100 * ((res.data.impressions - res.data.previousDataImpressions) / res.data.previousDataImpressions))
              let prevClicks = res.data.clicks === 0 ? 0 :  res.data.previousDataClicks === 0 ? 0 : (100 * ((res.data.clicks - res.data.previousDataClicks) / res.data.previousDataClicks))
              let prevCtr = res.data.ctr === 0 ? 0 :  res.data.previousDataCtr === 0 ? 0 : (100 * ((res.data.ctr - res.data.previousDataCtr) / res.data.previousDataCtr))
              let prevConversions = res.data.conversions === 0 ? 0 :  res.data.previousDataConversions === 0 ? 0 : (100 * ((res.data.conversions - res.data.previousDataConversions) / res.data.previousDataConversions))
              let prevConversionsRatio = res.data.conversionsRatio === 0 ? 0 :  res.data.previousDataConversionsRatio === 0 ? 0 : (100 * ((res.data.conversionsRatio - res.data.previousDataConversionsRatio) / res.data.previousDataConversionsRatio))
              let prevCost = res.data.cost === 0 ? 0 :  res.data.previousDataCost === 0 ? 0 : (100 * ((res.data.cost - res.data.previousDataCost) / res.data.previousDataCost))
              let prevCpm = res.data.cpm === 0 ? 0 :  res.data.previousDataCpm === 0 ? 0 : (100 * ((res.data.cpm - res.data.previousDataCpm) / res.data.previousDataCpm))
              let prevVideoCompletions = 0
              let prevVideoCompletionRatio = 0

              let data = [
                { title: "Impressions", iconClass: "bx-bar-chart", description: (res.data.impressions).toLocaleString(), yesterdayRate: (prevImpressions.toFixed(2) < 0 ? prevImpressions.toFixed(2) * -1 : prevImpressions.toFixed(2)) + "%", isIncreased: prevImpressions > 0 ? 'yes' : prevImpressions < 0 ? 'no' : 'zero' },
                { title: "Clicks", iconClass: "bx-bar-chart", description: (res.data.clicks).toLocaleString(), yesterdayRate: (prevClicks.toFixed(2) < 0 ? prevClicks.toFixed(2) * -1 : prevClicks.toFixed(2)) + "%", isIncreased: prevClicks > 0 ? 'yes' : prevClicks < 0 ? 'no' : 'zero' },
                { title: "CTR", iconClass: "bx-dollar-circle", description: (res.data.ctr).toLocaleString() + "%", yesterdayRate: (prevCtr.toFixed(2) < 0 ? prevCtr.toFixed(2) * -1 : prevCtr.toFixed(2)) + "%", isIncreased: prevCtr > 0 ? 'yes' : prevCtr < 0 ? 'no' : 'zero' },
                { title: "Conversions", iconClass: "bx-dollar-circle", description:"$" + (res.data.conversions).toLocaleString(), yesterdayRate: (prevConversions.toFixed(2) < 0 ? prevConversions.toFixed(2) * -1 : prevConversions.toFixed(2)) + "%", isIncreased: prevConversions > 0 ? 'yes' : prevConversions < 0 ? 'no' : 'zero' },
                { title: "Conversion Ratio", iconClass: "bx-show-alt", description: res.data.conversionsRatio + "%", yesterdayRate: (prevConversionsRatio.toFixed(2) < 0 ? prevConversionsRatio.toFixed(2) * -1 : prevConversionsRatio.toFixed(2)) + "%", isIncreased: prevConversionsRatio > 0 ? 'yes' : prevConversionsRatio < 0 ? 'no' : 'zero' },
                { title: "Cost", iconClass: "bx-dollar-circle", description: "$" + (res.data.cost).toLocaleString(), yesterdayRate: (prevCost.toFixed(2) < 0 ? prevCost.toFixed(2) * -1 : prevCost.toFixed(2)) + "%", isIncreased: prevCost > 0 ? 'yes' : prevCost < 0 ? 'no' : 'zero' },
                { title: "CPM", iconClass: "bx-show-alt", description: "$" + (res.data.cpm), yesterdayRate: (prevCpm.toFixed(2) < 0 ? prevCpm.toFixed(2) * -1 : prevCpm.toFixed(2)) + "%", isIncreased: prevCpm > 0 ? 'yes' : prevCpm < 0 ? 'no' : 'zero' },
              ]
              if(inventory === 'video'){
                data = [...data,{ title:"Video Completions", iconClass: "bx-dollar-circle", description: (res.data.videoCompletions).toLocaleString(), yesterdayRate: (prevVideoCompletions.toFixed(2) < 0 ? prevVideoCompletions.toFixed(2) * -1 : prevVideoCompletions.toFixed(2)) + "%", isIncreased: prevVideoCompletions > 0 ? 'yes' : prevVideoCompletions < 0 ? 'no' : 'zero' },
                { title:"Video Completion Rate", iconClass: "bx-show-alt", description: res.data.videoCompletionsRate + "%", yesterdayRate: (prevVideoCompletionRatio.toFixed(2) < 0 ? prevVideoCompletionRatio.toFixed(2) * -1 : prevVideoCompletionRatio.toFixed(2)) + "%", isIncreased: prevVideoCompletionRatio > 0 ? 'yes' : prevVideoCompletionRatio < 0 ? 'no' : 'zero' }]
              }
              setReportData([...data])
            }
            else {
              let data = [
                { title: "Impressions", iconClass: "bx-bar-chart", description: (res.data.impressions).toLocaleString(), yesterdayRate: '0%', isIncreased: 'zero' },
                { title: "Clicks", iconClass: "bx-bar-chart", description: (res.data.clicks).toLocaleString(), yesterdayRate: '0%', isIncreased: 'zero' },
                { title: "CTR", iconClass: "bx-dollar-circle", description:(res.data.ctr).toLocaleString() + "%", yesterdayRate: '0%', isIncreased: 'zero' },
                { title:"Conversions", iconClass: "bx-dollar-circle", description:"$" +(res.data.conversions).toLocaleString(), yesterdayRate: '0%', isIncreased: 'zero' },
                { title:"Conversion Ratio", iconClass: "bx-show-alt", description: res.data.conversionsRatio + "%", yesterdayRate: '0%', isIncreased: 'zero' },
                { title: "Cost", iconClass: "bx-dollar-circle", description: "$" + (res.data.cost).toLocaleString(), yesterdayRate: '0%', isIncreased: 'zero' },
                { title: "CPM", iconClass: "bx-show-alt", description:"$" + (res.data.cpm), yesterdayRate: '0%', isIncreased: 'zero' },
              ]
              if(inventory === 'video'){
                data = [...data,
                { title:"Video Completions", iconClass: "bx-dollar-circle", description: (res.data.videoCompletions).toLocaleString(), yesterdayRate: '0%', isIncreased: 'zero' },
                { title:"Video Completion Rate", iconClass: "bx-show-alt", description: res.data.videoCompletionsRate + "%", yesterdayRate: '0%', isIncreased: 'zero' }]
              }
              setReportData([...data])
            }
            if (res.data.overview && res.data.overview.length > 0) {
              xAxis = []
              let clicks = []
              let ctr = []
              let impressions = []
              res.data.overview.map((item, index) => {
                xAxis.push(item['x-axis'])
              })
              res.data.overview.map((item, index) => {
                clicks.push(item['clicks'] < 0 ? 0 : item['clicks'])
              })
              res.data.overview.map((item, index) => {
                ctr.push(item['ctr'] < 0 ? 0 : item['ctr'])
              })
              res.data.overview.map((item, index) => {
                impressions.push(item['impressions'] < 0 ? 0 : item['impressions'])
              })
              setChartData([
                { name: 'Impressions', type: "column", data: impressions },
                { name: 'Clicks', type: "column", data: clicks },
                { name: 'CTR', type: "line", data: ctr },
              ])
            } else {
              setChartData([])
              xAxis = []
            }
          } else {
            let data = [
              { title: "Impressions", iconClass: "bx-bar-chart", description: (res.data.impressions).toLocaleString(), yesterdayRate: '0%', isIncreased: 'zero' },
              { title: "Clicks", iconClass: "bx-bar-chart", description: (res.data.clicks).toLocaleString(), yesterdayRate: '0%', isIncreased: 'zero' },
              { title: "CTR", iconClass: "bx-dollar-circle", description:(res.data.ctr).toLocaleString() + "%", yesterdayRate: '0%', isIncreased: 'zero' },
              { title:"Conversions", iconClass: "bx-dollar-circle", description:"$" + (res.data.conversions).toLocaleString(), yesterdayRate: '0%', isIncreased: 'zero' },
              { title:"Conversion Ratio", iconClass: "bx-show-alt", description: res.data.conversionsRatio + "%", yesterdayRate: '0%', isIncreased: 'zero' },
              { title: "Cost", iconClass: "bx-dollar-circle", description: "$" + (res.data.cost).toLocaleString(), yesterdayRate: '0%', isIncreased: 'zero' },
              { title: "CPM", iconClass: "bx-show-alt", description:"$" + (res.data.cpm), yesterdayRate: '0%', isIncreased: 'zero' },
            ]
            if(inventory === 'video'){
              data = [...data,
              { title:"Video Completions", iconClass: "bx-dollar-circle", description: (res.data.videoCompletions).toLocaleString(), yesterdayRate: '0%', isIncreased: 'zero' },
              { title:"Video Completion Rate", iconClass: "bx-show-alt", description: res.data.videoCompletionsRate + "%", yesterdayRate: '0%', isIncreased: 'zero' }]
            }
            setReportData([...data])
            setChartData([])
            xAxis = []
          }
        }
      })
      .catch((err) => {
        setLoading(false)
        console.log("e", err)
      });
  }


  const changeDatePick = (event) => {
    dateFromDate = event.startDate
    dateToDate = event.endDate
    fromDate = event.startDate._d.toISOString();
    toDate = event.endDate._d.toISOString();
    setPeriodType("day")
    if (customActiveTab === "2") {
      getDashboardData("video", "day")
    } else {
      getDashboardData("display", "day")
    }

    // setFromDate(event.startDate._d.toISOString());
    // setToDate(event.endDate._d.toISOString());
  }

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }));




  const getOverViewData = (inventory,ptype) => {
    const body = {
      "url": "http://127.0.0.1:2003/v1.0/Query/Revenue/DashBoards/agency/overView",
      "requestBody": {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        "startDate": moment(fromDate).format("YYYY-MM-DD"),
        "endDate": moment(toDate).format("YYYY-MM-DD"),
        name: userInfo.name,
        frequency: ptype,
        inventoryType: inventory,
        currencyType:"USD"
      }
    }
    axios.post("/v1.0/connectOP", body,
      {
        headers: headers
      })
      .then((res) => {
        setLoading(false)
        if (res.data.success) {
            if (res.data.overview && res.data.overview.length > 0) {
              xAxis = []
              let clicks = []
              let ctr = []
              let impressions = []
              res.data.overview.map((item, index) => {
                xAxis.push(item['x-axis'])
              })
              res.data.overview.map((item, index) => {
                clicks.push(item['clicks'] < 0 ? 0 : item['clicks'])
              })
              res.data.overview.map((item, index) => {
                ctr.push(item['ctr'] < 0 ? 0 : item['ctr'])
              })
              res.data.overview.map((item, index) => {
                impressions.push(item['impressions'] < 0 ? 0 : item['impressions'])
              })
              setChartData([
                { name: 'Impressions', type: "column", data: impressions },
                { name: 'Clicks', type: "column", data: clicks },
                { name: 'CTR', type: "line", data: ctr },
              ])
            } else {
              setChartData([])
              xAxis = []
            }
          } 
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  useEffect(() => {
    if (loading) {
      setSwitchLoader(true)
    }
  }, [loading]);


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Agency Dashboard | Bidsxchange</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col md="4">
              <Breadcrumbs
                title={props.t("Agency Dashboard - (USD-$)")}
                breadcrumbItem={props.t("Agency Dashboard - (USD-$)")}
              />
            </Col>
            <Col md="4">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-between mb-4">
                    <Col md="10">
                      <div className="d-flex justify-content-start mb-4">
                        <Nav tabs className="nav-tabs-custom nav-justified" style={{ width: '30pc' }}>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classNames({
                                active: customActiveTab === "1",
                              })}
                              onClick={() => {
                                toggleCustom("1");
                              }}
                            >
                              <span className="d-block d-sm-none">
                                <i className="fas fa-home"></i>
                              </span>
                              <span className="d-none d-sm-block" style={{ fontSize: '16px' }}>Display</span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classNames({
                                active: customActiveTab === "2",
                              })}
                              onClick={() => {
                                toggleCustom("2");
                              }}
                            >
                              <span className="d-block d-sm-none">
                                <i className="far fa-user"></i>
                              </span>
                              <span className="d-none d-sm-block" style={{ fontSize: '16px' }}>Video</span>
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </Col>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-end mb-4">

                    <DateRangePickerDashboard datePick={changeDatePick} startDate={dateFromDate} endDate={dateToDate} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <TabContent
              activeTab={customActiveTab}
              className=" text-muted"
            >
              <TabPane tabId="1">
                <Col xl="">
                  <Row>
                    {switchLoader &&
                      <Agency_LoadingDashboard loading={loading} targetLoading={handleSwitch} />
                    }
                    {!switchLoader && reportData.map((report, key) => (
                      <Col md="3" className="col-xxxl" key={"_col_" + key}>
                        <Card className="mini-stats-wid dashboard-cards text-center sim-main" >
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  {report.title}
                                </p>
                                <h4 style={{ marginBottom: '3%' }}>{report.description}</h4>

                                {/* <span className={report.isIncreased === 'yes' ? 'badge badge-soft-success' : report.isIncreased === 'no' ? 'badge badge-soft-danger' : 'badge badge-soft-warning'} style={{ fontSize: '12px', fontWeight: '500', marginBottom: '0.7em' }}>
                                  {(report.isIncreased === 'yes' ? <i className="mdi mdi-arrow-up " /> : report.isIncreased === 'no' ? <i className="mdi mdi-arrow-down " /> : '')} {report.yesterdayRate}</span>                               <br></br>
                                &nbsp;&nbsp;From Previous Period */}
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Row>
                  <Col sm="12">
                    <Card>
                      <CardBody>
                        <div className="d-sm-flex flex-wrap">
                          <h4 className="card-title mb-4">Overview of Impressions | Clicks | CTR</h4>
                          <div className="ms-auto">
                            <ul className="nav nav-pills">
                              <li className="nav-item">
                                <Link
                                  to="#"
                                  className={classNames(
                                    { active: periodType === "day" },
                                    "nav-link"
                                  )}
                                  onClick={() => {
                                    onChangeChartPeriod("day");
                                  }}
                                  id="one_month"
                                >
                                  Day
                                </Link>
                              </li>
                              <li className="nav-item">
                                <Link
                                  to="#"
                                  className={classNames(
                                    { active: periodType === "week" },
                                    "nav-link"
                                  )}
                                  onClick={() => {
                                    onChangeChartPeriod("week");
                                  }}
                                  id="one_month"
                                >
                                  Week
                                </Link>
                              </li>
                              <li className="nav-item">
                                <Link
                                  to="#"
                                  className={classNames(
                                    { active: periodType === "month" },
                                    "nav-link"
                                  )}
                                  onClick={() => {
                                    onChangeChartPeriod("month");
                                  }}
                                  id="one_month"
                                >
                                  Month
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                        <AgencyStackColoumn chartData={chartData} loading={loading} xAxisData={xAxis} dataColors='["--bs-danger","--bs-primary", "--bs-success"]' title={''} />
                      </CardBody>
                    </Card>
                  </Col>

                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Col xl="">
                  <Row>
                    {switchLoader &&
                      <Agency_LoadingDashboard loading={loading} targetLoading={handleSwitch} />
                    }
                    {!switchLoader && reportData.map((report, key) => (
                      <Col md="4" className="col-mid-3  col-xxxl" key={"_col_" + key}>
                        <Card className="mini-stats-wid dashboard-cards text-center sim-main" >
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium text-nowrap">
                                  {report.title}
                                </p>
                                <h4 style={{ marginBottom: '3%' }}>{report.description}</h4>

                                {/* <span className={report.isIncreased === 'yes' ? 'badge badge-soft-success' : report.isIncreased === 'no' ? 'badge badge-soft-danger' : 'badge badge-soft-warning'} style={{ fontSize: '12px', fontWeight: '500', marginBottom: '0.7em' }}>
                                  {(report.isIncreased === 'yes' ? <i className="mdi mdi-arrow-up " /> : report.isIncreased === 'no' ? <i className="mdi mdi-arrow-down " /> : '')} {report.yesterdayRate}</span>                               <br></br>
                                &nbsp;&nbsp;From Previous <br></br> Period */}
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Row>
                  <Col sm="12">
                    <Card>
                      <CardBody>
                        <div className="d-sm-flex flex-wrap">
                          <h4 className="card-title mb-4">Overview of Impressions | Clicks | CTR</h4>
                          <div className="ms-auto">
                            <ul className="nav nav-pills">
                              <li className="nav-item">
                                <Link
                                  to="#"
                                  className={classNames(
                                    { active: periodType === "day" },
                                    "nav-link"
                                  )}
                                  onClick={() => {
                                    onChangeChartPeriod("day");
                                  }}
                                  id="one_month"
                                >
                                  Day
                                </Link>
                              </li>
                              <li className="nav-item">
                                <Link
                                  to="#"
                                  className={classNames(
                                    { active: periodType === "week" },
                                    "nav-link"
                                  )}
                                  onClick={() => {
                                    onChangeChartPeriod("week");
                                  }}
                                  id="one_month"
                                >
                                  Week
                                </Link>
                              </li>
                              <li className="nav-item">
                                <Link
                                  to="#"
                                  className={classNames(
                                    { active: periodType === "month" },
                                    "nav-link"
                                  )}
                                  onClick={() => {
                                    onChangeChartPeriod("month");
                                  }}
                                  id="one_month"
                                >
                                  Month
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                        <AgencyStackColoumn chartData={chartData} loading={loading} xAxisData={xAxis} dataColors='["--bs-danger","--bs-primary", "--bs-success"]' title={''} />
                      </CardBody>
                    </Card>
                  </Col>

                </Row>
              </TabPane>

            </TabContent>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AgencyDashBoard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(AgencyDashBoard)