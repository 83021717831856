/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import React, { useState, useRef, useEffect } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { WithContext as ReactTags } from 'react-tag-input';
import {
    Card,
    CardBody,
    CardTitle,
    Container,
    Row, Col, Input,
    Button,
    Spinner, Label, Form
} from "reactstrap"

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import BrandAffinity from "assets/files/BrandAffinity.xlsx"
import Interest from "assets/files/Interest.xlsx"
import Keyword from "assets/files/Keyword.xlsx"
import pageurl from "assets/files/pageurl.xlsx"
import ipmapping from "assets/files/ipmapping.xlsx"
import websiteCategorizedFile from "assets/files/websiteCategorization.xlsx"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';


var fileNameEntry = ''
var list = []
var brandList = []
let itemList = []

const UploadBlockings = props => {
    /* eslint-disable react/prop-types */

    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [loader, setLoader] = useState(false);
    const [publisherReport, setPublisherReport] = useState({
        fileName: '',
        groupName: '',
        networkBlockList: [],
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
    });
    const ref = useRef();
    const [inputKey, setInputKey] = useState(Math.random().toString(36))
    const [downloadFileBtn, setDownloadFileBtn] = useState(false)
    const [downloadUrl, setDownloadUrl] = useState('')
    const [itemBlock, setItemBlock] = useState('')

    const [isEdit, setIsEdit] = useState(false);
    const [tags, setTags] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [itemListData, setItemListData] = useState([]);

    const [websiteCategorization, setWebsiteCategorization] = useState({
        website: '',
        language: '',
        category: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setWebsiteCategorization(websiteCategorization => ({ ...websiteCategorization, [name]: value }));
    }

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
        "url": '',
        "requestBody": {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
        }
    }

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const dataFeedChange = (e) => {
        const { name, value } = e.target;
        setPublisherReport(publisherReport => ({ ...publisherReport, [name]: value }));
    }

    useEffect(() => {
        itemList = props.selectedDataLists
        if (props.selectedData !== '') {
            list = []
            brandList = []
            let dfs = []
            console.log(props.selectedData)
            setIsEdit(true)
            //   setPublisherReport(props.selectedData)
            if (props.networkBlockType === "brandAffinity") {
                if (props.selectedData.brandAffinityList.length > 0) {
                    brandList = props.selectedData.brandAffinityList.map((item, index) =>
                        Object.assign({}, { text: item, id: item })
                    )
                    console.log(brandList)
                    props.selectedData.brandAffinityList.map((item, index) => {
                        list.push(item)
                        dfs.push({ name: item, id: index + 1 })
                        itemList = dfs
                        setItemListData(dfs)
                        return itemList
                    })
                    setTags(brandList)
                }else{
                    itemList = [] 
                }
                setPublisherReport(publisherReport => ({ ...publisherReport, networkBlockList: props.selectedData.brandAffinityList, groupName: props.selectedData.brandAffinityGroup }));
            }
            if (props.networkBlockType === "interest") {
                if (props.selectedData.interestList.length > 0) {
                    brandList = props.selectedData.interestList.map((item, index) =>
                        Object.assign({}, { text: item, id: item })
                    )
                    console.log(brandList)
                    props.selectedData.interestList.map((item, index) => {
                        list.push(item)
                        dfs.push({ name: item, id: index + 1 })
                        itemList = dfs
                        setItemListData(dfs)
                        return itemList
                    })
                    setTags(brandList)
                }
                setPublisherReport(publisherReport => ({ ...publisherReport, networkBlockList: props.selectedData.interestList, groupName: props.selectedData.interestGroup }));
            }
            if (props.networkBlockType === "keyword") {
                if (props.selectedData.keywordList.length > 0) {
                    brandList = props.selectedData.keywordList.map((item, index) =>
                        Object.assign({}, { text: item, id: item })
                    )
                    console.log(brandList)
                    props.selectedData.keywordList.map((item, index) => {
                        list.push(item)
                        dfs.push({ name: item, id: index + 1 })
                        itemList = dfs
                        setItemListData(dfs)
                        return itemList
                    })
                    setTags(brandList)
                }
                setPublisherReport(publisherReport => ({ ...publisherReport, networkBlockList: props.selectedData.keywordList, groupName: props.selectedData.keywordGroup }));
            }
            if (props.networkBlockType === "iPMapping") {
                if (props.selectedData.iPMappingList.length > 0) {
                    brandList = props.selectedData.iPMappingList.map((item, index) =>
                        Object.assign({}, { text: item, id: item })
                    )
                    console.log(brandList)
                    props.selectedData.iPMappingList.map((item, index) => {
                        list.push(item)
                        dfs.push({ name: item, id: index + 1 })
                        itemList = dfs
                        setItemListData(dfs)
                        return itemList
                    })
                    setTags(brandList)
                }
                setPublisherReport(publisherReport => ({ ...publisherReport, networkBlockList: props.selectedData.iPMappingList, groupName: props.selectedData.iPMappingGroup }));
            }
            if (props.networkBlockType === "PageURLGroups") {
                if (props.selectedData.pageURLGroupValues.length > 0) {
                    brandList = props.selectedData.pageURLGroupValues.map((item, index) =>
                        Object.assign({}, { text: item, id: item })
                    )
                    console.log(brandList)
                    props.selectedData.pageURLGroupValues.map((item, index) => {
                        list.push(item)
                        dfs.push({ name: item, id: index + 1 })
                        itemList = dfs
                        setItemListData(dfs)
                        return itemList
                    })
                    setTags(brandList)
                }
                setPublisherReport(publisherReport => ({ ...publisherReport, networkBlockList: props.selectedData.pageURLGroupValues, groupName: props.selectedData.pageURLGroupName }));
            }
            if (props.networkBlockType === "websiteCatorization") {
                list = []
                brandList = []
                setWebsiteCategorization(props.selectedData)
            }
        } else {
            list = []
            brandList = []
            itemList = props.selectedDataLists
        }
    }, []);

    useEffect(() => {
        console.log(props)
        if(props.selectedDataLists.length === 0){
            console.log(itemList)
            itemList = []

        }
    }, [props.selectedDataLists]);


    const fileChangepublisherReport = (file) => {
        setDownloadFileBtn(false)
        if (file.target.files[0].name) {
            setLoader(true)
        } else {
            setLoader(false)
        }
        console.log(file.target.files[0])
        const fileName = file.target.files[0].name
        fileNameEntry = file.target.files[0].name
        const fileType = file.target.files[0].type
        console.log("fileName=====================", fileName, file)

        const formData = new FormData();
        let newFile = file.target.files[0];
        formData.append("data", newFile);

        const toBase64 = file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);

        });

        var newBaseData = toBase64(newFile);
        const newBaseContent = {};
        (async () => {
            newBaseContent.data = await newBaseData;
            newBaseContent.fileName = fileName;
            newBaseContent.type = fileType;
            newBaseContent.location = 'uploadedDataSettings';
            newBaseContent.userID = userInfo.userID;
            newBaseContent.userRole = userInfo.userRole;
            newBaseContent.companyID = userInfo.companyID;
            console.log(newBaseContent)
            const body = {
                "url": "http://127.0.0.1:3022/upload/uploadFile",
                "requestBody": newBaseContent
            }
            axios.post("/v1.0/connectOP", body, {
                headers: headers
            })
                .then((res) => {
                    console.log("publisherReport Upload ==>", res)
                    setLoader(false)
                    if (res.data.success) {
                        setPublisherReport(publisherReport => ({ ...publisherReport, fileName: fileName }));
                    }
                    else {
                        ref.current.value = "";
                        toastr.error(res.data.message)
                        setInputKey(Math.random().toString(36))
                        fileNameEntry = ''
                    }
                })
                .catch((err) => {
                    setLoader(false)
                    console.log("e", err)
                    setInputKey(Math.random().toString(36))
                    fileNameEntry = ''
                });
        })();
    };

    const handleDelete = (data) => (e) => {
        // setTags(tags.filter((tag, index) => index !== i));
        console.log(list, itemList)
        list.splice(data.id - 1, 1)
        let arr = [...itemList]
        let index = arr.findIndex(x => x.name === data.name)
        console.log(index)
        arr.splice(index, 1)
        arr.map((item, index) => {
            item.id = index + 1
        })
        itemList = arr
        setItemListData(arr)
        console.log(list, arr)
        setPublisherReport(publisherReport => ({ ...publisherReport, networkBlockList: list }));
    };

    const handleAddition = tag => {
        if (tag.code == "Enter") {
            console.log(list, itemListData, itemListData.length)
            let arr2 = [{ name: tag.target.value, id: itemListData.length + 1 }]
            list.push(tag.target.value)
            let arr4 = [...itemListData, ...arr2]
            itemList = arr4
            setItemListData(arr4)
            console.log(itemList, arr4)
            setPublisherReport(publisherReport => ({ ...publisherReport, networkBlockList: list }));
            setItemBlock('')
            tag.preventDefault();
            return
        }


        // list.push(tag.id)
        // setTags([...tags, tag]);
        // setPublisherReport(publisherReport => ({ ...publisherReport, networkBlockList: list }));
        // return
    };

    const keyField = "id";

    const roleListColumns = [
        {
            text: "Sl.No",
            dataField: "id",
            sort: true,
        },
        {
            text: "Name",
            dataField: "name",
            sort: true,
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',

            // eslint-disable-next-line react/display-name
            formatter: (cellContent, deviceControl) => (
                <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
                    <Link className="text-danger" to="#" onClick={handleDelete(deviceControl)}>
                        <i
                            className="mdi mdi-delete font-size-18"
                            id="edittooltip"
                        ></i>
                    </Link>
                </div>
            ),
        },
    ];

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );


    const pageOptions = {
        paginationSize: 4,
        pageStartIndex: 1,
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: true, // Hide the going to First and Last page button
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };
    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "asc", // desc or asc
        },
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        console.log(publisherReport)
        let requestBody = {
            networkBlockType: props.networkBlockType,
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
        }
        const bodyReport = {
            "url": props.selectedData !== '' ? "http://127.0.0.1:2002/v1.0/targeting/" + props.networkBlockType + "/update" : "http://127.0.0.1:2002/v1.0/targeting/" + props.networkBlockType + "/create",
            "requestBody": {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
            }
        }
        if (props.networkBlockType !== "websiteCatorization" && publisherReport.groupName && (publisherReport.networkBlockList.length > 0 || publisherReport.fileName)) {
       
            if (props.networkBlockType === "brandAffinity") {
                if (props.selectedData !== '') {
                    bodyReport.url = "http://127.0.0.1:2002/v1.0/targeting/" + props.networkBlockType + "/update/" + props.selectedData.brandAffinityID
                    requestBody = { ...requestBody, brandAffinityID: props.selectedData.brandAffinityID }
                }
                if (publisherReport.networkBlockList.length > 0) {
                    requestBody = { ...requestBody, brandAffinityGroup: publisherReport.groupName, brandAffinityList: publisherReport.networkBlockList, isManual: true }
                }
                if (publisherReport.networkBlockList.length === 0 && publisherReport.fileName) {
                    requestBody = { ...requestBody, brandAffinityGroup: publisherReport.groupName, fileName: publisherReport.fileName, isManual: false }
                }
            }
            if (props.networkBlockType === "interest") {
                if (props.selectedData !== '') {
                    bodyReport.url = "http://127.0.0.1:2002/v1.0/targeting/" + props.networkBlockType + "/update/" + props.selectedData.interestID
                    requestBody = { ...requestBody, interestID: props.selectedData.interestID }
                }
                if (publisherReport.networkBlockList.length > 0) {
                    requestBody = { ...requestBody, interestGroup: publisherReport.groupName, interestList: publisherReport.networkBlockList, isManual: true }
                }
                if (publisherReport.networkBlockList.length === 0 && publisherReport.fileName) {
                    requestBody = { ...requestBody, interestGroup: publisherReport.groupName, fileName: publisherReport.fileName, isManual: false }
                }
            }
            if (props.networkBlockType === "keyword") {
                if (props.selectedData !== '') {
                    bodyReport.url = "http://127.0.0.1:2002/v1.0/targeting/" + props.networkBlockType + "/update/" + props.selectedData.keywordID
                    requestBody = { ...requestBody, keywordID: props.selectedData.keywordID }
                }
                if (publisherReport.networkBlockList.length > 0) {
                    requestBody = { ...requestBody, keywordGroup: publisherReport.groupName, keywordList: publisherReport.networkBlockList, isManual: true }
                }
                if (publisherReport.networkBlockList.length === 0 && publisherReport.fileName) {
                    requestBody = { ...requestBody, keywordGroup: publisherReport.groupName, fileName: publisherReport.fileName, isManual: false }
                }
            }
            if (props.networkBlockType === "iPMapping") {
                if (props.selectedData !== '') {
                    bodyReport.url = "http://127.0.0.1:2002/v1.0/targeting/" + props.networkBlockType + "/update/" + props.selectedData.iPMappingID
                    requestBody = { ...requestBody, iPMappingID: props.selectedData.iPMappingID }
                }
                if (publisherReport.networkBlockList.length > 0) {
                    requestBody = { ...requestBody, iPMappingGroup: publisherReport.groupName, iPMappingList: publisherReport.networkBlockList, isManual: true }
                }
                if (publisherReport.networkBlockList.length === 0 && publisherReport.fileName) {
                    requestBody = { ...requestBody, iPMappingGroup: publisherReport.groupName, fileName: publisherReport.fileName, isManual: false }
                }
            }
            if (props.networkBlockType === "PageURLGroups") {
                if (props.selectedData !== '') {
                    bodyReport.url = "http://127.0.0.1:2002/v1.0/targeting/" + props.networkBlockType + "/update/" + props.selectedData.pageURLGroupID
                    requestBody = { ...requestBody, pageURLGroupID: props.selectedData.pageURLGroupID }
                }
                if (publisherReport.networkBlockList.length > 0) {
                    requestBody = { ...requestBody, pageURLGroupName: publisherReport.groupName, pageURLGroupValues: publisherReport.networkBlockList, isManual: true }
                }
                if (publisherReport.networkBlockList.length === 0 && publisherReport.fileName) {
                    requestBody = { ...requestBody, pageURLGroupName: publisherReport.groupName, fileName: publisherReport.fileName, isManual: false }
                }
            }
        }
        if (props.networkBlockType === "websiteCatorization") {
            if (props.selectedData !== '') {
                requestBody = { ...requestBody, websiteID: props.selectedData.websiteID }
            } 
            if(websiteCategorization.category && websiteCategorization.language && websiteCategorization.website) {
                requestBody = { ...requestBody,...websiteCategorization }
            }
            if (publisherReport.fileName) {
                bodyReport.url = "http://127.0.0.1:2002/v1.0/targeting/websiteCatorization/create/excel"
                requestBody = { ...requestBody, filename: publisherReport.fileName, isManual: false }
            }
        }
        console.log(requestBody)
        setLoader(true)

        if (props.networkBlockType === "websiteCatorization" && props.selectedData !== '') {
            bodyReport.url = "http://127.0.0.1:2002/v1.0/targeting/websiteCatorization/update/" + props.selectedData.websiteID
            bodyReport.requestBody = { ...requestBody,...websiteCategorization }
        }
        bodyReport.requestBody = { ...requestBody }
        axios.post("/v1.0/connectReportAPI", bodyReport, {
            headers: headers
        })
            .then((res) => {
                console.log("publisherReport Upload ==>", res)
                setLoader(false)
                if (res.data) {
                    if (res.data.success) {
                        if (props.selectedData !== '') {
                            props.closeCanvas('Update')
                        } else {
                            props.closeCanvas('Create')
                        }

                    } else {
                        toastr.error(res.data.message)
                        if (res.data && res.data.DownloadfileName) {
                            setDownloadFileBtn(true)
                            setDownloadUrl(res.data.DownloadfileName)
                        }
                        setInputKey(Math.random().toString(36))
                        fileNameEntry = ''
                    }
                } else {
                    toastr.error(res.data)
                    setInputKey(Math.random().toString(36))
                    fileNameEntry = ''
                }
            })
            .catch((err) => {
                setLoader(false)
                setInputKey(Math.random().toString(36))
                fileNameEntry = ''
                console.log("e", err)
                toastr.error('File Upload error')
            });



    }

    const [urlsState, setUrlsState] = useState(false)

    const urlStateChange = e => {
        setUrlsState(e.target.checked)
    }



    return (
        <React.Fragment>
            <div >
                {loader &&
                    <Spinner className="ms-2 loader" color="primary" />
                }
                <Form onSubmit={handleSubmit}>
                    <div className="formBodyScroll" style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
                        <div className="col-md-12">
                            {props.networkBlockType !== "websiteCatorization" &&
                                <div className="mb-3 mt-3">
                                    <Label>Group Name</Label>
                                    <Input type="text" className="form-control" name="groupName" value={publisherReport.groupName} onChange={dataFeedChange}>
                                    </Input>
                                </div>
                            }
                            {props.networkBlockType !== "websiteCatorization" &&
                                <div>
                                    <div className="player-content player-content2 mb-3">
                                        <div className="d-flex">
                                            <label className="form-check-label mb-3 " style={{ marginTop: '0.8pc' }}>
                                                <span color='text-success' style={{ color: urlsState ? '#a3a3a3' : '#34c38f', marginTop: '' }}>Manual Items</span>&nbsp;&nbsp;
                                            </label>
                                            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >

                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customSwitchsizemd"
                                                    checked={urlsState}
                                                    onChange={urlStateChange}
                                                />
                                                <label className="form-check-label">
                                                    <span color='text-success' style={{ color: urlsState ? '#34c38f' : '#a3a3a3' }}>Items Upload by File</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <Label>Blocking List</Label>

                                        <Col lg="12">
                                            <Card>
                                                <CardBody>
                                                    <ToolkitProvider
                                                        keyField={keyField}
                                                        data={itemList}
                                                        columns={roleListColumns}
                                                        // bootstrap4
                                                        search
                                                    >
                                                        {toolkitProps => (
                                                            <React.Fragment>
                                                                <Row className="mb-2">
                                                                    <Col sm="4">
                                                                        <div className="search-box ms-2 mb-2 d-inline-block">
                                                                            <div className="position-relative">
                                                                                <SearchBar {...toolkitProps.searchProps} />
                                                                                <i className="bx bx-search-alt search-icon" />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xl="12" className="table-responsive">
                                                                        <div className="">
                                                                            <BootstrapTable
                                                                                keyField={keyField}
                                                                                responsive
                                                                                bordered={false}
                                                                                striped={false}
                                                                                defaultSorted={defaultSorted}
                                                                                classes={
                                                                                    "table align-middle table-nowrap"
                                                                                }
                                                                                headerWrapperClasses={"thead-light"}
                                                                                {...toolkitProps.baseProps}
                                                                                pagination={paginationFactory(pageOptions)}
                                                                                noDataIndication={<h5 className="text-center">No Data</h5>} />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </div>
                                </div>
                            }
                            {(props.networkBlockType === "websiteCatorization" && !isEdit) &&
                                    <div className="player-content player-content2 mb-3">
                                        <div className="d-flex">
                                            <label className="form-check-label mb-3 " style={{ marginTop: '0.8pc' }}>
                                                <span color='text-success' style={{ color: urlsState ? '#a3a3a3' : '#34c38f', marginTop: '' }}>Manual Items</span>&nbsp;&nbsp;
                                            </label>
                                            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >

                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customSwitchsizemd"
                                                    checked={urlsState}
                                                    onChange={urlStateChange}
                                                />
                                                <label className="form-check-label">
                                                    <span color='text-success' style={{ color: urlsState ? '#34c38f' : '#a3a3a3' }}>Items Upload by File</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                            }
                            {!urlsState &&
                                <div>
                                    {(props.networkBlockType === "websiteCatorization") ?
                                        <div>
                                            <div className="mb-3">
                                                <Label className="form-label">Website Name</Label>
                                                <Input
                                                    type="text" name="website" value={websiteCategorization.website} onChange={handleChange} className={'form-control'}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label">Langauage</Label>
                                                <Input
                                                    type="text" name="language" value={websiteCategorization.language} onChange={handleChange} className={'form-control'}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label">Category</Label>
                                                <Input
                                                    type="text" name="category" value={websiteCategorization.category} onChange={handleChange} className={'form-control'}
                                                />
                                            </div>
                                        </div>
                                        :
                                        <div className="mb-3">
                                            <Label className="form-label">{props.networkBlockType === 'brandAffinity' ? 'Brand Affinity' : props.networkBlockType === 'interest' ? 'Interest' : props.networkBlockType === 'keyword' ?
                                                'Keyword' : props.networkBlockType === 'iPMapping' ? 'IP Mapping' : props.networkBlockType === 'pageURLGroup' ? 'Page URL' : 'Website'} List</Label>

                                            <Input type="text" className="form-control" name="itemBlock" value={itemBlock} onChange={(e) => { setItemBlock(e.target.value) }} onKeyPress={handleAddition} placeholder="Press enter to add new Item">
                                            </Input>
                                            {/* <ReactTags
                                                    tags={tags}
                                                    handleDelete={handleDelete}
                                                    handleAddition={handleAddition}
                                                    inputFieldPosition="inline"
                                                    style={{ width: '100%' }}
                                                    autocomplete
                                                    placeholder="Press enter to add new Item"
                                                /> */}
                                        </div>
                                    }
                                </div>
                            }
                            {urlsState &&
                                <Card>
                                    <CardTitle className="mt-0 card-header bg-transparent border-bottom">
                                        Upload {props.networkBlockType === 'brandAffinity' ? 'Brand Affinity' : props.networkBlockType === 'interest' ? 'Interest' : props.networkBlockType === 'keyword' ?
                                            'Keyword' : props.networkBlockType === 'iPMapping' ? 'IP Mapping' : props.networkBlockType === 'pageURLGroup' ? 'Page URL' : 'Website Categorization'} List
                                    </CardTitle>
                                    <CardBody>
                                        <Input className="form-control" type="file" key={inputKey || ''} id="formFile" ref={ref} onChange={fileChangepublisherReport} accept=" application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                        {/* <div className="col-12  ">
                                    <div className="row justify-content-center mt-2 ">
                                        <Button color="primary" className="font-16 btn-block btn btn-primary w-25" onClick={publisherReportUpload} disabled={fileNameEntry === ''}>Upload</Button> &nbsp;&nbsp;
                                        {downloadFileBtn &&
                                            <Button color="primary" className="font-16 btn-block btn btn-primary w-25" onClick={() => downloadCSV(downloadUrl, 'Error File-' + currentTimeStamp + '.csv')}>Download Error File</Button>
                                        }
                                    </div>
                                </div> */}
                                        <hr className="hr" />
                                        <span className=" mt-3 mb-1 row justify-content-center">
                                            Upload Data in Excel format (.xlsx file)
                                        </span>
                                        <Row className="justify-content-center text-center">
                                            <Link to={props.networkBlockType === 'brandAffinity' ? BrandAffinity : props.networkBlockType === 'interest' ? Interest : props.networkBlockType === 'keyword' ?
                                                Keyword : props.networkBlockType === 'pageURLGroup' ? pageurl : props.networkBlockType === 'websiteCatorization' ? websiteCategorizedFile : ipmapping} target="_blank" className=" color-dark-blue"
                                                download={props.networkBlockType === 'brandAffinity' ? 'Brand Affinity' : props.networkBlockType === 'interest' ? 'Interest' : props.networkBlockType === 'keyword' ?
                                                    'Keyword' : props.networkBlockType === 'pageURLGroup' ? 'Page URL' : props.networkBlockType === 'websiteCatorization' ? 'Website Categorization' : 'IP Mapping' + ".xlsx"} style={{ width: 'fit-content' }}>
                                                Download Template for Data Upload
                                            </Link>
                                        </Row>
                                    </CardBody>
                                </Card>
                            }
                        </div>
                    </div>

                    <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '20px', margin: 0 }}>
                        <Col>
                            <div className="text-start">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    // eslint-disable-next-line react/prop-types
                                    onClick={props.closeCanvas}
                                >
                                    Back
                                </button>
                                &nbsp;&nbsp;
                                {!isEdit &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Save
                                    </button>
                                }
                                &nbsp;&nbsp;
                                {isEdit &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Update
                                    </button>
                                }
                            </div>
                        </Col>
                    </Row>
                </Form>

            </div>

        </React.Fragment >
    )
}

UploadBlockings.propTypes = {}

export default UploadBlockings