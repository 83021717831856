import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Toast,
    ToastHeader,
    ToastBody,
    Carousel, CarouselItem, CarouselControl
} from "reactstrap";
import { Link } from "react-router-dom";

import classNames from "classnames";

//import Charts
import StackedColumnChart from "pages/Dashboard/StackedColumnChart";

//import action
import { getChartsData as onGetChartsData } from "../../store/actions";

// Pages Components
import WelcomeComp from "pages/Dashboard/WelcomeComp";

//Import Breadcrumb
import Breadcrumb from 'components/Common/Breadcrumb';

import DateRangePickers from '../../helpers/DateRangePicker'

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import Title from 'pages/Title';


const items = [
    {
        id: 1,
        msg: "Notification 1",
        caption: "Slide 1",
        type: 'info',
        color: '#556EE6'
    },
    {
        id: 2,
        msg: "Notification 2",
        caption: "Slide 2",
        type: 'error',
        color: '#F46A6A'
    },
    {
        id: 3,
        msg: "Notification 3",
        caption: "Slide 3",
        type: 'warning',
        color: '#F1B44C'
    },
]

var periods = {
    "chartData": [
        {
            "name": "Total Code Served",
            "type": "column",
            "data": [
                144033,
                4685,
                18539,
                35792,
                67456,
                56920,
                194349,
                108473
            ]
        },
        {
            "name": "Estimated Revenue",
            "type": "column",
            "data": [
                3.54,
                0,
                2.32,
                1.98,
                3.12,
                4.41,
                8.54,
                20.11
            ]
        },
        {
            "name": "Estimated eCPM",
            "type": "line",
            "data": [
                2,
                0,
                13,
                6,
                5,
                8,
                4,
                19
            ]
        }
    ],
    "xAxis": [
        "2023-01-07",
        "2023-01-08",
        "2023-01-01",
        "2023-01-02",
        "2023-01-03",
        "2023-01-04",
        "2023-01-05",
        "2023-01-06"
    ],
    "dataColors": "[\"--bs-danger\",\"--bs-primary\", \"--bs-success\"]",
    "title": "Overview of Total code served | Revenue | Estimated eCPM"
}
const PlayerDashboard = (props) => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [modal, setmodal] = useState(false);
    const [subscribemodal, setSubscribemodal] = useState(false);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());

    const [periodType, setPeriodType] = useState("daily");
    const [stdbPeriodType, setStdbPeriodType] = useState("month");
    const [highImpactPeriodType, setHighImpactPeriodType] = useState("month");
    const [videoPeriodType, setVideoPeriodType] = useState("month");

    const [adUnitData, setAdUnitData] = useState([]);
    const [highImpactAdUnitData, setHighImpactAdUnitData] = useState([]);
    const [videoAdUnitData, setVideoAdUnitData] = useState([]);
    const [selectedAdUnit, setSelectedAdUnit] = useState('')
    const [selectedHighImpactAdUnit, setSelectedHighImpactAdUnit] = useState('')
    const [selectedVideoAdUnit, setSelectedVideoAdUnit] = useState('')

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const changeDatePick = (event) => {
        console.log(event)
        console.log("start: ", event.startDate._d);
        console.log("end: ", event.endDate._d);
        setFromDate(event.startDate._d.toISOString());
        setToDate(event.endDate._d.toISOString());
    }

    const { chartsData } = useSelector(state => ({
        chartsData: state.Dashboard.chartsData
    }));

    console.log(userInfo)

    const reports = [
        { title: "Total Code Served ", iconClass: "bx-bar-chart", description: "1,00,358", yesterdayRate: '25%', isIncreased: true },
        { title: "Inventory", iconClass: "bx-bar-chart", description: "35,723", yesterdayRate: '5%', isIncreased: true },
        { title: "Player Start", iconClass: "bx-bar-chart", description: "34,252", yesterdayRate: '1%', isIncreased: false },
        { title: "Ad Calls", iconClass: "bx-bar-chart", description: "33,123", yesterdayRate: '3%', isIncreased: false },
        { title: "Ad Call Rate %", iconClass: "bx-show-alt", description: "88%", yesterdayRate: '3%', isIncreased: false },
        { title: "Ad Impressions", iconClass: "bx-show-alt", description: "31,321", yesterdayRate: '3%', isIncreased: false },
        { title: "Publisher Fill Rate", iconClass: "bx-show-alt", description: "28,753", yesterdayRate: '3%', isIncreased: true },
        { title: "Publisher CPM", iconClass: "bx-dollar-circle", description: "$10", yesterdayRate: '3%', isIncreased: false },
        { title: "Publisher Revenue", iconClass: "bx-dollar-circle", description: "$13,159", yesterdayRate: '3%', isIncreased: false },
    ];

    useEffect(() => {
        getAdUnitsData('stdb')
        getAdUnitsData('highImpact')
        getAdUnitsData('video')
    }, []);

    const onChangeAdUnit = (e) => {
        setSelectedAdUnit(e.target.value)
    };

    const onChangeHighImpactAdUnit = (e) => {
        setSelectedHighImpactAdUnit(e.target.value)
    };

    const onChangeVideoAdUnit = (e) => {
        setSelectedVideoAdUnit(e.target.value)
    };

    const onChangeChartPeriod = pType => {
        setPeriodType(pType);
        dispatch(onGetChartsData(pType));
    };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(onGetChartsData("yearly"));
    }, [dispatch]);

    const [toast, setToast] = useState(true);

    const toggleToast = () => {
        setToast(!toast);
    };

    const getAdUnitsData = (IType) => {
        const body = {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                name: userInfo.name,
                adUnitGroupID: '',
                filter: 'Active',
                adUnitType: IType,
                adUnitSize: ''
        }
        axios.post("/v2.0/supply/AdUnits/read", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is adUnitdData ==>", res)
                if (res.data.success) {
                    if (IType === 'stdb') {
                        setAdUnitData(objectAdUnitModal(res.data.response))
                    } else if (IType === 'highImpact') {
                        setHighImpactAdUnitData(objectAdUnitModal(res.data.response))
                    }
                    else {
                        setVideoAdUnitData(objectAdUnitModal(res.data.response))
                    }
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }

    const objectAdUnitModal = (response) => {
        var dataList = []
        response.map((item, index) => {
            let obj = {}
            obj.value = item.adUnitID
            obj.label = item.adUnitName
            dataList.push(obj)
        })
        return dataList
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Player Dashboard | Bidsxchange</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Player Dashboard" breadcrumbItem="Player Dashboard" />
                    <Row>
                        <Col md="8">
                        </Col>
                        <Col md="4">
                            <div className=" text-sm-end mb-4">
                                <DateRangePickers datePick={changeDatePick} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md='4'>
                            <WelcomeComp />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="12">
                            <Row>
                                {/* Reports Render */}

                                {reports.map((report, key) => (
                                    <Col md="4" key={"_col_" + key}>
                                        <Card className="mini-stats-wid dashboard-cards">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium">
                                                            {report.title}
                                                        </p>
                                                        <h4 style={{ marginBottom: '3%' }}>{report.description}</h4>
                                                        <span className={report.isIncreased ? 'badge badge-soft-success' : 'badge badge-soft-danger'} style={{ fontSize: '12px', fontWeight: '500' }}>
                                                            {(report.isIncreased ? " + " : " - ")} {report.yesterdayRate}</span>
                                                    </div>
                                                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                        <span className="avatar-title rounded-circle bg-primary">
                                                            <i
                                                                className={
                                                                    "bx " + report.iconClass + " font-size-24"
                                                                }
                                                            ></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl="12">

                            <Card>
                                <CardBody>
                                    <div className="d-sm-flex flex-wrap">
                                        <h4 className="card-title mb-4">Overview of Total Code Served | Revenue | eCPM</h4>
                                        <div className="ms-auto">
                                            <ul className="nav nav-pills">
                                                <li className="nav-item">
                                                    <Link
                                                        to="#"
                                                        className={classNames(
                                                            { active: periodType === "daily" },
                                                            "nav-link"
                                                        )}
                                                        onClick={() => {
                                                            onChangeChartPeriod("daily");
                                                        }}
                                                        id="one_month"
                                                    >
                                                        Day
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        to="#"
                                                        className={classNames(
                                                            { active: periodType === "weekly" },
                                                            "nav-link"
                                                        )}
                                                        onClick={() => {
                                                            onChangeChartPeriod("weekly");
                                                        }}
                                                        id="one_month"
                                                    >
                                                        Week
                                                    </Link>{" "}
                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        to="#"
                                                        className={classNames(
                                                            { active: periodType === "monthly" },
                                                            "nav-link"
                                                        )}
                                                        onClick={() => {
                                                            onChangeChartPeriod("monthly");
                                                        }}
                                                        id="one_month"
                                                    >
                                                        Month
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="clearfix mt-2"></div>
                                    <StackedColumnChart chartData={periods.chartData} xAxisData={periods.xAxis} dataColors='["--bs-danger","--bs-primary", "--bs-success"]' title={''} />
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <div className="d-sm-flex flex-wrap">
                                        <h4 className="card-title mb-4">Video</h4>
                                        <span style={{ position: 'absolute', width: '20%', left: '35%' }}>
                                            <Input
                                                name="selectedVideoAdUnit"
                                                type="select"
                                                className="form-select "
                                                onChange={onChangeVideoAdUnit}
                                                value={selectedVideoAdUnit}
                                            >
                                                <option value='' disabled>Select Ad Unit</option>
                                                {videoAdUnitData.length > 0 && videoAdUnitData.map((elem) => (
                                                    <option key={elem.value} value={elem.value}>{elem.label}</option>
                                                ))}
                                            </Input>
                                        </span>
                                        <div className="ms-auto">
                                            <ul className="nav nav-pills">
                                                <li className="nav-item">
                                                    <Link
                                                        to="#"
                                                        className={classNames(
                                                            { active: periodType === "daily" },
                                                            "nav-link"
                                                        )}
                                                        onClick={() => {
                                                            onChangeChartPeriod("daily");
                                                        }}
                                                        id="one_month"
                                                    >
                                                        Day
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        to="#"
                                                        className={classNames(
                                                            { active: periodType === "weekly" },
                                                            "nav-link"
                                                        )}
                                                        onClick={() => {
                                                            onChangeChartPeriod("weekly");
                                                        }}
                                                        id="one_month"
                                                    >
                                                        Week
                                                    </Link>{" "}
                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        to="#"
                                                        className={classNames(
                                                            { active: periodType === "monthly" },
                                                            "nav-link"
                                                        )}
                                                        onClick={() => {
                                                            onChangeChartPeriod("monthly");
                                                        }}
                                                        id="one_month"
                                                    >
                                                        Month
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="clearfix mt-2"></div>
                                    <StackedColumnChart chartData={periods.chartData} xAxisData={periods.xAxis} dataColors='["--bs-danger","--bs-primary", "--bs-success"]' title={''} />
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <div className="d-sm-flex flex-wrap">
                                        <h4 className="card-title mb-4">High Impact</h4>
                                        <span style={{ position: 'absolute', width: '20%', left: '35%' }}>
                                            <Input
                                                name="selectedHighImpactAdUnit"
                                                type="select"
                                                className="form-select "
                                                onChange={onChangeHighImpactAdUnit}
                                                value={selectedHighImpactAdUnit}
                                            >
                                                <option value='' disabled>Select Ad Unit</option>
                                                {highImpactAdUnitData.length > 0 && highImpactAdUnitData.map((elem) => (
                                                    <option key={elem.value} value={elem.value}>{elem.label}</option>
                                                ))}
                                            </Input>
                                        </span>
                                        <div className="ms-auto">
                                            <ul className="nav nav-pills">
                                                <li className="nav-item">
                                                    <Link
                                                        to="#"
                                                        className={classNames(
                                                            { active: periodType === "daily" },
                                                            "nav-link"
                                                        )}
                                                        onClick={() => {
                                                            onChangeChartPeriod("daily");
                                                        }}
                                                        id="one_month"
                                                    >
                                                        Day
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        to="#"
                                                        className={classNames(
                                                            { active: periodType === "weekly" },
                                                            "nav-link"
                                                        )}
                                                        onClick={() => {
                                                            onChangeChartPeriod("weekly");
                                                        }}
                                                        id="one_month"
                                                    >
                                                        Week
                                                    </Link>{" "}
                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        to="#"
                                                        className={classNames(
                                                            { active: periodType === "monthly" },
                                                            "nav-link"
                                                        )}
                                                        onClick={() => {
                                                            onChangeChartPeriod("monthly");
                                                        }}
                                                        id="one_month"
                                                    >
                                                        Month
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="clearfix mt-2"></div>
                                    <StackedColumnChart chartData={periods.chartData} xAxisData={periods.xAxis} dataColors='["--bs-danger","--bs-primary", "--bs-success"]' title={''} />
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <div className="d-sm-flex flex-wrap">
                                        <h4 className="card-title mb-4">Standard Banner</h4>
                                        <span style={{ position: 'absolute', width: '20%', left: '35%' }}>
                                            <Input
                                                name="selectedAdUnit"
                                                type="select"
                                                className="form-select "
                                                onChange={onChangeAdUnit}
                                                value={selectedAdUnit}
                                            >
                                                <option value='' disabled>Select Ad Unit</option>
                                                {adUnitData.length > 0 && adUnitData.map((elem) => (
                                                    <option key={elem.value} value={elem.value}>{elem.label}</option>
                                                ))}
                                            </Input>
                                        </span>
                                        <div className="ms-auto">
                                            <ul className="nav nav-pills">
                                                <li className="nav-item">
                                                    <Link
                                                        to="#"
                                                        className={classNames(
                                                            { active: periodType === "daily" },
                                                            "nav-link"
                                                        )}
                                                        onClick={() => {
                                                            onChangeChartPeriod("daily");
                                                        }}
                                                        id="one_month"
                                                    >
                                                        Day
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        to="#"
                                                        className={classNames(
                                                            { active: periodType === "weekly" },
                                                            "nav-link"
                                                        )}
                                                        onClick={() => {
                                                            onChangeChartPeriod("weekly");
                                                        }}
                                                        id="one_month"
                                                    >
                                                        Week
                                                    </Link>{" "}
                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        to="#"
                                                        className={classNames(
                                                            { active: periodType === "monthly" },
                                                            "nav-link"
                                                        )}
                                                        onClick={() => {
                                                            onChangeChartPeriod("monthly");
                                                        }}
                                                        id="one_month"
                                                    >
                                                        Month
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="clearfix mt-2"></div>
                                    <StackedColumnChart chartData={periods.chartData} xAxisData={periods.xAxis} dataColors='["--bs-danger","--bs-primary", "--bs-success"]' title={''} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>


                {/* <Modal
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal);
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setSubscribemodal(!subscribemodal);
            }}
          ></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i className="mdi mdi-email-open"></i>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <h4 className="text-primary">Subscribe !</h4>
                <p className="text-muted font-size-14 mb-4">
                  Subscribe our newletter and get notification to stay update.
                </p>

                <div
                  className="input-group rounded bg-light"
                >
                  <Input
                    type="email"
                    className="form-control bg-transparent border-0"
                    placeholder="Enter Email address"
                  />
                  <Button color="primary" type="button" id="button-addon2">
                    <i className="bx bxs-paper-plane"></i>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}

                {/* <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal);
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal> */}
            </div>
        </React.Fragment>
    );
};

PlayerDashboard.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
}

export default withTranslation()(PlayerDashboard)