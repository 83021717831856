import {
    LOAD_CREATIVES,
    NO_DATA,
    GET_CREATIVES_SUCCESS,
    GET_CREATIVES_FAIL,
    ADD_CREATIVE_SUCCESS,
    ADD_CREATIVE_ERROR,
    UPDATE_CREATIVE_SUCCESS,
    UPDATE_CREATIVE_ERROR,
    READ_CREATIVE_SUCCESS,
    STATUS_CREATIVE_SUCCESS,
    ARCHIVE_CREATIVE_SUCCESS,
    ADD_VIDEO_CREATIVE_SUCCESS,
    ADD_VIDEO_CREATIVE_ERROR,
    UPDATE_VIDEO_CREATIVE_SUCCESS,
    UPDATE_VIDEO_CREATIVE_ERROR,
    READ_VIDEO_CREATIVE_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    creatives: [],
    pagination:{},
    error: {},
    loading:false,
    noData:false,
    successMsg: null,
    errorMsg: null,
    creative: null,
    response:null,
    archiveMsg:null,
}

const creatives = (state = INIT_STATE, action) => {
    console.log
    switch (action.type) {
        case LOAD_CREATIVES:
            return {
                ...state,
                loading: true,
            }
        case NO_DATA:
                return {
                    ...state,
                    noData: true,
                }
        case GET_CREATIVES_SUCCESS:
            return {
                ...state,
                loading: false,
                creatives: action.payload.response ? action.payload.response : [],
                pagination:action.payload
            }

        case GET_CREATIVES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case ADD_CREATIVE_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_CREATIVE_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_CREATIVES_SUCCESS' ? null : action.payload,
            }
        case UPDATE_CREATIVE_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_CREATIVES_SUCCESS' ? null : action.payload,
            }
        case UPDATE_CREATIVE_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_CREATIVES_SUCCESS' ? null : action.payload,
            }

            case ADD_VIDEO_CREATIVE_SUCCESS:

                return {
                    ...state,
                    successMsg: action.payload,
                }
            case ADD_VIDEO_CREATIVE_ERROR:
                return {
                    ...state,
                    error: action.payload,
                    errorMsg: action.type === 'GET_CREATIVES_SUCCESS' ? null : action.payload,
                }
            case UPDATE_VIDEO_CREATIVE_SUCCESS:
                return {
                    ...state,
                    successMsg: action.type === 'GET_CREATIVES_SUCCESS' ? null : action.payload,
                }
            case UPDATE_VIDEO_CREATIVE_ERROR:
                return {
                    ...state,
                    error: action.payload,
                    errorMsg: action.type === 'GET_CREATIVES_SUCCESS' ? null : action.payload,
                }
        case READ_CREATIVE_SUCCESS:
            return {
                ...state,
                creative: action.payload,
            }
            case READ_VIDEO_CREATIVE_SUCCESS:
                return {
                    ...state,
                    creative: action.payload,
                }
        case STATUS_CREATIVE_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_CREATIVE_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default creatives