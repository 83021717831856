import React, { useEffect, useState, useRef } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import MetaTags from 'react-meta-tags';
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Input,
  Spinner,
  Label,
  BreadcrumbItem
} from "reactstrap"

import DateRangePickers from '../../../helpers/DateRangePicker'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import {
  getPub_Notifications as onGetPub_Notifications,
  statusPub_Notification as onStatusPub_Notification,
  archivePub_Notification as onArchivePub_Notification,
  statusPub_NotificationSuccess,
  archivePub_NotificationSuccess,
  addPub_NotificationFail,
  updatePub_NotificationFail,
} from "store/Notifications/Publisher_Notification/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import New_Publisher_Notification from "./New_Publisher_Notification";
import LoadingTable from "pages/LoaderPages/LoadingTable";
import AdminSidebar from "../Admin_Sidebar/AdminSidebar";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var filterCount = 0

const Publisher_Notification = props => {
  const dispatch = useDispatch();
  const [isRight, setIsRight] = useState(false);
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const [statusSearch, setStatusSearch] = useState('Active')
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [isArchived, setIsArchived] = useState(false)
  const [selected, setSelected] = useState([])
  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const [switchLoader, setSwitchLoader] = useState(true)

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  const changeDatePick = (event) => {
    console.log(event)
    console.log("start: ", event.startDate._d);
    console.log("end: ", event.endDate._d);
    setFromDate(event.startDate._d.toISOString());
    setToDate(event.endDate._d.toISOString());
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };

  useEffect(() => {
    // dispatch(onGetPub_Notifications(null));
  }, []);

  const selectRow = {
    mode: "checkbox",
    nonSelectable: [],
    selected: selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelected([...selected, row.id])
        setSelectedRowsStatus(true)
        selectedIndex.push(row.notificationID)
        selectedItem.push(row.notificationID)
      } else {
        setSelected(selected.filter(x => x !== row.id))
        let index = selectedIndex.indexOf(row.notificationID)
        selectedIndex.splice(index, 1)
        if (selectedIndex.length === 0) {
          setSelectedRowsStatus(false)
        }
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      const ids = rows.map(r => r.id);
      if (isSelect) {
        setSelected(ids)
        rows.map((item, index) => {
          selectedIndex.push(item.notificationID)
        })
        setSelectedRowsStatus(true)
      } else {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
      }
    }
  };

  const [filterState, setFilterState] = useState(false)

  const statusChange = (e) => {
    if (e.target.value === 'Active') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (statusSearch === 'Active') {
      filterCount = filterCount + 1
    }
    setStatusSearch(e.target.value)
  }

  const applyFilter = (e) => {
    dispatch(onGetPub_Notifications({ filter: statusSearch }));
    if (filterCount > 0) {
      setFilterState(true)
    } else {
      setFilterState(false)
    }
  }

  const [isMenu, setisMenu] = useState(false)
  const toggleMenu = () => {
    setisMenu(!isMenu)
  }

  const clearAllFilter = (e) => {
    setStatusSearch('Active')
    filterCount = 0
    setFilterState(false)
    dispatch(onGetPub_Notifications({ filter: 'Active' }));
  }

  const handleClose = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
    selectedIndex = []
  }

  const { pubNotifications } = useSelector(state => ({
    pubNotifications: state.pub_notifications.nots,
  }));

  const { loading } = useSelector(state => ({
    loading: state.pub_notifications.loading,
  }));

  const { pubNotificationsResponse } = useSelector(state => ({
    pubNotificationsResponse: state.pub_notifications.response,
  }));

  const { archivePub_NotificationsResponse } = useSelector(state => ({
    archivePub_NotificationsResponse: state.pub_notifications.archiveMsg,
  }));

  // const { errorMsg, successMsg } = useSelector(state => ({
  //   errorMsg: state.pubNotifications.errorMsg,
  //   successMsg: state.pubNotifications.successMsg,
  // }));

  useEffect(() => {
    console.log(loading)
    if (loading) {
      setSwitchLoader(true)
    }

  }, [loading]);

  console.log(pubNotificationsResponse)
  useEffect(() => {
    if (pubNotificationsResponse !== null) {
      if (pubNotificationsResponse.success) {
        toastr.success('Notification Status Changed Successfully')
        dispatch(onGetPub_Notifications({ filter: statusSearch }));
        dispatch(statusPub_NotificationSuccess(null));
        selectedItem = []
        setSelected([])
        selectedIndex = []
        setSelectedRowsStatus(false)
      }
    }
  }, [dispatch, pubNotificationsResponse]);

  useEffect(() => {
    if (archivePub_NotificationsResponse !== null) {
      if (archivePub_NotificationsResponse.success) {
        toastr.success('Notification Archived Successfully')
        dispatch(onGetPub_Notifications({ filter: statusSearch }));
        dispatch(archivePub_NotificationSuccess(null));
        selectedItem = []
        setSelected([])
        selectedIndex = []
        setSelectedRowsStatus(false)

      }
    }
  }, [dispatch, archivePub_NotificationsResponse]);

  const toggleRightCanvas = () => {
    selectedData = ''
    dispatch(addPub_NotificationFail(""));
    dispatch(updatePub_NotificationFail(""));
    dispatch(onGetPub_Notifications({ filter: statusSearch }));
    setIsRight(!isRight);
  };

  const toggleEnableScroll = () => {
    setIsEnableScroll(!isEnableScroll);
  };

  const toggleDrawer = (close, pubNotification) => (event) => {
    console.log(event)
    if (event === 'Create') {
      toastr.success('Notification Saved Successfully!...')
    }
    if (event === 'Update') {
      toastr.success('Notification Updated Successfully!...')
    }
    selectedData = pubNotification
    setIsArchived(pubNotification.isNotificationsArchived)
    dispatch(onGetPub_Notifications({ filter: statusSearch }));
    dispatch(addPub_NotificationFail(""));
    dispatch(updatePub_NotificationFail(""));
    setIsRight(close);
  };

  useEffect(() => {
    if (pubNotifications !== null) {
      dispatch(onGetPub_Notifications({ filter: statusSearch }));
    }
  }, []);

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const pubNotificationListColumns = [
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "notificationID",
      sort: true,
      formatter: (cellContent, pubNotification) => (
        <>
          <span>{pubNotification.notificationID}</span>
        </>
      ),
    },
    {
      dataField: "notificationCreateTime",
      text: "Creation Date",
      sort: true,
    },

    {
      text: "Notification Name",
      dataField: "notificationName",
      sort: true,
    },
    {
      text: "Notification Data",
      dataField: "message",
      sort: true,
      formatter: (cellContent, pubNotification) => (
        <>
          <span dangerouslySetInnerHTML={{ __html: pubNotification.message }}></span>
        </>

      ),
    },
    {
      text: "For ",
      dataField: "forType",
      sort: true,
    },
    {
      dataField: "NotificationStatus",
      text: "Status",
      sort: true,
      formatter: (cellContent, pubNotification) => (
        <>
          <span className={pubNotification.newStatus === 'Active' ? "active-state" : pubNotification.newStatus === 'Inactive' ? "inactive-state" : "archived-state"}>{pubNotification.newStatus}</span>
        </>

      ),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',
      hidden: (UserRolePrivileges.adminAccess && UserRolePrivileges.adminAccess.Notification) && (!UserRolePrivileges.adminAccess.Notification.update),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, pubNotification) => (
        <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
          <Link className="text-success" to="#" onClick={toggleDrawer(true, pubNotification)}>
            {pubNotification.newStatus === 'Archived' &&
              <i
                className="mdi mdi-eye font-size-18"
                id="edittooltip"
              ></i>
            }
            {pubNotification.newStatus !== 'Archived' &&
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
              ></i>
            }
          </Link>
        </div>
      ),
    },
  ];

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };
  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const archivePub_Notification = async () => {
    dispatch(onArchivePub_Notification({ idList: selectedItem }));
  }

  const statusPub_Notification = (state) => (event) => {
    console.log(state)
    dispatch(onStatusPub_Notification({ idList: selectedItem, notificationStatus: state }));
  }

  console.log(pubNotifications)

  if (pubNotifications.length > 0) {
    if (UserRolePrivileges.Demand && UserRolePrivileges.adminAccess.Notification) {
      if (!UserRolePrivileges.adminAccess.Notification.archive && !UserRolePrivileges.adminAccess.Notification.status) {
        pubNotifications.map((item, index) => {
          selectRow.nonSelectable.push(index + 1)
        })
      } else {
        pubNotifications.map((item, index) => {
          if (item.isNotificationsArchived) {
            selectRow.nonSelectable.push(index + 1)
          }
        })
      }
    }
  }


  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      {/* <AdminSidebar />
      <div className="main-content access-management"> */}
      <div className="page-content">
        <MetaTags>
          <title> Notification | Bidsxchange</title>
        </MetaTags>

        {/* {loading &&
            <Spinner className="ms-2 loader" color="primary" />
          } */}
        <Container fluid style={{ pointerEvents: (loading) ? 'none' : 'unset' }}>
          {/* Render Breadcrumbs */}
          <div className=" d-sm-flex  justify-content-between">
            <Breadcrumbs title="Admin" breadcrumbItem=" Notification" breadList="All Notifications" />
            {UserRolePrivileges.Demand && UserRolePrivileges.adminAccess.Notification && UserRolePrivileges.adminAccess.Notification.create &&
              <div className=" text-sm-end">
                <div className="btn-group">
                  <Dropdown
                    direction="left"
                    isOpen={isMenu}
                    toggle={toggleMenu}
                  >
                    <DropdownToggle
                      tag="button"
                      className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                    >
                      <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                      {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                        Filter Applied
                      </span> : <span className="d-none font-16 d-sm-inline-block">
                        Filter
                      </span>
                      }
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                      <div className="dropdown-item-text">
                        <div className="row">
                          <h5 className="mb-0">Filters</h5>
                        </div>
                      </div>

                      <DropdownItem divider />
                      <Card>
                        <CardBody aria-hidden="true">
                          <Row >
                            <Col sm="12" className="mt-2">
                              <Label className="form-label">Status</Label>
                              <div className=" text-sm-end">
                                <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                  <option value=''> All</option>
                                  <option value='Active' > Active</option>
                                  <option value='Inactive'> InActive</option>
                                  <option value='Archived'> Archived</option>
                                </Input>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      <DropdownItem divider />
                      <Row>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={clearAllFilter}
                          >
                            Clear ALL
                          </DropdownItem>
                        </Col>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={applyFilter}
                          >
                            Apply Filter
                          </DropdownItem>
                        </Col>
                      </Row>

                    </DropdownMenu>
                  </Dropdown>
                </div> &nbsp;&nbsp;
                <Button
                  color="primary"
                  className="font-16 btn-block btn btn-primary"
                  onClick={toggleRightCanvas}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Add Notification
                </Button>
              </div>
            }
          </div>
          {switchLoader &&
            <LoadingTable loading={loading} targetLoading={handleSwitch} />
          }
          {!switchLoader &&
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody aria-hidden="true">
                          <ToolkitProvider
                            keyField={keyField}
                            data={pubNotifications}
                            columns={pubNotificationListColumns}
                            // bootstrap4
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>

                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar {...toolkitProps.searchProps} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="4"></Col>
                                  <Col sm="4" className="date-range ">
                                    <div className="text-sm-end">
                                      <DateRangePickers datePick={changeDatePick} />
                                    </div>
                                  </Col>

                                </Row>
                                <Row>
                                  <Col xl="12">
                                    {selectedRowsStatus && selectedIndex.length > 0 &&
                                      <div className="row m-0 React-Table-Selection">
                                        {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                                        <div style={{ padding: '0.5% 2%' }}>

                                          {UserRolePrivileges.Demand && UserRolePrivileges.adminAccess.Notification && UserRolePrivileges.adminAccess.Notification.status &&
                                            <button color="primary" className="btn btn-primary " onClick={statusPub_Notification(true)}>
                                              Activate
                                            </button>
                                          }
                                          &nbsp;  &nbsp;
                                          {UserRolePrivileges.Demand && UserRolePrivileges.adminAccess.Notification && UserRolePrivileges.adminAccess.Notification.status &&
                                            <button color="primary" className="btn btn-primary " onClick={statusPub_Notification(false)}>
                                              DeActivate
                                            </button>
                                          }
                                          &nbsp;  &nbsp;
                                          {UserRolePrivileges.Demand && UserRolePrivileges.adminAccess.Notification && UserRolePrivileges.adminAccess.Notification.archive &&
                                            <Button color="primary" className="btn btn-primary " onClick={archivePub_Notification} >
                                              Archive
                                            </Button>
                                          }
                                          <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                            className="mdi mdi-close-thick font-size-18"
                                          ></i></Button>
                                        </div>
                                      </div>
                                    }
                                  </Col>
                                  <Col xl="12" className="table-responsive">
                                    <div >
                                      <BootstrapTable
                                        keyField={keyField}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        pagination={paginationFactory(pageOptions)}
                                        noDataIndication={<h5 className="text-center">No Data</h5>}
                                      // ref={node}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
        </Container>
      </div>
      {/* </div> */}
      {/* </div> */}
      <Offcanvas isOpen={isRight} direction='end'  >
        <OffcanvasHeader toggle={toggleRightCanvas}>
          {selectedData ? 'Edit Notification' : 'Add Notification'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <New_Publisher_Notification closeCanvas={toggleDrawer(false, '')} selected_Notification={selectedData} selectedArchived={isArchived} />
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment >
  )
}

Publisher_Notification.propTypes = {}

export default Publisher_Notification