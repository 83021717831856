import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
} from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import Create_PM from "./Create_PM"
import Create_Partner from "./Create_Partner"

import {
    getPartners as onGetPartners,
    statusPartner as onStatusPartner,
    archivePartner as onArchivePartner,
    statusPartnerSuccess,
    archivePartnerSuccess,
    addPartnerFail,
    updatePartnerFail,
} from "store/PartnerManagement/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

var selectedItem = []
var selectedData = ''
var selectedIndex = []

const Partner_Management = () => {
    const dispatch = useDispatch();
    const [isRight, setIsRight] = useState(false);
    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
    const [selected, setSelected] = useState([])

    toastr.options = {
        positionClass: "toast-top-right",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 1000,
        extendedTimeOut: 1000
      };

      const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
          setIsRight(false);
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
          document.removeEventListener("keydown", escFunction, false);
        };
      }, [escFunction]);

    const selectRow = {
        mode: "checkbox",
        selected: selected,
        onSelect: (row, isSelect, rowIndex, e) => {
            console.log(row, isSelect, rowIndex)
            if (isSelect) {
                setSelected([...selected, row.id])
                setSelectedRowsStatus(true)
                selectedIndex.push(row.partnerID)
                selectedItem.push(row.partnerID)
                console.log(selectedIndex)
            } else {
                setSelected(selected.filter(x => x !== row.id))
                let index = selectedIndex.indexOf(row.partnerID)
                selectedIndex.splice(index, 1)
                if (selectedIndex.length === 0) {
                    setSelectedRowsStatus(false)
                }
                console.log(selectedIndex)
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            const ids = rows.map(r => r.id);
            if (isSelect) {
                setSelected(ids)
                    rows.map((item, index) => {
                    selectedIndex.push(item.partnerID)
                  })
                  setSelectedRowsStatus(true)
              } else {
                setSelected([])
                  setSelectedRowsStatus(false)
                  selectedIndex = []
              }
        }
    };

    const handleClose = () => {
        setSelectedRowsStatus(false)
        selectedItem = []
        setSelected([])
    }

    const { partners } = useSelector(state => ({
        partners: state.partners.partners,
    }));

    const { partnersResponse } = useSelector(state => ({
        partnersResponse: state.partners.response,
    }));

    const { archivePartnersResponse } = useSelector(state => ({
        archivePartnersResponse: state.partners.archiveMsg,
    }));
 
    console.log(partnersResponse)
    useEffect(() => {
        if (partnersResponse !== null) {
            if (partnersResponse.success) {
                toastr.success('Partner Status Changed Successfully')
                dispatch(onGetPartners());
                dispatch(statusPartnerSuccess(null));
                selectedItem = []
                selectedIndex = []
                setSelected([])
                setSelectedRowsStatus(false)
            }
        }
    }, [dispatch, partnersResponse]);

    useEffect(() => {
        if (archivePartnersResponse !== null) {
            if (archivePartnersResponse.success) {
                toastr.success('Partner Archived Successfully')
                dispatch(onGetPartners());
                dispatch(archivePartnerSuccess(null));
                selectedItem = []
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
            }
        }
    }, [dispatch, archivePartnersResponse]);

    const toggleRightCanvas = () => {
        selectedData = ''
        dispatch(onGetPartners());
        dispatch(addPartnerFail(null));
        dispatch(updatePartnerFail(null));
        setIsRight(!isRight);
    };
    const toggleEnableScroll = () => {
        setIsEnableScroll(!isEnableScroll);
    };

    const toggleDrawer = (close, partner) => (event) => {
        console.log(event)
        if (event === 'Create') {
            toastr.success('Partner Saved Successfully!...')
        }
        if (event === 'Update') {
            toastr.success('Partner Updated Successfully!...')
        }
        selectedData = partner
        dispatch(onGetPartners());
        dispatch(addPartnerFail(null));
        dispatch(updatePartnerFail(null));
        setIsRight(close);
    };

    useEffect(() => {
        if (partners !== null) {
            dispatch(onGetPartners());
        }
    }, []);

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const partnerListColumns = [
        {
            text: "ID",
 headerClasses:"ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            text: "ID",
 headerClasses:"ID",
            dataField: "partnerID",
            sort: true,
        },
        {
            dataField: "userCreateTime",
            text: "Date",
            sort: true,
        },
        {
            text: "Name",
            dataField: "userName",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, partner) => (
                <>
                    <h5 className="font-size-14 mb-1">
                        <Link to="#" className="text-dark">
                            {partner.userName}
                        </Link>
                    </h5>
                </>
            ),
        },
        {
            dataField: "userEmail",
            text: "Email",
            sort: true,
        },

        {
            dataField: "userStatus",
            text: "Status",
            sort: true,
            formatter: (cellContent, partner) => (
                <>
                    {partner.userStatus &&
                        <span className="active-state">Active</span>
                    }
                    {!partner.userStatus &&
                        <span className="inactive-state">Inactive</span>
                    }
                </>
            ),
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',

            // eslint-disable-next-line react/display-name
            formatter: (cellContent, partner) => (
                <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
                    <Link className="text-success" to="#" onClick={toggleDrawer(true, partner)}>
                        <i
                            className="mdi mdi-pencil font-size-18"
                            id="edittooltip"
                        ></i>
                    </Link>
                </div>
            ),
        },
    ];

    const { SearchBar } = Search;
    const sizePerPage = 10;
    const pageOptions = {
        sizePerPage: 25,
        totalSize: partners.length, // replace later with size(partners),
        custom: true,
    };
    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const archivePartner = async () => {
        dispatch(onArchivePartner({ idList: selectedItem }));
    }

    const statusPartner = (state) => (event) => {
        console.log(state)
        dispatch(onStatusPartner({ idList: selectedItem, userStatus: state }));
    }

    console.log(partners)

    return (
        <React.Fragment>
            <Sidebar />
            <Header />
            <div className="main-content">
                <div className="page-content">
                    <MetaTags>
                        <title>Partner Management | Bidsxchange</title>
                    </MetaTags>
                    <Container fluid={true}>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Home" breadcrumbItem="Partner Management" />
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField={keyField}
                                            columns={partnerListColumns}
                                            data={partners}
                                        >
                                            {({ paginationProps, paginationTableProps }) => {
                                                return (
                                                    <ToolkitProvider
                                                        keyField={keyField}
                                                        data={partners}
                                                        columns={partnerListColumns}
                                                        // bootstrap4
                                                        search
                                                    >
                                                        {toolkitProps => (
                                                            <React.Fragment>
                                                                <Row className="mb-2">
                                                                    <Col sm="4">
                                                                        <div className="search-box ms-2 mb-2 d-inline-block">
                                                                            <div className="position-relative">
                                                                                <SearchBar {...toolkitProps.searchProps} />
                                                                                <i className="bx bx-search-alt search-icon" />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm="8">
                                                                        <div className="text-sm-end">
                                                                            <Button
                                                                                color="primary"
                                                                                className="font-16 btn-block btn btn-primary"
                                                                                onClick={toggleRightCanvas}
                                                                            >
                                                                                <i className="mdi mdi-plus-circle-outline me-1" />
                                                                                New Partner
                                                                            </Button>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xl="12">
                                                                        {selectedRowsStatus && selectedIndex.length > 0 &&
                                                                            <div className="row m-0" style={{ width: '100%', backgroundColor: '#556ee6', color: 'white', padding: '10px' }}>
                                                                                {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                                                                                <div style={{ padding: '0 2%' }}>

                                                                                    {/* <button color="primary" className="btn btn-primary " onClick={statusPartner(true)}>Activate</button> */}
                                                                                    <button color="primary" className="btn btn-primary " onClick={statusPartner(true)}>
                                                                                        Activate
                                                                                    </button>
                                                                                    &nbsp;  &nbsp;
                                                                                    <button color="primary" className="btn btn-primary " onClick={statusPartner(false)}>
                                                                                        DeActivate
                                                                                    </button>
                                                                                    &nbsp;  &nbsp;
                                                                                    <Button color="primary" className="btn btn-primary " onClick={archivePartner} >
                                                                                        Archive
                                                                                    </Button>
                                                                                    <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                                                                        className="mdi mdi-close-thick font-size-18"
                                                                                    ></i></Button>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </Col>
                                                                    <Col xl="12">
                                                                        <div className="table-responsive">
                                                                            <BootstrapTable
                                                                                keyField={keyField}
                                                                                responsive
                                                                                bordered={false}
                                                                                striped={false}
                                                                                defaultSorted={defaultSorted}
                                                                                selectRow={selectRow}
                                                                                classes={
                                                                                    "table align-middle table-nowrap"
                                                                                }
                                                                                headerWrapperClasses={"thead-light"}
                                                                                {...toolkitProps.baseProps}
                                                                                {...paginationTableProps}
                                                                                noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                            // ref={node}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                {/* <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row> */}
                                                                <Row className="align-items-md-center mt-30">
                                                                    <Col className="inner-custom-pagination d-flex">
                                                                        <div className="d-inline">
                                                                            <SizePerPageDropdownStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                        <div className="text-md-right ms-auto">
                                                                            <PaginationListStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                                );
                                            }}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <Offcanvas isOpen={isRight} direction='end'>
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {selectedData ? 'Edit Partner' : 'New Partner'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <Create_PM closeCanvas={toggleDrawer(false, '')} selectedPartner={selectedData} />
                </OffcanvasBody>
            </Offcanvas>

            {/* <Offcanvas isOpen={isRight} scrollable toggle={toggleRightCanvas}>
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {selectedData ? 'Edit Partner' : 'New Partner'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <Create_Partner closeCanvas={toggleDrawer(false, '')} selectedPartner={selectedData} />
                </OffcanvasBody>
            </Offcanvas> */}

        </React.Fragment>
    )
}

export default Partner_Management
