/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback, Spinner, Collapse } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import classnames from "classnames";
import {
  updatePartner as onUpdatePartner,
  addPartnerSuccess,
  addPartnerFail,
  updatePartnerSuccess,
  updatePartnerFail,
} from "store/PartnerManagement/actions";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import Select, { StylesConfig } from 'react-select';

let defaultRolePrivileges = {
  "Dashboard": {
    isRevenueAccess: true,
    isAnalyticsAccess: false,
    isAgencyAccess: false
  },
  "Demand": {
    "TC": {
      "read": true,
      "create": false,
      "status": false,
      "update": true,
      "archive": false
    },
    "IOs": {
      "read": true,
      "create": false,
      "status": false,
      "update": true,
      "archive": false
    },
    "IOGroups": {
      "read": true,
      "create": false,
      "status": false,
      "update": true,
      "archive": false
    },
    "Creatives": {
      "read": true,
      "create": false,
      "status": false,
      "update": true,
      "archive": false
    },
    "HeaderBidding": {
      "read": true,
      "create": false,
      "status": false,
      "update": true,
      "archive": false
    },
    "LineItems": {
      "read": true,
      "create": false,
      "status": false,
      "update": true,
      "archive": false
    },
    "Advertisers": {
      "read": true,
      "create": false,
      "status": false,
      "update": true,
      "archive": false
    },
    isAdveriserAccess: false,
    isIOGroupAccess: false,
    isIOsAccess: false,
    isLineItemAccess: false,
    isTCAccess: false,
    isCreativeAccess: false,
    isHeaderBiddingAccess: false,
  },

  "Supply": {
    "AdUnits": {
      "read": true,
      "create": false,
      "status": false,
      "update": true,
      "archive": false
    },
    "Publishers": {
      "read": true,
      "create": false,
      "status": false,
      "update": true,
      "archive": false
    },
    "AdUnitGroups": {
      "read": true,
      "create": false,
      "status": false,
      "update": true,
      "archive": false
    },
    "ChildAdUnits": {
      "read": true,
      "create": false,
      "status": false,
      "update": true,
      "archive": false
    },
    "HighImpactSettings": {
      "read": true,
      "create": false,
      "status": false,
      "update": true,
      "archive": false
    },
    "STDBSettings": {
      "read": true,
      "create": false,
      "status": false,
      "update": true,
      "archive": false
    },
    "PlayerSettings": {
      "read": true,
      "create": false,
      "status": false,
      "update": true,
      "archive": false
    },
    isPublisherAccess: true,
    isAdUnitGroupAccess: true,
    isAdUnitAccess: true,
    isChildAdUnitAccess: false,
    isHighImpactSettingAccess: false,
    isSTDBSettingAccess: false,
    isPlayerSettingAccess: false,
    isTemplatesCustomAccess: false,
    templatesSelected: [],
    isSTDBTemplatesCustomAccess: false,
    stdbTemplatesSelected: []
  },
  "HouseAds": {
    "NativeAds": {
      "read": true,
      "create": false,
      "status": false,
      "update": false,
      "archive": false
    },
    "HouseAdsAdUnits": {
      "read": true,
      "create": false,
      "status": false,
      "update": false,
      "archive": false
    },
    "HouseAdsCreatives": {
      "read": true,
      "create": false,
      "status": false,
      "update": false,
      "archive": false
    },
    "HouseAdsCategories": {
      "read": true,
      "create": false,
      "status": false,
      "update": false,
      "archive": false
    },
    "HouseAdsPublishers": {
      "read": true,
      "create": false,
      "status": false,
      "update": false,
      "archive": false
    },
    isHouseAdsPublisherAccess: false,
    isHouseAdsAdUnitAccess: false,
    isHouseAdCreativeAccess: false,
    isNativeAdAccess: false,
    isHouseAdsCategoryAccess: false,
    isFsrvAccess: false,
  },
  "ProxyAds": {
    "ProxyAdUnits": {
      "read": true,
      "create": false,
      "status": false,
      "update": true,
      "archive": false
    },
    isProxyAdUnitAccess: false,
    isSsrvAccess: false,
  },
  "Report": {
    "Query": {
      "Pageurl": false,
      "Revenue": true,
      "Analytic": false
    },
    isQueryAccess: true,
    isPageUrlReportAccess: false,
    isRevenueReportAccess: true,
    isAnalyticReportAccess: false,
    isYMAnalyticReportAccess: false,
    isASAnalyticReportAccess: false,
    isMISAnalyticReportAccess: false,
    isTeamPageUrlReportAccess: false,
    isAgencyReportAccess: false,
  },
  "Billing": {
    "Invoices": {
      "History": true,
      "Payment": true
    },
    isInvoiceHistoryAccess: true,
    isInvoicePaymentAccess: true
  },

  ContextualSettings: {
    isBrandAffinityAccess: false,
    isInterestAccess: false,
    isKeywordAccess: false,
    isPageUrlAccess: false,
    isWebCategorizationAccess: false
  },
  Tools: {
    isVideoContentLibrarayAccess: false,
    isScreenShotToolAccess: false,
    isAdTagTesterAccess: false,
    isVastTagTesterAccess: false,
    isAdsTxtValidatorAccess: false
  },
  VCs: {
    isVideoContentLibrarayAccess: true,
    isVideoPlayListAccess: true,
    isVideoImportAccess: true,
    isContentMarketplaceAccess: true,
  },
  settings: {
    isAdsTxtManagementAccess: true,
    isBrandSafetyAccess: true,
    isCMSConfigurationAccess: true,
    isReportingAPIAccess: true
  },
  isDashboardAccess: true,
  isDemandAccess: false,
  isSupplyAccess: true,
  isHouseAdAccess: false,
  isProxyAdAccess: false,
  isReportAccess: true,
  isBillingAccess: true,
  isPartnerManagementAccess: false,
  isSwitchManagementAccess: false,
  isContextualSettingsAccess: false,
  isHBSettingAccess: false,
  isNetworkBlockingAccess: false,
  isNetworkSettingAccess: false,
  isToolsAccess: false,
  isVCsAccess: true,
  isRevenueOpportunitiesAccess: true,
  isPublisherSettingsAccess: true,
  isSupportTicketAccess: true,
}

const Supply_Publisher_Role_Access = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [templateSelect, setTemplateSelect] = useState([]);
  const [templatesData, setTemplatesData] = useState([]);
  const [stdbTemplateSelect, setSTDBTemplateSelect] = useState([]);
  const [stdbTemplatesData, setSTDBTemplatesData] = useState([]);
  console.log(props)
  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const body = {
    "url": '',
    "requestBody": {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      name: userInfo.name,
    }
  }

  const [finalData, setFinalData] = useState(
    {
      "rolePrivileges": {
        "Dashboard": {
          isRevenueAccess: true,
          isAnalyticsAccess: false,
          isAgencyAccess: false
        },
        "Demand": {
          "TC": {
            "read": true,
            "create": false,
            "status": false,
            "update": true,
            "archive": false
          },
          "IOs": {
            "read": true,
            "create": false,
            "status": false,
            "update": true,
            "archive": false
          },
          "IOGroups": {
            "read": true,
            "create": false,
            "status": false,
            "update": true,
            "archive": false
          },
          "Creatives": {
            "read": true,
            "create": false,
            "status": false,
            "update": true,
            "archive": false
          },
          "HeaderBidding": {
            "read": true,
            "create": false,
            "status": false,
            "update": true,
            "archive": false
          },
          "LineItems": {
            "read": true,
            "create": false,
            "status": false,
            "update": true,
            "archive": false
          },
          "Advertisers": {
            "read": true,
            "create": false,
            "status": false,
            "update": true,
            "archive": false
          },
          isAdveriserAccess: false,
          isIOGroupAccess: false,
          isIOsAccess: false,
          isLineItemAccess: false,
          isTCAccess: false,
          isCreativeAccess: false,
          isHeaderBiddingAccess: false,
        },

        "Supply": {
          "AdUnits": {
            "read": true,
            "create": false,
            "status": false,
            "update": true,
            "archive": false
          },
          "Publishers": {
            "read": true,
            "create": false,
            "status": false,
            "update": true,
            "archive": false
          },
          "AdUnitGroups": {
            "read": true,
            "create": false,
            "status": false,
            "update": true,
            "archive": false
          },
          "ChildAdUnits": {
            "read": true,
            "create": false,
            "status": false,
            "update": true,
            "archive": false
          },
          "HighImpactSettings": {
            "read": true,
            "create": false,
            "status": false,
            "update": true,
            "archive": false
          },
          "STDBSettings": {
            "read": true,
            "create": false,
            "status": false,
            "update": true,
            "archive": false
          },
          "PlayerSettings": {
            "read": true,
            "create": false,
            "status": false,
            "update": true,
            "archive": false
          },
          isPublisherAccess: true,
          isAdUnitGroupAccess: true,
          isAdUnitAccess: true,
          isChildAdUnitAccess: false,
          isHighImpactSettingAccess: false,
          isSTDBSettingAccess: false,
          isPlayerSettingAccess: false
        },
        "HouseAds": {
          "NativeAds": {
            "read": true,
            "create": false,
            "status": false,
            "update": true,
            "archive": false
          },
          "HouseAdsAdUnits": {
            "read": true,
            "create": false,
            "status": false,
            "update": true,
            "archive": false
          },
          "HouseAdsCreatives": {
            "read": true,
            "create": false,
            "status": false,
            "update": true,
            "archive": false
          },
          "HouseAdsCategories": {
            "read": true,
            "create": false,
            "status": false,
            "update": true,
            "archive": false
          },
          "HouseAdsPublishers": {
            "read": true,
            "create": false,
            "status": false,
            "update": true,
            "archive": false
          },
          isHouseAdsPublisherAccess: false,
          isHouseAdsAdUnitAccess: false,
          isHouseAdCreativeAccess: false,
          isNativeAdAccess: false,
          isHouseAdsCategoryAccess: false,
          isFsrvAccess: false,
        },
        "ProxyAds": {
          "ProxyAdUnits": {
            "read": true,
            "create": false,
            "status": false,
            "update": true,
            "archive": false
          },
          isProxyAdUnitAccess: true,
          isSsrvAccess: true,
        },
        "Report": {
          "Query": {
            "Pageurl": false,
            "Revenue": true,
            "Analytic": false
          },
          isQueryAccess: true,
          isPageUrlReportAccess: false,
          isRevenueReportAccess: true,
          isAnalyticReportAccess: false,
          isYMAnalyticReportAccess: false,
          isASAnalyticReportAccess: false,
          isMISAnalyticReportAccess: false,
          isTeamPageUrlReportAccess: false,
          isAgencyReportAccess: false,
        },
        "Billing": {
          "Invoices": {
            "History": true,
            "Payment": true
          },
          isInvoiceHistoryAccess: true,
          isInvoicePaymentAccess: true
        },

        ContextualSettings: {
          isBrandAffinityAccess: false,
          isInterestAccess: false,
          isKeywordAccess: false,
          isPageUrlAccess: false,
          isWebCategorizationAccess: false
        },
        Tools: {
          isVideoContentLibrarayAccess: false,
          isScreenShotToolAccess: false,
          isAdTagTesterAccess: false,
          isVastTagTesterAccess: false,
          isAdsTxtValidatorAccess: false
        },
        VCs: {
          isVideoContentLibrarayAccess: true,
          isVideoPlayListAccess: true,
          isVideoImportAccess: true,
          isContentMarketplaceAccess: true,
        },
        settings: {
          isAdsTxtManagementAccess: true,
          isBrandSafetyAccess: true,
          isCMSConfigurationAccess: true,
          isReportingAPIAccess: true
        },
        isDashboardAccess: true,
        isDemandAccess: false,
        isSupplyAccess: true,
        isHouseAdAccess: false,
        isProxyAdAccess: false,
        isReportAccess: true,
        isBillingAccess: true,
        isPartnerManagementAccess: false,
        isSwitchManagementAccess: false,
        isContextualSettingsAccess: false,
        isHBSettingAccess: false,
        isNetworkBlockingAccess: false,
        isNetworkSettingAccess: false,
        isToolsAccess: false,
        isVCsAccess: true,
        isRevenueOpportunitiesAccess: true,
        isPublisherSettingsAccess: true,
        isSupportTicketAccess: true,
      },
      privilegesType: false,
    }
  )

  const selectDashboard = (event) => {
    setFinalData(finalData => ({
      ...finalData, rolePrivileges: {
        ...finalData.rolePrivileges, isDashboardAccess: event.target.checked
      }
    }))
    event.target.checked ? '' :
    setFinalData(finalData => ({
      ...finalData, rolePrivileges: {
        ...finalData.rolePrivileges, Dashboard: {
          ...finalData.rolePrivileges.Dashboard,
          "Revenue": false,
          "Analytics": false,
          isRevenueAccess: false,
          isAnalyticsAccess: false,
          isAgencyAccess: false
        }
      }
    }))
  }

  const selectDemand = (event) => {
    setFinalData(finalData => ({
      ...finalData, rolePrivileges: {
        ...finalData.rolePrivileges, isDemandAccess: event.target.checked
      }
    }))
    event.target.checked ? '' :
      setFinalData(finalData => ({
        ...finalData, rolePrivileges: {
          ...finalData.rolePrivileges, Demand: {
            ...finalData.rolePrivileges.Demand,
            "TC": {
              "read": true,
              "create": false,
              "status": false,
              "update": true,
              "archive": false
            },
            "IOs": {
              "read": true,
              "create": false,
              "status": false,
              "update": true,
              "archive": false
            },
            "IOGroups": {
              "read": true,
              "create": false,
              "status": false,
              "update": true,
              "archive": false
            },
            "Creatives": {
              "read": true,
              "create": false,
              "status": false,
              "update": true,
              "archive": false
            },
            "HeaderBidding": {
              "read": true,
              "create": false,
              "status": false,
              "update": true,
              "archive": false
            },
            "LineItems": {
              "read": true,
              "create": false,
              "status": false,
              "update": true,
              "archive": false
            },
            "Advertisers": {
              "read": true,
              "create": false,
              "status": false,
              "update": true,
              "archive": false
            },
            isAdveriserAccess: event.target.checked,
            isIOGroupAccess: event.target.checked,
            isIOsAccess: event.target.checked,
            isLineItemAccess: event.target.checked,
            isTCAccess: event.target.checked,
            isCreativeAccess: event.target.checked,
            isHeaderBiddingAccess: event.target.checked,
          }
        }
      }))
  }

  const selectSupply = (e) => {
    setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isSupplyAccess: e.target.checked } }))
    if (e.target.checked === false) {
      setFinalData(finalData => ({
        ...finalData, rolePrivileges: {
          ...finalData.rolePrivileges, Supply: {
            ...finalData.rolePrivileges.Supply,
            "AdUnits": {
              "read": true,
              "create": false,
              "status": false,
              "update": true,
              "archive": false
            },
            "Publishers": {
              "read": true,
              "create": false,
              "status": false,
              "update": true,
              "archive": false
            },
            "AdUnitGroups": {
              "read": true,
              "create": false,
              "status": false,
              "update": true,
              "archive": false
            },
            "ChildAdUnits": {
              "read": true,
              "create": false,
              "status": false,
              "update": true,
              "archive": false
            },
            "HighImpactSettings": {
              "read": true,
              "create": false,
              "status": false,
              "update": true,
              "archive": false
            },
            "STDBSettings": {
              "read": true,
              "create": false,
              "status": false,
              "update": true,
              "archive": false
            },
            "PlayerSettings": {
              "read": true,
              "create": false,
              "status": false,
              "update": true,
              "archive": false
            },
            isPartnerAccess: false,
            isPublisherAccess: false,
            isAdUnitGroupAccess: false,
            isAdUnitAccess: false,
            isChildAdUnitAccess: false,
            isHighImpactSettingAccess: false,
            isSTDBSettingAccess: false,
            isPlayerSettingAccess: false,
            isTemplatesCustomAccess: false,
            templatesSelected: [],
            isSTDBTemplatesCustomAccess: false,
            stdbTemplatesSelected: []
          }
        }
      }))
      setTemplateSelect([])
      setSTDBTemplateSelect([]);
    }

  }

  const selectReport = (event) => {
    setFinalData(finalData => ({
      ...finalData, rolePrivileges: {
        ...finalData.rolePrivileges, isReportAccess: event.target.checked
      }
    }))
    event.target.checked ? '' :
    setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report,isRevenueReportAccess:false, isAnalyticReportAccess: false,isPageUrlReportAccess:false },"Query": {
      "Pageurl": false,
      "Revenue": false,
      "Analytic": false
    }, } }));
  }

  const selectBilling = (event) => {
    setFinalData(finalData => ({
      ...finalData, rolePrivileges: {
        ...finalData.rolePrivileges, isBillingAccess: event.target.checked
      }
    }))
    event.target.checked ? '' :
    setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Billing: { ...finalData.rolePrivileges.Billing, isInvoiceHistoryAccess: false,isInvoicePaymentAccess:false,"Invoices": {
      "History": false,
      "Payment": false
    }, } } }))
  }

  const selectVCS = (event) => {
    setFinalData(finalData => ({
      ...finalData, rolePrivileges: {
        ...finalData.rolePrivileges, isVCsAccess: event.target.checked
      }
    }))
    event.target.checked ? '' :
    setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, VCs: { ...finalData.rolePrivileges.VCs, isVideoContentLibrarayAccess: false,isVideoPlayListAccess:false,isVideoImportAccess:false,isContentMarketplaceAccess:false } } }))
  }

  const selectSettings = (event) => {
    setFinalData(finalData => ({
      ...finalData, rolePrivileges: {
        ...finalData.rolePrivileges, isPublisherSettingsAccess: event.target.checked
      }
    }))
    event.target.checked ? '' :
    setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, settings: { ...finalData.rolePrivileges.settings, isAdsTxtManagementAccess: false,isBrandSafetyAccess:false,isCMSConfigurationAccess:false,isReportingAPIAccess:false } } }))
  }

  const changeData = (parent, child, action) => (e) => {
    const { name, value } = e.target;
    console.log(e.target.checked)
    setFinalData(finalData => ({
      ...finalData, rolePrivileges: {
        ...finalData.rolePrivileges,
        [parent]: {
          ...finalData.rolePrivileges[parent],
          [child]: { ...finalData.rolePrivileges[parent][child], [name]: e.target.checked }
        }
      }
    }));
  }

  const changeChildAccess = (parent, child, action) => (e) => {
    const { name, value } = e.target;
    console.log(e.target.checked)
    setFinalData(finalData => ({
      ...finalData, rolePrivileges: {
        ...finalData.rolePrivileges,
        [parent]: {
          ...finalData.rolePrivileges[parent],
          [child]: { ...finalData.rolePrivileges[parent][child], create: action, status: action, update: action, archive: action, }
        }
      }
    }));
  }

  const createUI = (parent, child) => {
    console.log(parent, child, finalData.rolePrivileges[`${parent}`][`${child}`])
    return (
      <div className="d-flex" style={{justifyContent:'space-between'}}>
        < div  className="mt-2 mb-2">
          Select :  &nbsp; <Link to="#" className="text-blue link" onClick={changeChildAccess(`${parent}`, `${child}`, true)} style={{ cursor: 'pointer' }}>All</Link> &nbsp;&nbsp;
          <Link to="#" className="text-blue link" onClick={changeChildAccess(`${parent}`, `${child}`, false)} style={{ cursor: 'pointer' }}>None</Link>
        </div>
        <div md={2}>
          <div className="form-check mt-2 mb-2">
            <input
              className="form-check-input"
              type="checkbox"
              name="read"
              checked={finalData.rolePrivileges[`${parent}`][`${child}`]['read']}
              onChange={changeData(`${parent}`, `${child}`, `read`)}
              id={`${parent}${child}Read`}
              disabled
            />
            <label
              className="form-check-label"
              htmlFor={`${parent}${child}Read`}
            >
              View
            </label>
          </div>
          </div>
          <div md={3}>
          <div className="form-check mt-2 mb-2">
            <input
              className="form-check-input"
              type="checkbox"
              name="create"
              checked={finalData.rolePrivileges[`${parent}`][`${child}`]['create']}
              onChange={changeData(`${parent}`, `${child}`, `create`)}
              id={`${parent}${child}Create`}
            />
            <label
              className="form-check-label"
              htmlFor={`${parent}${child}Create`}
            >
              Create / Update
            </label>
          </div>
          </div>
          <div md={3}>
          <div className="form-check mt-2 mb-2">
            <input
              className="form-check-input"
              type="checkbox"
              name="status"
              checked={finalData.rolePrivileges[`${parent}`][`${child}`]['status']}
              onChange={changeData(`${parent}`, `${child}`, `status`)}
              id={`${parent}${child}ActiveDeactivate`}
            />
            <label
              className="form-check-label"
              htmlFor={`${parent}${child}ActiveDeactivate`}
            >
              Active / DeActivate
            </label>
          </div>
          </div>
          <div md={2}>
          <div className="form-check mt-2 mb-2">
            <input
              className="form-check-input"
              type="checkbox"
              name="archive"
              checked={finalData.rolePrivileges[`${parent}`][`${child}`]['archive']}
              onChange={changeData(`${parent}`, `${child}`, `archive`)}
              id={`${parent}${child}Archive`}
            />
            <label
              className="form-check-label"
              htmlFor={`${parent}${child}Archive`}
            >
              Archive
            </label>
          </div>
        </div>
      </div>
    )

  }

  const createUIDisabled = (parent, child) => {
    return (
      <div className="row">
        <Col md={2}>
          <div className="form-check mt-2 mb-2">
            <input
              className="form-check-input"
              type="checkbox"
              name="read"
              checked={finalData.rolePrivileges[`${parent}`][`${child}`]['read']}
              onChange={changeData(`${parent}`, `${child}`, `read`)}
              id={`${parent}${child}Read`}
              disabled
            />
            <label
              className="form-check-label"
              htmlFor={`${parent}${child}Read`}
              style={{ opacity: 1 }}
            >
              Read
            </label>
          </div>
        </Col>
      </div>
    )

  }


  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.partners.errorMsg,
    successMsg: state.partners.successMsg,
  }));

  console.log(errorMsg, successMsg)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.success) {
        dispatch(addPartnerSuccess(""));
        dispatch(addPartnerFail(null));
        dispatch(updatePartnerSuccess(""));
        dispatch(updatePartnerFail(null));
        setLoader(false)
        if (props.selectedPartner !== '') {
          props.closeCanvas('Update')
        } else {
          props.closeCanvas('Create')
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    console.log(errorMsg)
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
    }
  }, [errorMsg]);

  useEffect(() => {
    if (props.selectedPartner !== '') {
      setIsEdit(true)
      console.log(finalData, props.selectedPartner)
      let roleData = { ...finalData.rolePrivileges, ...props.selectedPartner.partnerRolePrivilages }
      console.log(roleData)
      setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...roleData }, privilegesType: props.selectedPartner.partnerRolePrivilagesType === 'custom' ? true : false }));
      if (props.selectedPartner.partnerRolePrivilages.Supply && props.selectedPartner.partnerRolePrivilages.Supply.templatesSelected && props.selectedPartner.partnerRolePrivilages.Supply.templatesSelected.length > 0) {
        setTemplateSelect(objectModalsTemplate(props.selectedPartner.partnerRolePrivilages.Supply.templatesSelected));
      }
      if (props.selectedPartner.partnerRolePrivilages.Supply && props.selectedPartner.partnerRolePrivilages.Supply.stdbTemplatesSelected && props.selectedPartner.partnerRolePrivilages.Supply.stdbTemplatesSelected.length > 0) {
        setSTDBTemplateSelect(objectModalsTemplate(props.selectedPartner.partnerRolePrivilages.Supply.stdbTemplatesSelected));
      }
    }
  }, []);

  const objectModalsTemplate = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    setLoader(true)
    if (props.selectedPartner !== '') {
      dispatch(onUpdatePartner({
        ...props.selectedPartner, isCDNEnabled: false,
        domainType: "",
        bucketType: "",
        bucketName: "",
        cDNBucketCname: "",
        IsTCDisabled: false,
        userlog: {
          userID: userInfo.userID,
          userRole: userInfo.userRole,
          companyID: userInfo.companyID,
          name: userInfo.name
        },
        password: '', generatePasswordEmail: false, partnerRolePrivilages: { ...finalData.rolePrivileges }, partnerRolePrivilagesType: finalData.privilegesType ? 'custom' : 'default'
      }));
    }
  }

  const privilegesTypeChange = e => {
    setFinalData(finalData => ({ ...finalData, privilegesType: e.target.checked, rolePrivileges: { ...finalData.rolePrivileges, ...defaultRolePrivileges } }));
  }

  useEffect(() => {
    body.url = (userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency') ? "http://127.0.0.1:3006/v1.0/CreativeTemplates/list" : "http://127.0.0.1:2002/v1.0/CreativeTemplates/list"
    body.requestBody = {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      name: userInfo.name,

      CreativeTemplateType: "highImpact",
      CreativeTemplateFor: "HighImapct"
    }
    if (userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency') {
      body.requestBody = { ...body.requestBody, partnerName: userInfo.name, partnerID: userInfo.userID }
    }
    axios.post(userInfo.userRole === 'Partner' ? "/v1.0/connectPartner" : userInfo.userRole === 'Agency' ? "/v1.0/connectPartner" : "/v1.0/connectReportAPI", body,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is ==>", res)
        if (res.data) {
          console.log(res.data.response)
          setTemplatesData(objectModals(res.data.response))
        }
      })
      .catch((err) => {
        console.log("e", err)
      });


    body.url = (userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency') ? "http://127.0.0.1:3006/v1.0/CreativeTemplates/list" : "http://127.0.0.1:2002/v1.0/CreativeTemplates/list"
    body.requestBody = {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      name: userInfo.name,

      CreativeTemplateType: "stdbST",
      CreativeTemplateFor: "STDB"
    }
    if (userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency') {
      body.requestBody = { ...body.requestBody, partnerName: userInfo.name, partnerID: userInfo.userID }
    }
    axios.post(userInfo.userRole === 'Partner' ? "/v1.0/connectPartner" : userInfo.userRole === 'Agency' ? "/v1.0/connectPartner" : "/v1.0/connectReportAPI", body,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is ==>", res)
        if (res.data) {
          console.log(res.data.response)
          setSTDBTemplatesData(objectModals(res.data.response))
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }, [])

  const objectModals = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.creativeTemplateName
      obj.label = item.creativeTemplateName
      dataList.push(obj)
    })
    return dataList
  }

  const templateChange = templates => {
    setTemplateSelect(templates);
    let templatesList = []
    templates.map((item, index) => {
      templatesList.push(item.value)
    })
    setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Supply: { ...finalData.rolePrivileges.Supply, templatesSelected: templatesList } } }))
  }

  const stdbTemplateChange = templates => {
    setSTDBTemplateSelect(templates);
    let templatesList = []
    templates.map((item, index) => {
      templatesList.push(item.value)
    })
    setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Supply: { ...finalData.rolePrivileges.Supply, stdbTemplatesSelected: templatesList } } }))
  }

  const customStyles = {
    // For the select it self, not the options of the select
    control: (styles, { isDisabled }) => {
      return {
        ...styles,
        cursor: isDisabled ? 'not-allowed' : 'default',
        // This is an example: backgroundColor: isDisabled ? 'rgba(206, 217, 224, 0.5)' : 'white'
        color: isDisabled ? '#aaa' : 'white'
      }
    },
    // For the options
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        color: isDisabled ? '#000' : '#000',
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
  };

  return (
    <React.Fragment>
      <div className="col-12">
        {loader &&
          <Spinner className="ms-2 loader" color="primary" />
        }
        <Form onSubmit={handleSubmit}>
          <Row className="formBodyScroll" style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
            <Col xs={12}>
              <div className=" d-sm-flex  justify-content-between">
                <Label className="form-label"><b>Partner Name :</b> &nbsp;{props.selectedPartner.userName}</Label>
                <Label className="form-label"><b>Inventory Type :</b> &nbsp;{props.selectedPartner.partnerInvertoryType}</Label>
                <div className="text-sm-end">
                  <div className="form-check form-switch form-check-success form-switch-md" >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitchsizemd1"
                      checked={finalData.privilegesType}
                      onChange={privilegesTypeChange}
                    />
                    <label className="form-check-label" htmlFor="customSwitchsizemd1">
                      {finalData.privilegesType ? <span color='text-success' style={{ color: '#34c38f' }}>Custom Access </span> : <span style={{ color: '#a3a3a3' }}>Default Access</span>}
                    </label>
                  </div>
                </div>
              </div>
              <hr />
              {finalData.privilegesType &&
                <div>
                  <Row className="m-4">
                    <div className="accordion-item p-0 ml-0 mr-0 mb-2">

                      <h2 className="accordion-header m-0" id="headingOne">
                        <label className=" d-sm-flex  justify-content-between form-check-label settingsButton" htmlFor="isDashboardAccess">
                          <h6 className="heading6 m-3">Dashboards</h6>
                          <div className="text-sm-end">
                            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={finalData.rolePrivileges.isDashboardAccess}
                                onChange={selectDashboard}
                                id="isDashboardAccess"
                              />
                            </div>
                          </div>
                        </label>
                      </h2>
                      <Collapse isOpen={finalData.rolePrivileges.isDashboardAccess} className="accordion-collapse">
                        <div className="accordion-body">
                          <div className="row">
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.Dashboard.isRevenueAccess}
                                  onChange={(e) => setFinalData(finalData => ({
                                    ...finalData, rolePrivileges: {
                                      ...finalData.rolePrivileges, Dashboard: {
                                        ...finalData.rolePrivileges.Dashboard,
                                        isRevenueAccess: e.target.checked
                                      }
                                    }
                                  }))}
                                  id="marketplaceDashboard"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="marketplaceDashboard"
                                >
                                  Marketplace Dashboard
                                </label>
                              </div>
                            </Col>
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.Dashboard.isAnalyticsAccess}
                                  onChange={(e) => setFinalData(finalData => ({
                                    ...finalData, rolePrivileges: {
                                      ...finalData.rolePrivileges, Dashboard: {
                                        ...finalData.rolePrivileges.Dashboard,
                                        isAnalyticsAccess: e.target.checked
                                      }
                                    }
                                  }))}
                                  id="analytics"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="analytics"
                                >
                                  Server Dashboard
                                </label>
                              </div>
                            </Col>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </Row>

                  <Row className="m-4">
                    <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                      <h2 className="accordion-header m-0" id="headingOne">
                        <label className=" d-sm-flex  justify-content-between form-check-label settingsButton" htmlFor="isDemandAccess">
                          <h6 className="heading6 m-3">Demand</h6>
                          <div className="text-sm-end">
                            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={finalData.rolePrivileges.isDemandAccess}
                                onChange={selectDemand}
                                id="isDemandAccess"
                              />
                            </div>
                          </div>
                        </label>
                      </h2>
                      <Collapse isOpen={finalData.rolePrivileges.isDemandAccess} className="accordion-collapse">
                        <div className="accordion-body">
                          <div>
                            <Col md={12} className="m-1">
                              <Row>
                                <Col md={2}>
                                  <div className="form-check mt-2 mb-2">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={finalData.rolePrivileges.Demand.isAdveriserAccess}
                                      onChange={(e) => setFinalData(finalData => ({
                                        ...finalData, rolePrivileges: {
                                          ...finalData.rolePrivileges, Demand: {
                                            ...finalData.rolePrivileges.Demand,
                                            isAdveriserAccess: e.target.checked
                                          }
                                        }
                                      }))}
                                      id="advertiser"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="advertiser"
                                    >
                                      Advertiser
                                    </label>
                                  </div>
                                </Col>
                                {finalData.rolePrivileges.Demand.isAdveriserAccess &&
                                  <Col md={10} >
                                    {createUI('Demand', 'Advertisers')}
                                  </Col>
                                }
                              </Row>
                            </Col>
                            <Col md={12} className="m-1">
                              <Row>
                                <Col md={2}>
                                  <div className="form-check mt-2 mb-2">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={finalData.rolePrivileges.Demand.isIOGroupAccess}
                                      onChange={(e) => setFinalData(finalData => ({
                                        ...finalData, rolePrivileges: {
                                          ...finalData.rolePrivileges, Demand: {
                                            ...finalData.rolePrivileges.Demand,
                                            isIOGroupAccess: e.target.checked
                                          }
                                        }
                                      }))}
                                      id="IoGroup"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="IoGroup"
                                    >
                                      IO Group
                                    </label>
                                  </div>
                                </Col>
                                {finalData.rolePrivileges.Demand.isIOGroupAccess &&
                                  <Col md={10} >
                                    {createUI('Demand', 'IOGroups')}
                                  </Col>
                                }
                              </Row>
                            </Col>
                            <Col md={12} className="m-1">
                              <Row>
                                <Col md={2}>
                                  <div className="form-check mt-2 mb-2">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={finalData.rolePrivileges.Demand.isIOsAccess}
                                      onChange={(e) => setFinalData(finalData => ({
                                        ...finalData, rolePrivileges: {
                                          ...finalData.rolePrivileges, Demand: {
                                            ...finalData.rolePrivileges.Demand,
                                            isIOsAccess: e.target.checked
                                          }
                                        }
                                      }))}
                                      id="insertionOrder"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="insertionOrder"
                                    >
                                      Insertion Order
                                    </label>
                                  </div>
                                </Col>
                                {finalData.rolePrivileges.Demand.isIOsAccess &&
                                  <Col md={10} >
                                    {createUI('Demand', 'IOs')}
                                  </Col>
                                }
                              </Row>
                            </Col>
                            <Col md={12} className="m-1">
                              <Row>
                                <Col md={2}>
                                  <div className="form-check mt-2 mb-2">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={finalData.rolePrivileges.Demand.isLineItemAccess}
                                      onChange={(e) => setFinalData(finalData => ({
                                        ...finalData, rolePrivileges: {
                                          ...finalData.rolePrivileges, Demand: {
                                            ...finalData.rolePrivileges.Demand,
                                            isLineItemAccess: e.target.checked
                                          }
                                        }
                                      }))}
                                      id="lineItem"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="lineItem"
                                    >
                                      Line Item
                                    </label>
                                  </div>
                                </Col>
                                {finalData.rolePrivileges.Demand.isLineItemAccess &&
                                  <Col md={10} >
                                    {createUI('Demand', 'LineItems')}
                                  </Col>
                                }
                              </Row>
                            </Col>
                            {/* <Col md={12} className="m-1">
                      <Row>
                        <Col md={2}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.Demand.isTCAccess}
                              onChange={(e) => setFinalData(finalData => ({
                                ...finalData, rolePrivileges: {
                                  ...finalData.rolePrivileges, Demand: {
                                    ...finalData.rolePrivileges.Demand,
                                    isTCAccess: e.target.checked
                                  }
                                }
                              }))}
                              id="tagController"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="tagController"
                            >
                              Tag Controller
                            </label>
                          </div>
                        </Col>
                        {finalData.rolePrivileges.Demand.isTCAccess &&
                          <Col md={10} >
                            {createUI('Demand', 'TC')}
                          </Col>
                        }
                      </Row>
                    </Col> */}
                            <Col md={12} className="m-1">
                              <Row>
                                <Col md={2}>
                                  <div className="form-check mt-2 mb-2">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={finalData.rolePrivileges.Demand.isCreativeAccess}
                                      onChange={(e) => setFinalData(finalData => ({
                                        ...finalData, rolePrivileges: {
                                          ...finalData.rolePrivileges, Demand: {
                                            ...finalData.rolePrivileges.Demand,
                                            isCreativeAccess: e.target.checked
                                          }
                                        }
                                      }))}
                                      id="creative"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="creative"
                                    >
                                      Creative
                                    </label>
                                  </div>
                                </Col>
                                {finalData.rolePrivileges.Demand.isCreativeAccess &&
                                  <Col md={10} >
                                    {createUI('Demand', 'Creatives')}
                                  </Col>
                                }
                              </Row>
                            </Col>

                            <Col md={12} className="m-1">
                              <Row>
                                <Col md={2}>
                                  <div className="form-check mt-2 mb-2">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={finalData.rolePrivileges.Demand.isHeaderBiddingAccess}
                                      onChange={(e) => setFinalData(finalData => ({
                                        ...finalData, rolePrivileges: {
                                          ...finalData.rolePrivileges, Demand: {
                                            ...finalData.rolePrivileges.Demand,
                                            isHeaderBiddingAccess: e.target.checked
                                          }
                                        }
                                      }))}
                                      id="HeaderBidding"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="HeaderBidding"
                                    >
                                      Header Bidding
                                    </label>
                                  </div>
                                </Col>
                                {finalData.rolePrivileges.Demand.isHeaderBiddingAccess &&
                                  <Col md={10} >
                                    {createUI('Demand', 'HeaderBidding')}
                                  </Col>
                                }
                              </Row>
                            </Col>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </Row>

                  <Row className="m-4">
                    <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                      <h2 className="accordion-header m-0" id="headingOne">
                        <label className=" d-sm-flex  justify-content-between form-check-label settingsButton" htmlFor="isSupplyAccess">
                          <h6 className="heading6 m-3">Supply</h6>
                          <div className="text-sm-end">
                            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={finalData.rolePrivileges.isSupplyAccess}
                                onChange={selectSupply}
                                id="isSupplyAccess"
                              />
                            </div>
                          </div>
                        </label>
                      </h2>
                      <Collapse isOpen={finalData.rolePrivileges.isSupplyAccess} className="accordion-collapse">
                        <div className="accordion-body">
                          <div>
                            <Col md={12} className="m-1">
                              <Row>
                                <Col md={2}>
                                  <div className="form-check mt-2 mb-2">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={finalData.rolePrivileges.Supply.isPublisherAccess}
                                      onChange={(e) => setFinalData(finalData => ({
                                        ...finalData, rolePrivileges: {
                                          ...finalData.rolePrivileges, Supply: {
                                            ...finalData.rolePrivileges.Supply,
                                            isPublisherAccess: e.target.checked
                                          }
                                        }
                                      }))}
                                      id="supplyPublisher"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="supplyPublisher"
                                    >
                                      Publisher
                                    </label>
                                  </div>
                                </Col>
                                {finalData.rolePrivileges.Supply.isPublisherAccess &&
                                  <Col md={10} >
                                    {createUI('Supply', 'Publishers')}
                                  </Col>
                                }
                              </Row>
                            </Col>
                            <Col md={12} className="m-1">
                              <Row>
                                <Col md={2}>
                                  <div className="form-check mt-2 mb-2">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={finalData.rolePrivileges.Supply.isAdUnitGroupAccess}
                                      onChange={(e) => setFinalData(finalData => ({
                                        ...finalData, rolePrivileges: {
                                          ...finalData.rolePrivileges, Supply: {
                                            ...finalData.rolePrivileges.Supply,
                                            isAdUnitGroupAccess: e.target.checked
                                          }
                                        }
                                      }))}
                                      id="supplyAdunitGroup"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="supplyAdunitGroup"
                                    >
                                      Ad Unit Group
                                    </label>
                                  </div>
                                </Col>
                                {finalData.rolePrivileges.Supply.isAdUnitGroupAccess &&
                                  <Col md={10} >
                                    {createUI('Supply', 'AdUnitGroups')}
                                  </Col>
                                }
                              </Row>
                            </Col>
                            <Col md={12} className="m-1">
                              <Row>
                                <Col md={2}>
                                  <div className="form-check mt-2 mb-2">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={finalData.rolePrivileges.Supply.isAdUnitAccess}
                                      onChange={(e) => setFinalData(finalData => ({
                                        ...finalData, rolePrivileges: {
                                          ...finalData.rolePrivileges, Supply: {
                                            ...finalData.rolePrivileges.Supply,
                                            isAdUnitAccess: e.target.checked
                                          }
                                        }
                                      }))}
                                      id="supplyAdUnit"

                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="supplyAdUnit"
                                    >
                                      Ad Unit
                                    </label>
                                  </div>
                                </Col>
                                {finalData.rolePrivileges.Supply.isAdUnitAccess &&
                                  <Col md={10} >
                                    {createUI('Supply', 'AdUnits')}
                                  </Col>
                                }
                              </Row>
                            </Col>

                            <div>
                              <Col md={12} className="m-1">
                                <Row>
                                  <Col md={2}>
                                    <div className="form-check mt-2 mb-2">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={finalData.rolePrivileges.Supply.isChildAdUnitAccess}
                                        onChange={(e) => setFinalData(finalData => ({
                                          ...finalData, rolePrivileges: {
                                            ...finalData.rolePrivileges, Supply: {
                                              ...finalData.rolePrivileges.Supply,
                                              isChildAdUnitAccess: e.target.checked
                                            }
                                          }
                                        }))}
                                        id="supplyChildUnit"
                                        disabled={props.selectedPartner.partnerInvertoryType === 'video'}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="supplyChildUnit"
                                      >
                                        Child AdUnit
                                      </label>
                                    </div>
                                  </Col>
                                  {finalData.rolePrivileges.Supply.isChildAdUnitAccess &&
                                    <Col md={10} >
                                      {createUI('Supply', 'ChildAdUnits')}
                                    </Col>
                                  }
                                </Row>
                              </Col>

                              <Col md={12} className="m-1">
                                <Row>
                                  <Col md={2}>
                                    <div className="form-check mt-2 mb-2">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={finalData.rolePrivileges.Supply.isHighImpactSettingAccess}
                                        onChange={(e) => setFinalData(finalData => ({
                                          ...finalData, rolePrivileges: {
                                            ...finalData.rolePrivileges, Supply: {
                                              ...finalData.rolePrivileges.Supply,
                                              isHighImpactSettingAccess: e.target.checked
                                            }
                                          }
                                        }))}
                                        id="isHighImpactSettingAccess"
                                        disabled={props.selectedPartner.partnerInvertoryType === 'video'}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="isHighImpactSettingAccess"
                                      >
                                        High Impact Settings
                                      </label>
                                    </div>
                                  </Col>
                                  {finalData.rolePrivileges.Supply.isHighImpactSettingAccess &&
                                    <Col md={10} >
                                      {createUI('Supply', 'HighImpactSettings')}
                                    </Col>
                                  }
                                </Row>
                              </Col>
                              {finalData.rolePrivileges.Supply.isHighImpactSettingAccess &&
                                <Col md={12} className="m-1">
                                  <Row>
                                    <Col md={2}>
                                      <div className="form-check mt-2 mb-2">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          checked={finalData.rolePrivileges.Supply.isTemplatesCustomAccess}
                                          onChange={(e) => setFinalData(finalData => ({
                                            ...finalData, rolePrivileges: {
                                              ...finalData.rolePrivileges, Supply: {
                                                ...finalData.rolePrivileges.Supply,
                                                isTemplatesCustomAccess: e.target.checked
                                              }
                                            }
                                          }))}
                                          id="isTemplatesCustomAccess"
                                          disabled={props.selectedPartner.partnerInvertoryType === 'video'}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="isTemplatesCustomAccess"
                                        >
                                          {finalData.rolePrivileges.Supply.isTemplatesCustomAccess ? 'Template Selection : Custom' : 'Template Selection : Default'}
                                        </label>
                                      </div>
                                    </Col>
                                    <Col md={4} >
                                      {finalData.rolePrivileges.Supply.isTemplatesCustomAccess ?
                                        <MultiSelect
                                          options={templatesData}
                                          value={templateSelect}
                                          onChange={templateChange}
                                          overrideStrings={{ "selectSomeItems": "Please Select Templates" }}
                                        /> :
                                        <Select
                                          options={templatesData}
                                          classNamePrefix="select2-selection "
                                          className="col-12"
                                          isOptionDisabled={(option) => option === option}
                                          styles={customStyles}
                                          placeholder={"Defualt Templates"}
                                        />}
                                    </Col>
                                  </Row>
                                </Col>
                              }
                              <Col md={12} className="m-1">
                                <Row>
                                  <Col md={2}>
                                    <div className="form-check mt-2 mb-2">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={finalData.rolePrivileges.Supply.isSTDBSettingAccess}
                                        onChange={(e) => setFinalData(finalData => ({
                                          ...finalData, rolePrivileges: {
                                            ...finalData.rolePrivileges, Supply: {
                                              ...finalData.rolePrivileges.Supply,
                                              isSTDBSettingAccess: e.target.checked
                                            }
                                          }
                                        }))}
                                        id="isSTDBSettingAccess"
                                        disabled={props.selectedPartner.partnerInvertoryType === 'video'}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="isSTDBSettingAccess"
                                      >
                                        Standard Banner Settings
                                      </label>
                                    </div>
                                  </Col>
                                  {finalData.rolePrivileges.Supply.isSTDBSettingAccess &&
                                    <Col md={10} >
                                      {createUI('Supply', 'STDBSettings')}
                                    </Col>
                                  }
                                </Row>
                              </Col>
                              {finalData.rolePrivileges.Supply.isSTDBSettingAccess &&
                                <Col md={12} className="m-1">
                                  <Row>
                                    <Col md={2}>
                                      <div className="form-check mt-2 mb-2">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          checked={finalData.rolePrivileges.Supply.isSTDBTemplatesCustomAccess}
                                          onChange={(e) => setFinalData(finalData => ({
                                            ...finalData, rolePrivileges: {
                                              ...finalData.rolePrivileges, Supply: {
                                                ...finalData.rolePrivileges.Supply,
                                                isSTDBTemplatesCustomAccess: e.target.checked
                                              }
                                            }
                                          }))}
                                          id="isSTDBTemplatesCustomAccess"
                                          disabled={props.selectedPartner.partnerInvertoryType === 'video'}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="isSTDBTemplatesCustomAccess"
                                        >
                                          {finalData.rolePrivileges.Supply.isSTDBTemplatesCustomAccess ? 'Template Selection : Custom' : 'Template Selection : Default'}
                                        </label>
                                      </div>
                                    </Col>
                                    <Col md={4} >
                                      {finalData.rolePrivileges.Supply.isSTDBTemplatesCustomAccess ?
                                        <MultiSelect
                                          options={stdbTemplatesData}
                                          value={stdbTemplateSelect}
                                          onChange={stdbTemplateChange}
                                          overrideStrings={{ "selectSomeItems": "Please Select Templates" }}
                                        /> :
                                        <Select
                                          options={stdbTemplatesData}
                                          classNamePrefix="select2-selection "
                                          className="col-12"
                                          isOptionDisabled={(option) => option === option}
                                          styles={customStyles}
                                          placeholder={"Defualt Templates"}
                                        />}
                                    </Col>
                                  </Row>
                                </Col>
                              }
                            </div>
                            <Col md={12} className="m-1">
                              <Row>
                                <Col md={2}>
                                  <div className="form-check mt-2 mb-2">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={finalData.rolePrivileges.Supply.isPlayerSettingAccess}
                                      onChange={(e) => setFinalData(finalData => ({
                                        ...finalData, rolePrivileges: {
                                          ...finalData.rolePrivileges, Supply: {
                                            ...finalData.rolePrivileges.Supply,
                                            isPlayerSettingAccess: e.target.checked
                                          }
                                        }
                                      }))}
                                      id="isPlayerSettingAccess"
                                      disabled={props.selectedPartner.partnerInvertoryType === 'display'}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="isPlayerSettingAccess"
                                    >
                                      Player Settings
                                    </label>
                                  </div>
                                </Col>
                                {finalData.rolePrivileges.Supply.isPlayerSettingAccess &&
                                  <Col md={10} >
                                    {createUI('Supply', 'PlayerSettings')}
                                  </Col>
                                }
                              </Row>
                            </Col>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </Row>

                  <Row className="m-4">
                    <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                      <h2 className="accordion-header m-0" id="headingOne">
                        <label className=" d-sm-flex  justify-content-between form-check-label settingsButton" htmlFor="isReportAccess">
                          <h6 className="heading6 m-3">Report</h6>
                          <div className="text-sm-end">
                            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={finalData.rolePrivileges.isReportAccess}
                                onChange={selectReport}
                                id="isReportAccess"
                              />
                            </div>
                          </div>
                        </label>
                      </h2>
                      <Collapse isOpen={finalData.rolePrivileges.isReportAccess} className="accordion-collapse">
                        <div className="accordion-body">
                          <div className="row">
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.Report.isAnalyticReportAccess}
                                  onChange={(e) => {
                                    setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, isAnalyticReportAccess: e.target.checked } } }));
                                    setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, Query: { ...finalData.rolePrivileges.Report.Query, Analytic: e.target.checked } } } }));
                                  }
                                  }
                                  id="reportingAnalyticsReport"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="reportingAnalyticsReport"
                                >
                                  Analytics Report
                                </label>
                              </div>
                            </Col>
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.Report.isPageUrlReportAccess}
                                  onChange={(e) => {
                                    setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, isPageUrlReportAccess: e.target.checked } } }))
                                    setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, Query: { ...finalData.rolePrivileges.Report.Query, Pageurl: e.target.checked } } } }));
                                  }}
                                  id="PageUrlReport"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="PageUrlReport"
                                >
                                  Page URL  Report
                                </label>
                              </div>
                            </Col>
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.Report.isRevenueReportAccess}
                                  onChange={(e) => {
                                    setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, isRevenueReportAccess: e.target.checked } } }))
                                    setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, Query: { ...finalData.rolePrivileges.Report.Query, Revenue: e.target.checked } } } }));
                                  }}
                                  id="revenueReport"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="revenueReport"
                                >
                                  Revenue Report
                                </label>
                              </div>
                            </Col>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </Row>

                  <Row className="m-4">
                    <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                      <h2 className="accordion-header m-0" id="headingOne">
                        <label className=" d-sm-flex  justify-content-between form-check-label settingsButton" htmlFor="isBillingAccess">
                          <h6 className="heading6 m-3">Billing</h6>
                          <div className="text-sm-end">
                            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={finalData.rolePrivileges.isBillingAccess}
                                onChange={selectBilling}
                                id="isBillingAccess"
                              />
                            </div>
                          </div>
                        </label>
                      </h2>
                      <Collapse isOpen={finalData.rolePrivileges.isBillingAccess} className="accordion-collapse">
                        <div className="accordion-body">
                          <div className="row">
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.Billing.isInvoiceHistoryAccess}
                                  onChange={(e) => {
                                    setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Billing: { ...finalData.rolePrivileges.Billing, isInvoiceHistoryAccess: e.target.checked } } }))
                                    setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Billing: { ...finalData.rolePrivileges.Billing, Invoices: { ...finalData.rolePrivileges.Billing.Invoices, History: e.target.checked } } } }));
                                  }}
                                  id="billingInvoiceHistory"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="billingInvoiceHistory"
                                >
                                  Invoice History
                                </label>
                              </div>
                            </Col>
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.Billing.isInvoicePaymentAccess}
                                  onChange={(e) => {
                                    setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Billing: { ...finalData.rolePrivileges.Billing, isInvoicePaymentAccess: e.target.checked } } }))
                                    setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Billing: { ...finalData.rolePrivileges.Billing, Invoices: { ...finalData.rolePrivileges.Billing.Invoices, Payment: e.target.checked } } } }));
                                  }}
                                  id="billingPayments"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="billingPayments"
                                >
                                  Payments
                                </label>
                              </div>
                            </Col>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </Row>

                  <Row className="m-4">
                    <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                      <h2 className="accordion-header m-0" id="headingOne">
                        <label className=" d-sm-flex  justify-content-between form-check-label" htmlFor="isRevenueOpportunitiesAccess">
                          <h6 className="heading6 m-3">Revenue Opportunities</h6>
                          <div className="text-sm-end">
                            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={finalData.rolePrivileges.isRevenueOpportunitiesAccess}
                                onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isRevenueOpportunitiesAccess: e.target.checked } }))}
                                id="isRevenueOpportunitiesAccess"
                              />
                            </div>
                          </div>
                        </label>
                      </h2>
                    </div>
                  </Row>

                  <Row className="m-4">
                    <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                      <h2 className="accordion-header m-0" id="headingOne">
                        <label className=" d-sm-flex  justify-content-between form-check-label settingsButton" htmlFor="isVCsAccess">
                          <h6 className="heading6 m-3">Video Content Suite</h6>
                          <div className="text-sm-end">
                            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={finalData.rolePrivileges.isVCsAccess}
                                onChange={selectVCS}
                                id="isVCsAccess"
                              />
                            </div>
                          </div>
                        </label>
                      </h2>
                      <Collapse isOpen={finalData.rolePrivileges.isVCsAccess} className="accordion-collapse">
                        <div className="accordion-body">
                          <div className="row">
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.VCs.isVideoContentLibrarayAccess}
                                  onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, VCs: { ...finalData.rolePrivileges.VCs, isVideoContentLibrarayAccess: e.target.checked } } }))}
                                  id="videoContentLibrarys"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="videoContentLibrarys"
                                >
                                  Video Content Library
                                </label>
                              </div>
                            </Col>
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.VCs.isVideoPlayListAccess}
                                  onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, VCs: { ...finalData.rolePrivileges.VCs, isVideoPlayListAccess: e.target.checked } } }))}
                                  id="playList"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="playList"
                                >
                                  Video Play List
                                </label>
                              </div>
                            </Col>
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.VCs.isVideoImportAccess}
                                  onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, VCs: { ...finalData.rolePrivileges.VCs, isVideoImportAccess: e.target.checked } } }))}
                                  id="isVideoImportAccess"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="isVideoImportAccess"
                                >
                                  Video Import
                                </label>
                              </div>
                            </Col>
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.VCs.isContentMarketplaceAccess
                                  }
                                  onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, VCs: { ...finalData.rolePrivileges.VCs, isContentMarketplaceAccess: e.target.checked } } }))}
                                  id="isContentMarketplaceAccess"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="isContentMarketplaceAccess"
                                >
                                  Content Marketplace
                                </label>
                              </div>
                            </Col>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </Row>

                  <Row className="m-4">
                    <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                      <h2 className="accordion-header m-0" id="headingOne">
                        <label className=" d-sm-flex  justify-content-between form-check-label settingsButton" htmlFor="Settings">
                          <h6 className="heading6 m-3">Settings</h6>
                          <div className="text-sm-end">
                            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={finalData.rolePrivileges.isPublisherSettingsAccess}
                                onChange={selectSettings}
                                id="Settings"
                              />
                            </div>
                          </div>
                        </label>
                      </h2>
                      <Collapse isOpen={finalData.rolePrivileges.isPublisherSettingsAccess} className="accordion-collapse">
                        <div className="accordion-body">
                          <div className="row">
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.settings.isAdsTxtManagementAccess}
                                  onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, settings: { ...finalData.rolePrivileges.settings, isAdsTxtManagementAccess: e.target.checked } } }))}
                                  id="adsTxtManagement"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="adsTxtManagement"
                                >
                                  Ads.Txt Management
                                </label>
                              </div>
                            </Col>
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.settings.isBrandSafetyAccess}
                                  onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, settings: { ...finalData.rolePrivileges.settings, isBrandSafetyAccess: e.target.checked } } }))}
                                  id="isBrandSafetyAccess"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="isBrandSafetyAccess"
                                >
                                  Brand Safety
                                </label>
                              </div>
                            </Col>
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.settings.isCMSConfigurationAccess}
                                  onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, settings: { ...finalData.rolePrivileges.settings, isCMSConfigurationAccess: e.target.checked } } }))}
                                  id="isCMSConfigurationAccess"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="isCMSConfigurationAccess"
                                >
                                  CMS Configuration
                                </label>
                              </div>
                            </Col>
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.settings.isReportingAPIAccess}
                                  onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, settings: { ...finalData.rolePrivileges.settings, isReportingAPIAccess: e.target.checked } } }))}
                                  id="isReportingAPIAccess"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="isReportingAPIAccess"
                                >
                                  Reporting API
                                </label>
                              </div>
                            </Col>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </Row>

                  <Row className="m-4">
                    <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                      <h2 className="accordion-header m-0" id="headingOne">
                        <label className=" d-sm-flex  justify-content-between form-check-label" htmlFor="supportTicket">
                          <h6 className="heading6 m-3">Suport Ticket</h6>
                          <div className="text-sm-end">
                            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={finalData.rolePrivileges.isSupportTicketAccess}
                                onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isSupportTicketAccess: e.target.checked } }))}
                                id="supportTicket"
                              />
                            </div>
                          </div>
                        </label>
                      </h2>
                    </div>
                  </Row>

                </div>
              }

              {!finalData.privilegesType &&
                <div>



                  <Row className="m-4">
                    <div className="accordion-item p-0 ml-0 mr-0 mb-2">

                      <h2 className="accordion-header m-0" id="headingOne">
                        <label className=" d-sm-flex  justify-content-between form-check-label settingsButton" htmlFor="isDashboardAccess">
                          <h6 className="heading6 m-3">Dashboards</h6>
                          <div className="text-sm-end">
                            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={finalData.rolePrivileges.isDashboardAccess}
                                onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isDashboardAccess: e.target.checked } }))}
                                id="isDashboardAccess"
                                disabled
                              />
                            </div>
                          </div>
                        </label>
                      </h2>
                      <Collapse isOpen={finalData.rolePrivileges.isDashboardAccess} className="accordion-collapse">
                        <div className="accordion-body">
                          <div className="row">
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.Dashboard.isRevenueAccess}
                                  onChange={(e) => setFinalData(finalData => ({
                                    ...finalData, rolePrivileges: {
                                      ...finalData.rolePrivileges, Dashboard: {
                                        ...finalData.rolePrivileges.Dashboard,
                                        isRevenueAccess: e.target.checked
                                      }
                                    }
                                  }))}
                                  disabled
                                  id="marketplaceDashboard"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="marketplaceDashboard"
                                >
                                  Marketplace Dashboard
                                </label>
                              </div>
                            </Col>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </Row>

                  <Row className="m-4">
                    <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                      <h2 className="accordion-header m-0" id="headingOne">
                        <label className=" d-sm-flex  justify-content-between form-check-label settingsButton" htmlFor="isSupplyAccess">
                          <h6 className="heading6 m-3">Supply</h6>
                          <div className="text-sm-end">
                            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={finalData.rolePrivileges.isSupplyAccess}
                                onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isSupplyAccess: e.target.checked } }))}
                                id="isSupplyAccess"
                                disabled
                              />
                            </div>
                          </div>
                        </label>
                      </h2>
                      <Collapse isOpen={finalData.rolePrivileges.isSupplyAccess} className="accordion-collapse">
                        <div className="accordion-body">
                          <div>
                            <Col md={12} className="m-1">
                              <Row>
                                <Col md={2}>
                                  <div className="form-check mt-2 mb-2">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={finalData.rolePrivileges.Supply.isPublisherAccess}
                                      onChange={(e) => setFinalData(finalData => ({
                                        ...finalData, rolePrivileges: {
                                          ...finalData.rolePrivileges, Supply: {
                                            ...finalData.rolePrivileges.Supply,
                                            isPublisherAccess: e.target.checked
                                          }
                                        }
                                      }))}
                                      id="supplyPublisher"
                                      disabled
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="supplyPublisher"
                                    >
                                      Publisher
                                    </label>
                                  </div>
                                </Col>
                                {/* {finalData.rolePrivileges.Supply.isPublisherAccess &&
                                  <Col md={10} >
                                    {createUI('Supply', 'Publishers')}
                                  </Col>
                                } */}
                              </Row>
                            </Col>
                            <Col md={12} className="m-1">
                              <Row>
                                <Col md={2}>
                                  <div className="form-check mt-2 mb-2">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={finalData.rolePrivileges.Supply.isAdUnitGroupAccess}
                                      onChange={(e) => setFinalData(finalData => ({
                                        ...finalData, rolePrivileges: {
                                          ...finalData.rolePrivileges, Supply: {
                                            ...finalData.rolePrivileges.Supply,
                                            isAdUnitGroupAccess: e.target.checked
                                          }
                                        }
                                      }))}
                                      id="supplyAdunitGroup"
                                      disabled
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="supplyAdunitGroup"
                                    >
                                      Ad Unit Group
                                    </label>
                                  </div>
                                </Col>
                                {/* {finalData.rolePrivileges.Supply.isAdUnitGroupAccess &&
                                  <Col md={10} >
                                    {createUI('Supply', 'AdUnitGroups')}
                                  </Col>
                                } */}
                              </Row>
                            </Col>
                            <Col md={12} className="m-1">
                              <Row>
                                <Col md={2}>
                                  <div className="form-check mt-2 mb-2">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={finalData.rolePrivileges.Supply.isAdUnitAccess}
                                      onChange={(e) => setFinalData(finalData => ({
                                        ...finalData, rolePrivileges: {
                                          ...finalData.rolePrivileges, Supply: {
                                            ...finalData.rolePrivileges.Supply,
                                            isAdUnitAccess: e.target.checked
                                          }
                                        }
                                      }))}
                                      id="supplyAdUnit"
                                      disabled
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="supplyAdUnit"
                                    >
                                      Ad Unit
                                    </label>
                                  </div>
                                </Col>
                                {/* {finalData.rolePrivileges.Supply.isAdUnitAccess &&
                                  <Col md={10} >
                                    {createUI('Supply', 'AdUnits')}
                                  </Col>
                                } */}
                              </Row>
                            </Col>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </Row>

                  <Row className="m-4">
                    <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                      <h2 className="accordion-header m-0" id="headingOne">
                        <label className=" d-sm-flex  justify-content-between form-check-label settingsButton" htmlFor="isReportAccess">
                          <h6 className="heading6 m-3">Reporting</h6>
                          <div className="text-sm-end">
                            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={finalData.rolePrivileges.isReportAccess}
                                onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isReportAccess: e.target.checked } }))}
                                id="isReportAccess"
                                disabled
                              />
                            </div>
                          </div>
                        </label>
                      </h2>
                      <Collapse isOpen={finalData.rolePrivileges.isReportAccess} className="accordion-collapse">
                        <div className="accordion-body">
                          <div className="row">
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.Report.isRevenueReportAccess}
                                  onChange={(e) => {
                                    setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, isRevenueReportAccess: e.target.checked } } }))
                                    setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, Query: { ...finalData.rolePrivileges.Report.Query, Revenue: e.target.checked } } } }));
                                  }}
                                  id="revenueReport"
                                  disabled
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="revenueReport"
                                >
                                  Revenue Report
                                </label>
                              </div>
                            </Col>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </Row>

                  <Row className="m-4">
                    <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                      <h2 className="accordion-header m-0" id="headingOne">
                        <label className=" d-sm-flex  justify-content-between form-check-label settingsButton" htmlFor="isBillingAccess">
                          <h6 className="heading6 m-3">Billing</h6>
                          <div className="text-sm-end">
                            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={finalData.rolePrivileges.isBillingAccess}
                                onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isBillingAccess: e.target.checked } }))}
                                id="isBillingAccess"
                                disabled
                              />
                            </div>
                          </div>
                        </label>
                      </h2>
                      <Collapse isOpen={finalData.rolePrivileges.isBillingAccess} className="accordion-collapse">
                        <div className="accordion-body">
                          <div className="row">
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.Billing.isInvoiceHistoryAccess}
                                  onChange={(e) => {
                                    setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Billing: { ...finalData.rolePrivileges.Billing, isInvoiceHistoryAccess: e.target.checked } } }))
                                    setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Billing: { ...finalData.rolePrivileges.Billing, Invoices: { ...finalData.rolePrivileges.Billing.Invoices, History: e.target.checked } } } }));
                                  }}
                                  id="billingInvoiceHistory"
                                  disabled
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="billingInvoiceHistory"
                                >
                                  Invoice History
                                </label>
                              </div>
                            </Col>
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.Billing.isInvoicePaymentAccess}
                                  onChange={(e) => {
                                    setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Billing: { ...finalData.rolePrivileges.Billing, isInvoicePaymentAccess: e.target.checked } } }))
                                    setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Billing: { ...finalData.rolePrivileges.Billing, Invoices: { ...finalData.rolePrivileges.Billing.Invoices, Payment: e.target.checked } } } }));
                                  }}
                                  id="billingPayments"
                                  disabled
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="billingPayments"
                                >
                                  Payments
                                </label>
                              </div>
                            </Col>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </Row>


                  <Row className="m-4">
                    <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                      <h2 className="accordion-header m-0" id="headingOne">
                        <label className=" d-sm-flex  justify-content-between form-check-label" htmlFor="isRevenueOpportunitiesAccess">
                          <h6 className="heading6 m-3">Revenue Opportunities</h6>
                          <div className="text-sm-end">
                            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={finalData.rolePrivileges.isRevenueOpportunitiesAccess}
                                onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isRevenueOpportunitiesAccess: e.target.checked } }))}
                                id="isRevenueOpportunitiesAccess"
                                disabled
                              />
                            </div>
                          </div>
                        </label>
                      </h2>
                    </div>
                  </Row>

                  <Row className="m-4">
                    <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                      <h2 className="accordion-header m-0" id="headingOne">
                        <label className=" d-sm-flex  justify-content-between form-check-label settingsButton" htmlFor="isVCsAccess">
                          <h6 className="heading6 m-3">Video Content Suite</h6>
                          <div className="text-sm-end">
                            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={finalData.rolePrivileges.isVCsAccess}
                                onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isVCsAccess: e.target.checked } }))}
                                id="isVCsAccess"
                                disabled
                              />
                            </div>
                          </div>
                        </label>
                      </h2>
                      <Collapse isOpen={finalData.rolePrivileges.isVCsAccess} className="accordion-collapse">
                        <div className="accordion-body">
                          <div className="row">
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.VCs.isVideoContentLibrarayAccess}
                                  onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, VCs: { ...finalData.rolePrivileges.VCs, isVideoContentLibrarayAccess: e.target.checked } } }))}
                                  id="videoContentLibrarys"
                                  disabled
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="videoContentLibrarys"
                                >
                                  Video Content Library
                                </label>
                              </div>
                            </Col>
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.VCs.isVideoPlayListAccess}
                                  onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, VCs: { ...finalData.rolePrivileges.VCs, isVideoPlayListAccess: e.target.checked } } }))}
                                  id="playList"
                                  disabled
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="playList"
                                >
                                  Video Play List
                                </label>
                              </div>
                            </Col>
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.VCs.isVideoImportAccess}
                                  onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, VCs: { ...finalData.rolePrivileges.VCs, isVideoImportAccess: e.target.checked } } }))}
                                  id="isVideoImportAccess"
                                  disabled
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="isVideoImportAccess"
                                >
                                  Video Import
                                </label>
                              </div>
                            </Col>
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.VCs.isContentMarketplaceAccess
                                  }
                                  onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, VCs: { ...finalData.rolePrivileges.VCs, isContentMarketplaceAccess: e.target.checked } } }))}
                                  id="isContentMarketplaceAccess"
                                  disabled
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="isContentMarketplaceAccess"
                                >
                                  Content Marketplace
                                </label>
                              </div>
                            </Col>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </Row>

                  <Row className="m-4">
                    <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                      <h2 className="accordion-header m-0" id="headingOne">
                        <label className=" d-sm-flex  justify-content-between form-check-label settingsButton" htmlFor="Settings">
                          <h6 className="heading6 m-3">Settings</h6>
                          <div className="text-sm-end">
                            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={finalData.rolePrivileges.isPublisherSettingsAccess}
                                onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isPublisherSettingsAccess: e.target.checked } }))}
                                id="Settings"
                                disabled
                              />
                            </div>
                          </div>
                        </label>
                      </h2>
                      <Collapse isOpen={finalData.rolePrivileges.isPublisherSettingsAccess} className="accordion-collapse">
                        <div className="accordion-body">
                          <div className="row">
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.settings.isAdsTxtManagementAccess}
                                  onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, settings: { ...finalData.rolePrivileges.settings, isAdsTxtManagementAccess: e.target.checked } } }))}
                                  id="adsTxtManagement"
                                  disabled
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="adsTxtManagement"
                                >
                                  Ads.Txt Management
                                </label>
                              </div>
                            </Col>
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.settings.isBrandSafetyAccess}
                                  onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, settings: { ...finalData.rolePrivileges.settings, isBrandSafetyAccess: e.target.checked } } }))}
                                  id="isBrandSafetyAccess"
                                  disabled
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="isBrandSafetyAccess"
                                >
                                  Brand Safety
                                </label>
                              </div>
                            </Col>
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.settings.isCMSConfigurationAccess}
                                  onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, settings: { ...finalData.rolePrivileges.settings, isCMSConfigurationAccess: e.target.checked } } }))}
                                  id="isCMSConfigurationAccess"
                                  disabled
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="isCMSConfigurationAccess"
                                >
                                  CMS Configuration
                                </label>
                              </div>
                            </Col>
                            <Col md={3} >
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.settings.isReportingAPIAccess}
                                  onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, settings: { ...finalData.rolePrivileges.settings, isReportingAPIAccess: e.target.checked } } }))}
                                  id="isReportingAPIAccess"
                                  disabled
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="isReportingAPIAccess"
                                >
                                  Reporting API
                                </label>
                              </div>
                            </Col>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </Row>

                  <Row className="m-4">
                    <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                      <h2 className="accordion-header m-0" id="headingOne">
                        <label className=" d-sm-flex  justify-content-between form-check-label" htmlFor="isSupportTicketAccess">
                          <h6 className="heading6 m-3">Suport Ticket</h6>
                          <div className="text-sm-end">
                            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={finalData.rolePrivileges.isSupportTicketAccess}
                                onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isSupportTicketAccess: e.target.checked } }))}
                                id="isSupportTicketAccess"
                                disabled
                              />
                            </div>
                          </div>
                        </label>
                      </h2>
                    </div>
                  </Row>

                </div>
              }
            </Col>
          </Row>
          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
                &nbsp;&nbsp;

                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }
                &nbsp;&nbsp;
                {isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }

              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};

Supply_Publisher_Role_Access.propTypes = {}

export default Supply_Publisher_Role_Access