import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_PUBLISHERS, ADD_PUBLISHER, UPDATE_PUBLISHER, READ_PUBLISHER, STATUS_PUBLISHER, ARCHIVE_PUBLISHER } from "./actionTypes"

import {
  loadActivitiesPublisher,
  noDataPublisher,
  getPublisherSuccess,
  getPublisherFail,
  addPublisherSuccess,
  addPublisherFail,
  updatePublisherSuccess,
  updatePublisherFail,
  readPublisherSuccess,
  statusPublisherSuccess,
  archivePublisherSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getPublisher, getPublisherByType, getPublishersByPartner, createPublisher, updatePublisher, readPublisher, statusChangePublisher, archivePublisher } from "../../../helpers/Backend_helper"

function* fetchPublishers({ payload: publisher }) {
  try {
    yield put(loadActivitiesPublisher(true))
    var getPublisherList
    console.log(publisher)
    if (publisher && publisher.type) {
      if (publisher && publisher.partnerID !== '') {
        getPublisherList = yield call(getPublishersByPartner, publisher)
        getPublisherList.response.map((item, index) => {
          item.id = index + 1
        })
        if(getPublisherList.response.length === 0){
          yield put(noDataPublisher(true))
        }
        yield put(getPublisherSuccess(getPublisherList))
      } else if (publisher && publisher.partnerID === '') {
        delete publisher.partnerID
        getPublisherList = yield call(getPublisherByType, publisher)
        getPublisherList.response.map((item, index) => {
          item.id = index + 1
        })
        if(getPublisherList.response.length === 0){
          yield put(noDataPublisher(true))
        }
        yield put(getPublisherSuccess(getPublisherList))
      }
    } else{
      if (publisher && publisher.partnerID !== '') {
        getPublisherList = yield call(getPublishersByPartner, publisher)
        getPublisherList.response.map((item, index) => {
          item.id = index + 1
        })
        if(getPublisherList.response.length === 0){
          yield put(noDataPublisher(true))
        }
        yield put(getPublisherSuccess(getPublisherList))
      }
      else if (publisher && publisher.partnerID === '') {
        delete publisher.partnerID
        getPublisherList = yield call(getPublisher, publisher)
        getPublisherList.response.map((item, index) => {
          item.id = index + 1
        })
        if(getPublisherList.response.length === 0){
          yield put(noDataPublisher(true))
        }
        yield put(getPublisherSuccess(getPublisherList))
      } else {
        getPublisherList = []
        yield put(getPublisherSuccess(getPublisherList))
      }
    }
  } catch (error) {
    yield put(getPublisherFail(error))
  }
}

function* onReadPublisher({ payload: publisher }) {
  try {
    const response = yield call(readPublisher, publisher)
    yield put(readPublisherSuccess(response))
  } catch (error) {
    yield put(readPublisherSuccess(error))
  }
}

function* onAddPublisher({ payload: publisher }) {
  try {
    const response = yield call(createPublisher, publisher)
    if (response.success) {
      yield put(addPublisherSuccess(response))
    } else {
      yield put(addPublisherFail(response))
    }
  } catch (error) {
    yield put(addPublisherFail(error))
  }
}

function* onUpdatePublisher({ payload: publisher }) {
  try {
    const response = yield call(updatePublisher, publisher)
    if (response.success) {
      yield put(updatePublisherSuccess(response))
    } else {
      yield put(updatePublisherFail(response))
    }
  } catch (error) {
    yield put(updatePublisherFail(error))
  }
}

function* onStatusPublisher({ payload: publisher }) {
  try {
    const response = yield call(statusChangePublisher, publisher)
    yield put(statusPublisherSuccess(response))
  } catch (error) {
    yield put(statusPublisherSuccess(error))
  }
}

function* onArchivePublisher({ payload: publisher }) {
  try {
    const response = yield call(archivePublisher, publisher)
    yield put(archivePublisherSuccess(response))
  } catch (error) {
    yield put(archivePublisherSuccess(error))
  }
}

function* publisherSaga() {
  yield takeEvery(GET_PUBLISHERS, fetchPublishers)
  yield takeEvery(ADD_PUBLISHER, onAddPublisher)
  yield takeEvery(UPDATE_PUBLISHER, onUpdatePublisher)
  yield takeEvery(READ_PUBLISHER, onReadPublisher)
  yield takeEvery(STATUS_PUBLISHER, onStatusPublisher)
  yield takeEvery(ARCHIVE_PUBLISHER, onArchivePublisher)
}

export default publisherSaga;
