import {
    LOAD_ACTIVITIES,
    NO_DATA,
    GET_PUBLISHERS_SUCCESS,
    GET_PUBLISHERS_FAIL,
    ADD_PUBLISHER_SUCCESS,
    ADD_PUBLISHER_ERROR,
    UPDATE_PUBLISHER_SUCCESS,
    UPDATE_PUBLISHER_ERROR,
    READ_PUBLISHER_SUCCESS,
    STATUS_PUBLISHER_SUCCESS,
    ARCHIVE_PUBLISHER_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    publisherList: [],
    pagination:{},
    error: {},
    loading:false,
    noData:false,
    successMsg: null,
    errorMsg: null,
    publisher: null,
    response:null,
    archiveMsg:null,
}

const publishers = (state = INIT_STATE, action) => {
    console.log
    switch (action.type) {
        case LOAD_ACTIVITIES:
            return {
                ...state,
                loading: true,
            }
            case NO_DATA:
                return {
                    ...state,
                    noData: true,
                }
        case GET_PUBLISHERS_SUCCESS:
            return {
                ...state,
                loading:false,
                publisherList: action.payload.response ? action.payload.response : [],
                pagination:action.payload
            }

        case GET_PUBLISHERS_FAIL:
            return {
                ...state,
                loading:false,
                error: action.payload,
            }
        case ADD_PUBLISHER_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_PUBLISHER_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_PUBLISHERS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_PUBLISHER_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_PUBLISHERS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_PUBLISHER_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_PUBLISHERS_SUCCESS' ? null : action.payload,
            }
        case READ_PUBLISHER_SUCCESS:
            return {
                ...state,
                publisher: action.payload,
            }
        case STATUS_PUBLISHER_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_PUBLISHER_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default publishers