/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, Spinner, } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import Creative_Selection from "./Creative_Selection";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// actions
import {
  addNewCreative as onADDCreative,
  updateCreative as onUpdateCreative,
  readCreative as onReadCreative,
  addCreativeSuccess,
  addCreativeFail,
  updateCreativeSuccess,
  updateCreativeFail,
} from "store/Demand/Creative/actions";
import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";

const NewImageCreative = props => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [loader, setLoader] = useState(false);
  console.log(props)
  const userInfo = JSON.parse(localStorage.getItem('authUser'));

  const [finalData, setFinalData] = useState({
    creativeType: "image",
    typeFor: props.creativeType,
    creativeWeight: 1,
    url: '',
    creativeTemplateName: '',
    MIME: '',
    name: '',
    isFileAvailable: false,
    creativeLandingPageUrl: '',
    // HbTag:'',
  })

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 1000,
    extendedTimeOut: 1000
  };


  const [submitted, setSubmitted] = useState(false);
  const [googleState, setGoogleState] = useState(true);

  const { errorMsg, successMsg, creativeData } = useSelector(state => ({
    errorMsg: state.creatives.errorMsg,
    successMsg: state.creatives.successMsg,
    creativeData: state.creatives.creative
  }));

  console.log(errorMsg, successMsg, creativeData)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.success) {
        dispatch(addCreativeSuccess(""));
        dispatch(addCreativeFail(null));
        dispatch(updateCreativeSuccess(""));
        dispatch(updateCreativeFail(null));
        setLoader(false)
        if (props.selectedCreativeData !== '' && props.selectedcreativeCreateStatus !== 'Duplicate') {
          props.creativeNewClose('Update')
        } else {
          props.creativeNewClose('Create')
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    console.log(errorMsg)
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if (errorMsg.status && errorMsg.status === 500) {
      } else {
        dispatch(addCreativeSuccess(""));
        dispatch(addCreativeFail(null));
        dispatch(updateCreativeSuccess(""));
        dispatch(updateCreativeFail(null));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addCreativeSuccess(""));
    dispatch(addCreativeFail(null));
    dispatch(updateCreativeSuccess(""));
    dispatch(updateCreativeFail(null));
    props.creativeNewClose('')
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFinalData(creative => ({ ...creative, [name]: value }));
  }

  const blurChange = (e) => {
    console.log("input blurred")
    const { name, value } = e.target;
    if (e.target.value < 1) {
      setFinalData(finalData => ({ ...finalData, [name]: 1 }));
    } else {
      setFinalData(finalData => ({ ...finalData, [name]: value }));
    }
  }

  const getCreativeData = async (selectedCreativeData) => {
    console.log(selectedCreativeData)
    setFinalData({ ...finalData, ...selectedCreativeData })
    console.log(finalData)
  }

  const changeTarget3 = (e) => {
    // setGoogleState(e.target.checked)
    // if (e.target.checked) {
    //   setFinalData({ ...finalData, googleDemandOption: 'firstlook' })
    // } else {
    //   setFinalData({ ...finalData, googleDemandOption: 'all' })
    // }
  }

  useEffect(() => {
    console.log(props.selectedCreativeData)
    if (props.selectedCreativeData !== '') {
      dispatch(onReadCreative({ ...props.selectedCreativeData,userRole:userInfo.userRole,userID:userInfo.userID}));
      if(props.selectedcreativeCreateStatus !== 'Duplicate'){
        setIsEdit(true)
      }
  }
    if (props.selectedLineItem !== '') {
      setFinalData({ ...finalData, lineItemID: props.selectedLineItem.lineItemID })
    }
  }, [])

  useEffect(() => {
    if (creativeData !== null && props.selectedCreativeData !== '') {
      console.log(creativeData)
      if (props.selectedcreativeCreateStatus === 'Duplicate') {
       delete creativeData.creativeCreateTime
       delete creativeData.creativeID
       delete creativeData.creativeModifyTime
       delete creativeData.creativeStatus
       delete creativeData.isCreativeArchived
      }
      setFinalData(finalData => ({ ...finalData, ...creativeData }));
      console.log(finalData)
      // setGoogleState(creativeData.googleDemandOption === 'firstlook' ? true : false)
    }
  }, [creativeData]);

  const fileChange = (file) => {
    console.log(file.target.files[0])
    console.log(file.target.files[0].name)
    // fileName = file.target.files[0].name
    encodeFileBase64(file.target.files[0])
  };

  const encodeFileBase64 = (file) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var base64 = reader.result
        if (base64.includes("data:image/png;base64,")) {
          base64 = reader.result.replace("data:image/png;base64,", "");
        }
        else if (base64.includes("data:image/jpeg;base64,")) {
          base64 = reader.result.replace("data:image/jpeg;base64,", "");
        } else if (base64.includes("data:image/gif;base64,")) {
          base64 = reader.result.replace("data:image/gif;base64,", "");
        }
        console.log(base64)
        if (base64) {
          setFinalData({ ...finalData, MIME: file.type, name: file.name, url: base64, isFileAvailable: true })
        }
      }
      reader.onerror = (error) => {
        console.log("error: ", error)
      };
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(finalData)
    var body = ''
    setSubmitted(true);
    if (finalData.creativeName && finalData.lineItemID && finalData.creativeWeight) {
      setLoader(true)
      if (props.selectedCreativeData !== '' && props.selectedcreativeCreateStatus !== 'Duplicate') {
        dispatch(onUpdateCreative({ ...finalData, creativeName: finalData.creativeName.trim(),userRole:userInfo.userRole,userID:userInfo.userID }));
      } else {
        dispatch(onADDCreative({ ...finalData, creativeName: finalData.creativeName.trim(),userRole:userInfo.userRole,userID:userInfo.userID }));
      }
    }
  }

  function allowOnlyNumbers(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code >= 48 && code < 58) && !(code >= 46 && code < 47)) { e.preventDefault(); }
  };

  return (
    <React.Fragment>
      {loader &&
        <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
        <Form onSubmit={handleSubmit}>
          <Row className="formBodyScroll" style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
            <Col xs={12}>
              <h6 className="text-muted">Image Creative Setup</h6>
              <hr></hr>
              <Creative_Selection selectedArchived={props.selectedArchived} creativeType={props.creativeType} selectedItems={getCreativeData} submitted={submitted} selectedCreativeData={props.selectedCreativeData} creativeStatus={isEdit} selectedLineItem={props.selectedLineItem} />
              <h6 className="text-muted">Image Setup</h6>
              <hr></hr>
              <div className="mb-3">
                <Label className="form-label">Browse Image File</Label>
                <div>
                  {/* <Input id="uploadFile" value={finalData.name} className="f-input" readOnly />
                  <div className="fileUpload  btn--browse">
                    <span>Browse</span>
                    <Input id="uploadBtn" type="file" accept="image/x-png,image/gif,image/jpeg" className="upload" onChange={fileChange} />
                  </div> */}
                  <Input type="file" accept="image/x-png,image/gif,image/jpeg" onChange={fileChange} disabled={props.selectedArchived} />
                  <span className="notes">Note: Allowed file types: gif, jpg, png, jpeg. Maximum allowed file size: 15 MB.</span>
                </div>
              </div>
              {/* <div className="mb-3">
                <Label >
                  Google Demand
                </Label>
                <Input
                  type="textarea"
                  id="textarea"
                  rows="3"
                  onChange={handleChange} name="googleDemandTag" value={finalData.googleDemandTag}
                  className={'form-control'}
                  readOnly={props.selectedArchived}
                />
              </div> */}
              <div className="mb-3">
                <Label >
                  Landing Page URL
                </Label>
                <Input
                  type="text"
                  onChange={handleChange} name="creativeLandingPageUrl" value={finalData.creativeLandingPageUrl}
                  className={'form-control'}
                  readOnly={props.selectedArchived}
                />
              </div>
              {/* <div className="mb-3">
                <Label >
                Header Bidding Demand Tag
                </Label>
                <Input
                  type="textarea"
                  id="textarea"
                  rows="3"
                  onChange={handleChange} name="HbTag" value={finalData.HbTag}   readOnly={props.selectedArchived}
                   placeholder='Header Bidding Demand Tag' className={'form-control'}
                />
              </div> */}
              {/* <Label >
                Google Demand Options
              </Label>
              <div className="form-check form-switch form-switch-md mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={googleState}
                  onChange={changeTarget3}
                  disabled={props.selectedArchived}
                />
                <label
                  className="form-check-label"
                >
               {googleState ? "First Look" : 'All'}
                </label>

              </div> */}
              <div className="mb-3">
                <Label className="form-label">Creative Weightage</Label>
                <Input
                  type="text" name="creativeWeight" value={finalData.creativeWeight} onKeyPress={allowOnlyNumbers} onBlur={blurChange} readOnly={props.selectedArchived}
                  onChange={handleChange} className={'form-control' + (submitted && !finalData.creativeWeight ? ' is-invalid' : '')}
                />
                {submitted && !finalData.creativeWeight &&
                  <div className="invalid-feedback">Creative Weightage is required</div>
                }
              </div>
            </Col>
          </Row>
          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">
                {isEdit && !props.selectedArchived &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.creativeTypeClose}
                >
                  Back
                </button>
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
          <Toaster status="error" msg={errorMsg.message} />
        }
      </div>
      {errorMsg !== null && errorMsg !== '' && errorMsg.status && errorMsg.status === 500 &&
        <div className="p-0 justify-content-center d-flex">
          <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')} />
        </div>
      }
    </React.Fragment>
  )
}

NewImageCreative.propTypes = {
  history: PropTypes.object,
}

export default NewImageCreative