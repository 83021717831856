/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import {
  Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Spinner, NavItem, Collapse, Button,
  NavLink,
  TabContent,
  TabPane, InputGroup, Tooltip
} from "reactstrap";
import PropTypes from "prop-types";
import axios from "axios";
import Select, { StylesConfig } from 'react-select';
import classnames from "classnames"
import queryString from 'query-string';

import _ from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// actions
import {
  updateAdUnitBid as onUpdateHBBuyer,
  addHeaderBidSuccess,
  addHeaderBidFail,
  updateAdUnitBidSuccess,
  updateAdUnitBidFail,
} from "store/HeaderBidding/HeaderBid/actions";
import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";
import DevicewiseAssociate from "./DevicewiseAssociate";
import MobilewiseAssociate from "./MobilewiseAssociate";
const AssociateAdUnits = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loader, setLoader] = useState(false);
  const [desktopItemOpen, setDesktopItemOpen] = useState(true)
  const [mobileItemOpen, setMobileItemOpen] = useState(false)
  const [videosList, setVideosList] = useState([])
  const [mobileSettingsList, setMobileSettingsList] = useState([{
    settingsEnabled: true,
    settingConfigMode: 'SimpleMode',
    settingInfo: []
  }])
  const [desktopSettingsList, setDesktopSettingsList] = useState([{
    settingsEnabled: true,
    settingConfigMode: 'SimpleMode',
    settingInfo: []
  }])
  const parsed = queryString.parse(window.location.search);

  const [targetStates, setTargetStates] = useState({
    desktop: true,
    mobile: false,
  })

  const [adUnitSettingsInfo, setAdUnitSettingsInfo] = useState({
    desktopSettings: {
      _size: '',
      size:'',
      timeout: 1500,
      floorPrice: {
        floorType: 'RON',
        floorValues: {
          'RON': {
            price: 0.01,
            enabled: true,
          }
        }
      },
      optimizationStrategyCall: false,
      optimizationStrategy: {
        type: 'floor',
        price: 0.03
      }
    },
    mobileSettings: {
      _size: '',
      size:'',
      timeout: 1500,
      floorPrice: {
        floorType: 'RON',
        floorValues: {
          'RON': {
            price: 0.01,
            enabled: true,
          }
        }
      },
      optimizationStrategyCall: false,
      optimizationStrategy: {
        type: 'floor',
        price: 0.03
      }
    }
  })

  const [adUnitData, setAdUnitData] = useState([])
  const [selectedAdUnit, setSelectedAdUnit] = useState([]);

  console.log(props)


  const [desktopFloorPriceValues, setDesktopFloorPriceValues] = useState({
    RON: 0.01,
    ROW: 0.01,
    GCC: 0.01,
    Tier1: 0.01,
    APAC: 0.01,
  })

  const [mobileFloorPriceValues, setMobileFloorPriceValues] = useState({
    RON: 0.01,
    ROW: 0.01,
    GCC: 0.01,
    Tier1: 0.01,
    APAC: 0.01,
  })

  const openCollapsed = (panel) => (event, newExpanded) => {
    console.log(panel)
    setDesktopItemOpen(!panel)
  };

  const openCollapsedMobile = (panel) => (event, newExpanded) => {
    console.log(panel)
    setMobileItemOpen(!panel)
  };

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const body = {
    "url": '',
    "requestBody": {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      name: userInfo.name,
      hB_BuyerID: props.selectedBuyerID
    }
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 1000,
    extendedTimeOut: 1000
  };

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.headerBids.errorMsg,
    successMsg: state.headerBids.successMsg,
  }));

  useEffect(() => {
    console.log(successMsg)
    if (successMsg !== null) {
      console.log(successMsg)
      if (successMsg.success) {
        dispatch(updateAdUnitBidSuccess(""));
        dispatch(updateAdUnitBidFail(null));
        setLoader(false)
        props.closeCanvas('Update')
      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if (errorMsg.status && errorMsg.status === 500) {
      } else {
        dispatch(updateAdUnitBidSuccess(""));
        dispatch(updateAdUnitBidFail(null));
      }
    }
  }, [errorMsg]);


  console.log(errorMsg, successMsg)

  useEffect(() => {
    if (props.selectedHBAdUnit !== '') {
      setIsEdit(true)
      setSelectedAdUnit({ value: props.selectedHBAdUnit.adUnitID, label: props.selectedHBAdUnit.aUnitName })
    }

  }, []);

  useEffect(() => {
  axios.post("/v2.0/demand/HB_Buyers/read/Adunit", body,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is notAssociated headerBids ==>", res)
        if (res.data.data) {
          setAdUnitData(objectModal(res.data.data))
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }, []);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.adUnitID
      obj.label = item.aUnitName
      dataList.push(obj)
    })
    return dataList
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    console.log(adUnitSettingsInfo)
    if (!selectedAdUnit.value) {
      toastr.error('Please Select AdUnit to Associate')
      return false
    }
    if (!targetStates.desktop && !targetStates.mobile) {
      toastr.error('Please Select any one of the Device Setup')
      return false
    }
    let bodyDesktopAdUnitSettings = {}
    let bodyMobileAdUnitSettings = {}
    if (targetStates.desktop) {
      bodyDesktopAdUnitSettings = getBodyData(adUnitSettingsInfo.desktopSettings, desktopFloorPriceValues, desktopSettingsList)
    }
    if (targetStates.mobile) {
      bodyMobileAdUnitSettings = getBodyData(adUnitSettingsInfo.mobileSettings, mobileFloorPriceValues, mobileSettingsList)
    }
    let bodyAdUnitSettings = {
      adUnitID: selectedAdUnit.value,
      adUnitName: selectedAdUnit.label,
      isUpdate: isEdit,
      desktop: bodyDesktopAdUnitSettings,
      mobile: bodyMobileAdUnitSettings
    }
    console.log(bodyAdUnitSettings)
    setLoader(true)
    dispatch(onUpdateHBBuyer({
      ...bodyAdUnitSettings, hB_BuyerID: props.selectedBuyerID,userRole:userInfo.userRole,userID:userInfo.userID 
    }));

  }

  function getBodyData(settingsData, floorPriceValues, settingsList) {
    let partitionValidation = checkBidderData(settingsData)
    if (partitionValidation && selectedAdUnit.value) {
      let floorPriceSets = {}
      let floorPriceSetValues = {}
      let GeoExistValues = {}
      let floorValueforFP = 0.01
      if (settingsData.floorPrice.floorType === 'RON') {
        floorPriceSets = {
          floorType: settingsData.floorPrice.floorType,
          floorValues: {
            "RON": { price: floorPriceValues.RON ? Number(floorPriceValues.RON) : 0.01, enabled: true }
          }
        }
        floorValueforFP = floorPriceValues.RON ? Number(floorPriceValues.RON) : 0.01
      }
      if (settingsData.floorPrice.floorType === 'GeoGroup') {
        floorValueforFP = 0.01
        floorPriceSetValues = { ...floorPriceSetValues, 'ROW': { price: floorPriceValues.ROW ? Number(floorPriceValues.ROW) : 0.01, enabled: floorPriceValues.ROW && (Number(floorPriceValues.ROW) > 0) ? true : false } }
        floorPriceSets = { ...floorPriceSets, floorType: 'GeoGroup', floorValues: floorPriceSetValues }
        GeoExistValues = { ...GeoExistValues, ROW: floorPriceValues.ROW && (Number(floorPriceValues.ROW) > 0) ? true : false }
        floorPriceSetValues = { ...floorPriceSetValues, 'GCC': { price: floorPriceValues.GCC ? Number(floorPriceValues.GCC) : 0.01, enabled: floorPriceValues.GCC && (Number(floorPriceValues.GCC) > 0) ? true : false } }
        floorPriceSets = { ...floorPriceSets, floorType: 'GeoGroup', floorValues: floorPriceSetValues }
        GeoExistValues = { ...GeoExistValues, GCC: floorPriceValues.GCC && (Number(floorPriceValues.GCC) > 0) ? true : false }
        floorPriceSetValues = { ...floorPriceSetValues, 'Tier1': { price: floorPriceValues.Tier1 ? Number(floorPriceValues.Tier1) : 0.01, enabled: floorPriceValues.Tier1 && (Number(floorPriceValues.Tier1) > 0) ? true : false } }
        floorPriceSets = { ...floorPriceSets, floorType: 'GeoGroup', floorValues: floorPriceSetValues }
        GeoExistValues = { ...GeoExistValues, Tier1: floorPriceValues.Tier1 && (Number(floorPriceValues.Tier1) > 0) ? true : false }
        floorPriceSetValues = { ...floorPriceSetValues, 'APAC': { price: floorPriceValues.APAC ? Number(floorPriceValues.APAC) : 0.01, enabled: floorPriceValues.APAC && (Number(floorPriceValues.APAC) > 0) ? true : false } }
        floorPriceSets = { ...floorPriceSets, floorType: 'GeoGroup', floorValues: floorPriceSetValues }
        GeoExistValues = { ...GeoExistValues, APAC: floorPriceValues.APAC && (Number(floorPriceValues.APAC) > 0) ? true : false }
      }
      if (settingsData.floorPrice.floorType === 'GeoGroup') {
        let notExistValue = Object.values(GeoExistValues).includes(false)
        if (!notExistValue) {
          let bodyAdUnitSettings = {
            floorPrice: floorPriceSets,
            _size: settingsData._size,
            size:settingsData.size,
            timeout: Number(settingsData.timeout),
            adUnitSettings: manipulateData(settingsList),
            fp: floorValueforFP,
            optimizationStrategyCall: settingsData.optimizationStrategyCall,
            optimizationStrategy: {
              type: settingsData.optimizationStrategy.type,
              price: Number(settingsData.optimizationStrategy.price)
            },
          }
          console.log(bodyAdUnitSettings)
          return bodyAdUnitSettings
        } else {
          toastr.error('Please Enter Price for GeoGroup')
          return false
        }
      } else {
        if (floorPriceValues.RON && (Number(floorPriceValues.RON) > 0)) {
          let bodyAdUnitSettings = {
            floorPrice: floorPriceSets,
            _size: settingsData._size,
            size:settingsData.size,
            timeout: Number(settingsData.timeout),
            adUnitSettings: manipulateData(settingsList),
            fp: floorValueforFP,
            optimizationStrategyCall: settingsData.optimizationStrategyCall,
            optimizationStrategy: {
              type: settingsData.optimizationStrategy.type,
              price: Number(settingsData.optimizationStrategy.price)
            }
          }
          console.log(bodyAdUnitSettings)
          return bodyAdUnitSettings
        } else {
          toastr.error('Please Enter Price for RON')
          return false
        }
      }

    }
  }

  function manipulateData(settingsList) {
    let videoContentListforAdUnit = settingsList
    videoContentListforAdUnit.map((item, i) => {
      let setsObject = {}
      if (item.settingInfo && item.settingInfo.length > 0) {
        item.settingInfo.map((elem, j) => {
          if (elem.value) {
            setsObject = { ...setsObject, [elem.ParameterName]: elem.value }
          }
        })
        videoContentListforAdUnit[i].settingInfo = setsObject
      }
    })
    console.log(videoContentListforAdUnit)
    return videoContentListforAdUnit
  }

  const handleSelectAdUnit = adUnit => {
    setSelectedAdUnit(adUnit);
  }

  function checkBidderData(settingsData) {
    if (!selectedAdUnit.value) {
      toastr.error('Please Select AdUnit to Associate')
      return false
    }
    else if (settingsData.timeout && (Number(settingsData.timeout) < 1000)) {
      toastr.error('Please Enter Bidder Timeout greater than 1000 ms')
      return false
    }
    else if (!settingsData.timeout) {
      toastr.error('Please Enter Bidder Timeout')
      return false
    }
    else {
      return true
    }
  }

  const getDesktopSettingData = async (SettingData) => {
    console.log(SettingData)
    setAdUnitSettingsInfo(adUnitSettingsInfo => ({
      ...adUnitSettingsInfo, desktopSettings: {
        ...adUnitSettingsInfo.desktopSettings, ...SettingData.desktopSettings
      }
    }))
  }

  const getMobileSettingData = async (SettingData) => {
    setAdUnitSettingsInfo(adUnitSettingsInfo => ({
      ...adUnitSettingsInfo, mobileSettings: {
        ...adUnitSettingsInfo.mobileSettings, ...SettingData.mobileSettings
      }
    }))
  }

  const getDesktopFloorData = async (SettingData) => {
    setDesktopFloorPriceValues(desktopFloorPriceValues => ({
      ...desktopFloorPriceValues, ...SettingData.floorPriceValues
    }))
  }

  const getMobileFloorData = async (SettingData) => {
    setMobileFloorPriceValues(mobileFloorPriceValues => ({
      ...mobileFloorPriceValues, ...SettingData.floorPriceValues
    }))
  }

  const getDesktopSettingsListData = async (SettingData) => {
    setDesktopSettingsList(SettingData.settingsInfo)
  }

  const getMobileSettingsListData = async (SettingData) => {
    setMobileSettingsList(SettingData.settingsInfo)
  }

  const handleCheck = e => {
    const { name, value } = e.target;
    let settings = {
      _size: '',
      size:'',
      timeout: 1500,
      floorPrice: {
        floorType: 'RON',
        floorValues: {
          'RON': {
            price: 0.01,
            enabled: true,
          }
        }
      },
      optimizationStrategyCall: false,
      optimizationStrategy: {
        type: 'floor',
        price: 0.03
      }
    }
    setTargetStates(targetStates => ({ ...targetStates, [name]: e.target.checked }));
    if (name === 'desktop' && e.target.checked) {
      if (!targetStates.mobile) {
        setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, desktopSettings: settings, mobileSettings: {} }));
      } else {
        setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, desktopSettings: settings }));
      }
    }
    if (name === 'mobile' && e.target.checked) {
      if (!targetStates.desktop) {
        setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, desktopSettings: {}, mobileSettings: settings }));
      } else {
        setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, mobileSettings: settings }));
      }
    }
  }



  useEffect(() => {
    if (props.selectedHBAdUnit !== '') {
      setIsEdit(true)
      let buyerID = props.selectedBuyerID ? props.selectedBuyerID : props.selectedHBBuyer.HB_BuyerID
      const bodyforAdUnit = {
          userID: userInfo.userID,
          userRole: userInfo.userRole,
          companyID: userInfo.companyID,
          name: userInfo.name,
          adUnitID: props.selectedHBAdUnit ? props.selectedHBAdUnit.adUnitID : parsed.adUnitID
      }
      axios.post("/v2.0/demand/HB_Buyers/getAssociateAdUnit/" + buyerID, bodyforAdUnit,
        {
          headers: headers
        })
        .then((res) => {
          console.log("result is notAssociated headerBids ==>", res)
          if (res.data.response) {
            console.log(res.data.response)
            if (JSON.stringify(res.data.response.desktop) !== "{}") {
              setDesktopItemOpen(true)
              setTargetStates(targetStates => ({ ...targetStates, desktop: true }));
            }
            if (JSON.stringify(res.data.response.mobile) !== "{}") {
              setMobileItemOpen(true)
              setTargetStates(targetStates => ({ ...targetStates, mobile: true }));
            }
          }
        })
        .catch((err) => {
          console.log("e", err)
        });
    }
  }, []);




  return (
    <React.Fragment>
      {loader &&
        <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
        <Form onSubmit={handleSubmit}  >
          <Row className="formBodyScroll" style={{ padding: '3%', height: '85vh', overflow: 'auto', width: '100%' }}>
            <Col xl={12} className="mb-4">
              <Row>
                <Col lg="12" className="p-2">
                  <div className="content clearfix ">
                    <h5>HB Buyer : {parsed.buyerName}</h5> &nbsp;&nbsp;
                    <div className="mb-3">
                      <Label className="form-label">Associate AdUnits </Label>
                      <Select
                        value={selectedAdUnit}
                        onChange={s => {
                          handleSelectAdUnit(s)
                        }}
                        options={adUnitData}
                        classNamePrefix="select2-selection "
                        className="col-12"
                        isDisabled={isEdit}
                      />
                    </div>
                    <div className="form-group d-flex mt-1">
                      <input type="checkbox" className="form-check-input  p-0 mt-1 " name="desktop" id='desktop' checked={targetStates.desktop} onChange={handleCheck} />
                      <Label className='m-3 mt-0 ' htmlFor='desktop'>Desktop Setup</Label>
                    </div>
                    <div className="mb-3">
                      <div className="adUnitSettings-settings" >
                        <div className="accordion-item ml-0 mr-0 mb-4 ">
                          <h2 className="accordion-header row m-0" id="headingOne">
                            <button
                              className={classnames(
                                "accordion-button",
                                "fw-medium", { collapsed: desktopItemOpen }
                              )}
                              type="button"
                              onClick={openCollapsed(desktopItemOpen)}
                              style={{ cursor: "pointer", padding: '0.5% 2%', color: !targetStates.desktop ? 'black' : '#4d63cf', backgroundColor: !targetStates.desktop ? 'white' : '#eef1fd' }}
                              disabled={!targetStates.desktop}
                            >
                              <Label className="form-label mt-2">Desktop Setup </Label>
                            </button>
                          </h2>
                          <Collapse isOpen={desktopItemOpen} className="accordion-collapse">
                            <div className="accordion-body">
                              <DevicewiseAssociate selectedDevice={'Desktop'} selectedDesktopSettingsData={getDesktopSettingsListData} selectedDesktopFloorValues={getDesktopFloorData} selectedDesktopItems={getDesktopSettingData} selectedBuyerID={props.selectedBuyerID} selectedBidder={props.selectedBidder} selectedHBAdUnit={props.selectedHBAdUnit} selectedHBBuyer="" />
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                    <div className="form-group d-flex mt-1">
                      <input type="checkbox" className="form-check-input  p-0 mt-1" name="mobile" id='mobile' checked={targetStates.mobile} onChange={handleCheck} />
                      <Label className='m-3 mt-0 ' htmlFor='mobile'>Mobile Setup</Label>
                    </div>
                    <div className="mb-3">
                      <div className="adUnitSettings-settings" >
                        <div className="accordion-item ml-0 mr-0 mb-4 ">
                          <h2 className="accordion-header row m-0" id="headingOne">
                            <button
                              className={classnames(
                                "accordion-button",
                                "fw-medium", { collapsed: mobileItemOpen }
                              )}
                              type="button"
                              onClick={openCollapsedMobile(mobileItemOpen)}
                              style={{ cursor: "pointer", padding: '0.5% 2%', color: !targetStates.mobile ? 'black' : '#4d63cf', backgroundColor: !targetStates.mobile ? 'white' : '#eef1fd' }}
                              disabled={!targetStates.mobile}
                            >
                              <Label className="form-label mt-2">Mobile Setup </Label>
                            </button>
                          </h2>
                          <Collapse isOpen={mobileItemOpen} className="accordion-collapse">
                            <div className="accordion-body">
                              <MobilewiseAssociate selectedDevice={'Mobile'} selectedMobileSettingsData={getMobileSettingsListData} selectedMobileFloorValues={getMobileFloorData} selectedMobileItems={getMobileSettingData} selectedBuyerID={props.selectedBuyerID} selectedBidder={props.selectedBidder} selectedHBAdUnit={props.selectedHBAdUnit} selectedHBBuyer="" />
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">


                {isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }

                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>

              </div>
            </Col>
          </Row>
        </Form>
        {/* {
          errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
          <Toaster status="error" msg={errorMsg.message} />
        } */}
      </div >
      {/* {
        errorMsg !== null && errorMsg !== '' && errorMsg.status && errorMsg.status === 500 &&
        <div className="p-0 justify-content-center d-flex">
          <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')} />
        </div>
      } */}
    </React.Fragment >
  )
}

AssociateAdUnits.propTypes = {}

export default AssociateAdUnits

