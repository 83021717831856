/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import MetaTags from 'react-meta-tags';
import Breadcrumb from "components/Common/Breadcrumb";
import { Container, Button, Card, CardBody, Form, Label, Input, FormGroup, Row, Col, Offcanvas,BreadcrumbItem, OffcanvasHeader, OffcanvasBody, } from 'reactstrap';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";


import New_Support_Ticket from './New_Support _Ticket';


//Bootstrap Table
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import { Type } from 'react-bootstrap-table2-editor';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';


import Support_Ticket_Chat from './Support_Ticket_Chat';

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import queryString from 'query-string';

//redux

import { useSelector, useDispatch } from 'react-redux';

import { getSupportTicketAdmins as onGetSupportTicketsAdmins } from 'store/Support_Ticket/Admin_Ticket/Admin_Support/actions'

import LoadingTable from 'pages/LoaderPages/LoadingTable';
import AdminSidebar from '../Admin_Sidebar/AdminSidebar';

const Support_Tickets = props => {
  const [isSupportTicketsOpen, setIsSupportTicketsOpen] = useState(false)
  const [specificNotification, setSpecificNotification] = useState(false)
  const [notificationData, setNotificationData] = useState([])
  const [switchLoader, setSwitchLoader] = useState(true)
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch()

  const emptyRef = useRef();
  const parsed = queryString.parse(window.location.search);

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setIsSupportTicketsOpen(false)
      setSpecificNotification(false)
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };


  useEffect(() => {
    if (parsed.success) {
      toastr.success('Support Ticket Created Successfully')
      emptyRef.current.click()
    }

 

  }, [specificNotification]);

  const { supportTicketAdminList } = useSelector(state => ({
    supportTicketAdminList: state.supportTicketAdmins.supportTicketAdminList
  }))

  console.log(supportTicketAdminList)

  const { loading } = useSelector(state => ({
    loading: state.supportTicketAdmins.loading,
  }));


  useEffect(() => {
    // console.log(loading)
    if (loading) {
      setSwitchLoader(true)
    }

  }, [loading]);


  useEffect(() => {
    let userDetails = {
      userID: userInfo.userID
    }
    if (supportTicketAdminList !== null) {
      // loader = true
      dispatch(onGetSupportTicketsAdmins(userDetails))
    }
  }, [specificNotification]);


  const supportTicketsColumnsList = [{
    text: "ID",
 headerClasses:"ID",
    dataField: "id",
    sort: true,
    hidden: true,
    // eslint-disable-next-line react/display-name
    formatter: user => <>{user.id}</>,
  }, {
    dataField: 'supportTicketID',
    sort: true,
    text: "ID"
  },
  {
    dataField: 'Author',
    text: "Partner"
  },
  {
    dataField: 'supportTicketSubject',
    text: "Title"
  },
  {
    dataField: 'supportTicketCreateTime',
    text: "Created At",
    formatter: (cell, row) => {
      const timestamp = new Date(cell);
      const day = timestamp.getDate();
      const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(timestamp);
      const year = timestamp.getFullYear();
      let hours = timestamp.getHours();
      let minutes = timestamp.getMinutes();
      let amOrPm = "AM";

      if (hours >= 12) {
        amOrPm = "PM";
        if (hours > 12) {
          hours -= 12;
        }
      }

      hours = String(hours).padStart(2, '0');
      minutes = String(minutes).padStart(2, '0');

      return `${day} ${month} ${year} ${hours}:${minutes} ${amOrPm}`;
    }
  },
  // {
  //   dataField: 'lastReplied',
  //   text: "Last Replied",
  //   formatter: (cell, row) => {
  //     const timestamp = new Date(cell);
  //     const day = timestamp.getDate();
  //     const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(timestamp);
  //     const year = timestamp.getFullYear();
  //     let hours = timestamp.getHours();
  //     let minutes = timestamp.getMinutes();
  //     let amOrPm = "AM";

  //     if (hours >= 12) {
  //       amOrPm = "PM";
  //       if (hours > 12) {
  //         hours -= 12;
  //       }
  //     }

  //     hours = String(hours).padStart(2, '0');
  //     minutes = String(minutes).padStart(2, '0');

  //     return `${day} ${month} ${year} ${hours}:${minutes} ${amOrPm}`;
  //   }
  // },
  {
    dataField: 'typeOfSupportPriority',
    text: "Priority",
    formatter: (cellContent, role) => (
      <>
        {role.typeOfSupportPriority === "urgent" &&
          <span className="support-ticket-urgent">Urgent</span>
        }
        {role.typeOfSupportPriority === "high" &&
          <span className="support-ticket-high">High</span>
        }
        {role.typeOfSupportPriority === "medium" &&
          <span className="support-ticket-medium">Medium</span>
        }
        {role.typeOfSupportPriority === "low" &&
          <span className="support-ticket-low">Low</span>
        }
      </>
    ),
  },

  // {
  //   dataField: 'supportTicketCreateTime',
  //   text: "CREATED"
  // },
  // {
  //   dataField: 'lastReplied',
  //   text: "LAST REPLY"
  // },
  {
    dataField: 'supportTicketStatus',
    text: "Status",
    formatter: (cellContent, role) => (
      <>
        {role.supportTicketStatus === "open" &&
          <span className="active-state">Active</span>
        }
        {role.supportTicketStatus === "closed" &&
          <span className="inactive-state-ticket">Closed</span>
        }
        {role.supportTicketStatus === "resolved" &&
          <span className="resolved-state">Resolved</span>
        }
      </>
    ),
  }, {
    dataField: "",
    isDummyField: true,
    editable: false,
    text: "Action",
    align: 'center',

    formatter: (cellContent, row) => (
      <div className="d-flex gap-3">
        <Link to={'#'} onClick={() => {
          setNotificationData(row)
          setSpecificNotification(true)
        }}>
          <u>View Chat</u>
        </Link>

      </div>
    ),
  }
  ]

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const keyField = "id";


  const toggleCreateSupportTickets = () => {
    setIsSupportTicketsOpen(!isSupportTicketsOpen)
  }

  const toggleSpecificNotification = () => {
    setSpecificNotification(!specificNotification)
  }
  // const rowEvents = {
  //   onClick: (e, row) => {
  //     setNotificationData(row)
  //     setSpecificNotification(true)
  //   }
  // } 
  return (
    <>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
         {/* <AdminSidebar />
      <div className="main-content access-management"> */}
      <div className="page-content">
        <MetaTags>
          <title>Support Tickets | Bidsxchange</title>
        </MetaTags>
        <Container fluid={true}>
          <div className=" d-sm-flex  justify-content-between">
            <Breadcrumb title="Support Tickets" breadcrumbItem="Support Tickets" breadList="All Support Tickets" />
            <div className=" text-sm-end d-none">
              <Button
                color="primary"
                className="font-16 btn-block btn btn-secondary"
                onClick={toggleCreateSupportTickets}
                disabled
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create Ticket
              </Button>
            </div>
          </div>
          {/* <Card>
                        <CardBody aria-hidden="true">
                        <Form>
                            <Row className="row-cols-lg-auto g-3 align-items-center">
                              <Col>
                                <h5 className='mt-1'>Filters:</h5>
                              </Col>
                              <Col>
                                <Label
                                  className="visually-hidden"
                                  for="notificationId"
                                >
                                  Filters
                                </Label>
                                <Input
                                  id="notificationId"
                                  name="id"
                                  placeholder="ID:"
                                  type="text"
                                />
                              </Col>
                              
                            </Row>
                          </Form>
                        </CardBody>
                      </Card> */}
          {switchLoader &&
            <LoadingTable loading={loading} targetLoading={handleSwitch} />
          }
          {
            !switchLoader &&

            <Row >
              <Col lg="12">
                <Card>
                  <CardBody aria-hidden="true">
                          <ToolkitProvider
                            keyField={keyField}
                            columns={supportTicketsColumnsList}
                            data={supportTicketAdminList}
                            // bootstrap4
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row>
                                  <Col xl="12" className="table-responsive">
                                    <div >
                                      <BootstrapTable
                                        keyField={keyField}
                                        headerWrapperClasses={"thead-light"}
                                        defaultSorted={defaultSorted}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        hover
                                        //rowEvents={rowEvents}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        {...toolkitProps.baseProps}
                                        pagination={paginationFactory(pageOptions)}
                                        noDataIndication={<h5 className="text-center">No Data</h5>}
                                      // ref={node}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
        </Container>
      </div>
      {/* </div> */}
      {/* </div> */}
      <Offcanvas isOpen={isSupportTicketsOpen} direction='end'  >
        <OffcanvasHeader toggle={toggleCreateSupportTickets}>
          {'Add Support Tickets'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <New_Support_Ticket toggleCreateSupportTickets={toggleCreateSupportTickets} />
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas isOpen={specificNotification} direction='end'  >
        <OffcanvasHeader toggle={toggleSpecificNotification}>
          {`Ticket #${notificationData.supportTicketID}`}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <Support_Ticket_Chat notificationData={notificationData} toggleSpecificNotification={toggleSpecificNotification} />
        </OffcanvasBody>
      </Offcanvas>

    </>
  )
}

Support_Tickets.propTypes = {}

export default Support_Tickets