/* eslint-disable react/prop-types */
import React, { useState, useRef,useEffect } from 'react';
import {
  Row, Col, Label, Form, Input
} from "reactstrap";
import { MultiSelect } from "react-multi-select-component";
import axios from "axios";
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import _ from "lodash";


const GeoPlayer = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));

  const [geoData, setGeoData] = useState({
    countriesIn: [],
    countriesEx: [],
    citiesIn: [],
    citiesEx: [],
  })

  const [value, setValue] = useState([]);
  const [cityData, setCityData] = useState([])
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);

  const [countriesState, setCountriesState] = useState(true);
  const [citiesState, setCitiesState] = useState(true);
  const [countryData, setCountryData] = useState([]);
  const [countriesData, setCountriesData] = useState([])

  const [treeSelect, setTreeSelect] = useState({
    checked: [],
    expanded: [],
    keyword: ''
  })

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const toggleOptions = () => {
    setTreeSelect(treeSelect => ({ ...treeSelect, keyword: '' }));
    setIsOptionsOpen(!isOptionsOpen);
  };

  const myRef = useRef();
  const cityRef = useRef();

  const handleClickOutside = e => {
    if (!myRef.current.contains(e.target)) {
      setTreeSelect(treeSelect => ({ ...treeSelect, keyword: '' }));
      setIsOptionsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const [treeSelectCity, setTreeSelectCity] = useState({
    checked: [],
    expanded: [],
    keyword: ''
  })
  const [isOptionsOpenCity, setIsOptionsOpenCity] = useState(false);

  const handleClickCityOutside = e => {
    if (!cityRef.current.contains(e.target)) {
      // setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, keyword: '' }));
      setIsOptionsOpenCity(false);
    }
  };

  const toggleOptionsCity = () => {
    setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, keyword: '' }));
    if(treeSelect.checked && treeSelect.checked.length > 0){
      setIsOptionsOpenCity(!isOptionsOpenCity);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickCityOutside);
    return () => document.removeEventListener("mousedown", handleClickCityOutside);
  });


  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const body = {
    "url": '',
    "requestBody": {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      name: userInfo.name,
    }
  }

  // console.log(props)



  useEffect(() => {
    if (props.tabId !== 6) {
      setIsOptionsOpen(false);
    }
  }, [props.tabId])

  useEffect(() => {
    body.url = "http://127.0.0.1:2002/v1.0/regions/list"
    axios.post("/v1.0/connectReportAPI", body,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is ==>", res)
        if (res.data) {
          let trees = [
            createTree("Africa", res.data.Africa),
            createTree("America", res.data.Americas),
            createTree("Asia", res.data.Asia),
            createTree("Europe", res.data.Europe),
            createTree("GDPR", res.data.GDPR),
            createTree("Oceania", res.data.Oceania),
          ];
          setCountryData(trees)
          setCountriesData([...res.data.Africa, ...res.data.Americas, ...res.data.Asia, ...res.data.Europe, ...res.data.GDPR, ...res.data.Oceania])
          if (props.selectedPlayer !== '') {
            if(props.selectedPlayer.countriesIn.length > 0 || props.selectedPlayer.countriesEx.length > 0 ){
              getCities(props.selectedPlayer.countriesIn.length > 0 ? props.selectedPlayer.countriesIn : props.selectedPlayer.countriesEx.length > 0 ? props.selectedPlayer.countriesEx : [],[...res.data.Africa, ...res.data.Americas, ...res.data.Asia, ...res.data.Europe, ...res.data.GDPR, ...res.data.Oceania])
            }
          }
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }, []);

  const getCities = (countries,countryData) => {
    setCityData([])
    let countryList = []
    var array3 = countryData.filter(function (obj) {
      return countries.indexOf(obj.value) !== -1
    });
    array3.map((item, index) => {
      countryList.push(item.label)
    })
    const bodyCity = {
      "url": "http://127.0.0.1:2002/v1.0/cities/list/countrylist",
      "requestBody": {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name: userInfo.name,
        "countrylist": countryList
      }
    }
    axios.post("/v1.0/connectReportAPI", bodyCity,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is cities ==>", res)
        if (res.data) {
          let trees = []
          if (res.data.response.length > 0) {
            res.data.response.map((item, i) => {
              trees.push(createCityTree(item.label, item.value))
            })
          }
          console.log(trees)
          setCityData(trees)
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const createTree = (groupName, options) => {
    return {
      label: groupName,
      value: groupName,
      children: options,
    };
  };

  const createCityTree = (groupName, options) => {
    return {
      label: groupName,
      value: groupName,
      children: objectModalCity(options)
    };
  };

  const objectModalCity = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }

  useEffect(() => {
    if (props.selectedPlayer !== '') {
      console.log(props.selectedPlayer)
      setSelectedRegion(props.selectedPlayer.countriesIn.length > 0 ? objectModals(props.selectedPlayer.countriesIn) : props.selectedPlayer.countriesEx.length > 0 ? objectModals(props.selectedPlayer.countriesEx) : [])
      setTreeSelect(treeSelect => ({ ...treeSelect, checked: props.selectedPlayer.countriesIn.length > 0 ? props.selectedPlayer.countriesIn : props.selectedPlayer.countriesEx.length > 0 ? props.selectedPlayer.countriesEx : [] }));
      setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: props.selectedPlayer.citiesIn.length > 0 ? props.selectedPlayer.citiesIn : props.selectedPlayer.citiesEx.length > 0 ? props.selectedPlayer.citiesEx : [] }));
      setSelectedCity(props.selectedPlayer.citiesIn.length > 0 ? objectModals(props.selectedPlayer.citiesIn) : props.selectedPlayer.citiesEx.length > 0 ? objectModals(props.selectedPlayer.citiesEx) : [])

      setCountriesState(props.selectedPlayer.countriesIn.length > 0 ? true : props.selectedPlayer.countriesEx.length > 0 ? false : true)
      setCitiesState(props.selectedPlayer.citiesIn.length > 0 ? true : props.selectedPlayer.citiesEx.length > 0 ? false : true)

    }
  }, []);

  // jQuerycode = () => {
  //   $(".button").click(function(){
  //    $('p').css('color', 'red')

  //   });
  // }

  const objectModals = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }

  const handleSelectRegion = country => (value) => {
    console.log(country)
    setValue(value.concat(options.filter((grpOpt) => !value.includes(grpOpt))));
    setValue(country)
    setSelectedRegion(country);
    var countryList = []
    country.map((item, index) => {
      countryList.push(item.value)
    })
    countriesState ? setGeoData(geoData => ({ ...geoData, countriesIn: countryList })) : setGeoData(geoData => ({ ...geoData, countriesEx: countryList }))
    countriesState ? props.selectedItems({ countriesIn: countryList }) : props.selectedItems({ countriesEx: countryList })
  }

  const handleSelectCity = city => {
    setSelectedCity(city);
    var citiesLists = []
    city.map((item, index) => {
      citiesLists.push(item.value)
    })
    citiesState ? setGeoData(geoData => ({ ...geoData, citiesIn: citiesLists })) : setGeoData(geoData => ({ ...geoData, citiesEx: citiesLists }))
    citiesState ? props.selectedItems({ citiesIn: citiesLists }) : props.selectedItems({ citiesEx: citiesLists })
  }


  const countryStateChange = (e) => {
    setCountriesState(e.target.checked)
    var countriess = []
    if (geoData.countriesIn.length > 0) {
      countriess = geoData.countriesIn
    } else {
      countriess = geoData.countriesEx
    }
    if (e.target.checked) {
      setGeoData(geoData => ({ ...geoData, countriesIn: countriess, countriesEx: [] }));
      props.selectedItems({ countriesIn: countriess, countriesEx: [] })
    } else {
      setGeoData(geoData => ({ ...geoData, countriesEx: countriess, countriesIn: [] }));
      props.selectedItems({ countriesEx: countriess, countriesIn: [] })
    }
  }

  const cityStateChange = (e) => {
    setCitiesState(e.target.checked)
    var citiess = []
    if (geoData.citiesIn.length > 0) {
      citiess = geoData.citiesIn
    } else {
      citiess = geoData.citiesEx
    }
    if (e.target.checked) {
      setGeoData(geoData => ({ ...geoData, citiesIn: citiess, citiesEx: [] }));
      props.selectedItems({ citiesIn: citiess, citiesEx: [] })
    } else {
      setGeoData(geoData => ({ ...geoData, citiesEx: citiess, citiesIn: [] }));
      props.selectedItems({ citiesEx: citiess, citiesIn: [] })
    }
  }


  const groupSelect = (options) => (e) => {
    console.log(e.target.checked)
    console.log(options, value)
    if (e.target.checked) {
      var countryList = []
      options.map((item, index) => {
        countryList.push(item.value)
      })
      countriesState ? setGeoData(geoData => ({ ...geoData, countriesIn: countryList })) : setGeoData(geoData => ({ ...geoData, countriesEx: countryList }))
      countriesState ? props.selectedItems({ countriesIn: countryList }) : props.selectedItems({ countriesEx: countryList })
      let arr = value
      let arr2 = []
      options.map((item, index) => {
        arr.push(item)
      })
      const ids = arr.map(o => o.value)
      const filtered = arr.filter(({ value }, index) => !ids.includes(value, index + 1))
      setValue(filtered);
    } else {
      console.log(value)
      let myArray = value.filter(ar => !options.find(rm => (rm.label === ar.label && ar.value === rm.value)))
      setValue(myArray)
      console.log(myArray)
    }
  }

  const onSearchInputChange = (event, data, searchedNodes) => {
    console.log(event, data, searchedNodes)
    setTreeSelect(treeSelect => {
      if (treeSelect.keyword.trim() && !data.value.trim()) {
        return {
          ...treeSelect,
          expanded: [],
          keyword: data.value
        };
      }
      return {
        ...treeSelect,
        expanded: getAllValuesFromNodes(searchedNodes, true),
        keyword: data.value
      };
    });
  };

  const onSearchInputCityChange = (event, data, searchedNodes) => {
    console.log(event, data, searchedNodes)
    setTreeSelectCity(treeSelectCity => {
      if (treeSelectCity.keyword.trim() && !data.value.trim()) {
        return {
          ...treeSelectCity,
          expanded: [],
          keyword: data.value
        };
      }
      return {
        ...treeSelectCity,
        expanded: getAllValuesFromNodes(searchedNodes, true),
        keyword: data.value
      };
    });
  };

  const getAllValuesFromNodes = (nodes, firstLevel) => {
    if (firstLevel) {
      const values = [];
      for (let n of nodes) {
        values.push(n.value);
        if (n.children) {
          values.push(...getAllValuesFromNodes(n.children, false));
        }
      }
      return values;
    } else {
      const values = [];
      for (let n of nodes) {
        values.push(n.value);
        if (n.children) {
          values.push(...getAllValuesFromNodes(n.children, false));
        }
      }
      return values;
    }
  };

  const keywordFilter = (nodes, keyword) => {
    let newNodes = [];
    for (let n of nodes) {
      if (n.children) {
        const nextNodes = keywordFilter(n.children, keyword);
        if (nextNodes.length > 0) {
          n.children = nextNodes;
        } else if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
          n.children = nextNodes.length > 0 ? nextNodes : [];
        }
        if (
          nextNodes.length > 0 ||
          n.label.toLowerCase().includes(keyword.toLowerCase())
        ) {
          n.label = getHighlightText(n.label, keyword);
          newNodes.push(n);
        }
      } else {
        if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
          n.label = getHighlightText(n.label, keyword);
          newNodes.push(n);
        }
      }
    }
    if(newNodes.length === 1){
      let regions = countryData
      regions.map((item,index)=> {
        if(item.value.toLowerCase() === newNodes[0].value.toLowerCase()){
          newNodes[0].children = item.children
        }
      })
    }
    return newNodes;
  };

  const keywordFilterCity = (nodes, keyword) => {
    let newNodes = [];
    for (let n of nodes) {
      if (n.children) {
        const nextNodes = keywordFilterCity(n.children, keyword);
        if (nextNodes.length > 0) {
          n.children = nextNodes;
        } else if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
          n.children = nextNodes.length > 0 ? nextNodes : [];
        }
        if (
          nextNodes.length > 0 ||
          n.label.toLowerCase().includes(keyword.toLowerCase())
        ) {
          n.label = getHighlightText(n.label, keyword);
          newNodes.push(n);
        }
      } else {
        if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
          n.label = getHighlightText(n.label, keyword);
          newNodes.push(n);
        }
      }
    }
    if(newNodes.length === 1){
      let countries = cityData
      countries.map((item,index)=> {
        if(item.value.toLowerCase() === newNodes[0].value.toLowerCase()){
          newNodes[0].children = item.children
        }
      })
    }
    return newNodes;
  };

  const getHighlightText = (text, keyword) => {
    console.log(text, keyword)
    const startIndex = text.indexOf(keyword);
    return startIndex !== -1 ? (
      <span>
        {text.substring(0, startIndex)}
        <span style={{ color: "red" }}>
          {text.substring(startIndex, startIndex + keyword.length)}
        </span>
        {text.substring(startIndex + keyword.length)}
      </span>
    ) : (
      <span>{text}</span>
    );
  };

  const checkValue = (row,checked) => {
    if(row.isParent){
      if (row.checked){
        let finalChecked = [...treeSelect.checked,...checked]
        finalChecked = finalChecked.filter((item,index) => finalChecked.indexOf(item) === index)
        setTreeSelect(treeSelect => ({ ...treeSelect, checked:finalChecked}));
        countriesState ? setGeoData(geoData => ({ ...geoData, countriesIn: finalChecked })) : setGeoData(geoData => ({ ...geoData, countriesEx: finalChecked }));
        countriesState ? props.selectedItems({ countriesIn: finalChecked }) : props.selectedItems({ countriesEx: finalChecked })
        if(finalChecked.length > 0){
          getCities(finalChecked,countriesData)
        }
      }else{
        let finalChecked = [...treeSelect.checked]
        let filteredChildren = row.children.map(a => a.value);
        let result  = finalChecked.filter(val => !filteredChildren.includes(val));
        setTreeSelect(treeSelect => ({ ...treeSelect, checked:result}));
        countriesState ? setGeoData(geoData => ({ ...geoData, countriesIn: result })) : setGeoData(geoData => ({ ...geoData, countriesEx: result }));
        countriesState ? props.selectedItems({ countriesIn: result }) : props.selectedItems({ countriesEx: result })
        if(result.length > 0){
          getCities(result,countriesData)
        }
      }
    }else{
      if (row.checked){
        let finalChecked = [...treeSelect.checked,...checked]
        finalChecked = finalChecked.filter((item,index) => finalChecked.indexOf(item) === index)
        setTreeSelect(treeSelect => ({ ...treeSelect, checked:finalChecked}));
        countriesState ? setGeoData(geoData => ({ ...geoData, countriesIn: finalChecked })) : setGeoData(geoData => ({ ...geoData, countriesEx: finalChecked }));
        countriesState ? props.selectedItems({ countriesIn: finalChecked }) : props.selectedItems({ countriesEx: finalChecked })
        if(finalChecked.length > 0){
          getCities(finalChecked,countriesData)
        }
      }else{
        let finalChecked = [...treeSelect.checked]
        let index = finalChecked.indexOf(row.value)
        finalChecked.splice(index,1)
        setTreeSelect(treeSelect => ({ ...treeSelect, checked:finalChecked}));
        countriesState ? setGeoData(geoData => ({ ...geoData, countriesIn: finalChecked })) : setGeoData(geoData => ({ ...geoData, countriesEx: finalChecked }));
        countriesState ? props.selectedItems({ countriesIn: finalChecked }) : props.selectedItems({ countriesEx: finalChecked })
        if(finalChecked.length > 0){
          getCities(finalChecked,countriesData)
        }
      }
    }
}

const checkCityValue = (row,checked) => {
  if(row.isParent){
    if (row.checked){
      let finalChecked = [...treeSelectCity.checked,...checked]
      finalChecked = finalChecked.filter((item,index) => finalChecked.indexOf(item) === index)
      setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked:finalChecked}));
      citiesState ? setGeoData(geoData => ({ ...geoData, citiesIn: finalChecked })) : setGeoData(geoData => ({ ...geoData, citiesEx: finalChecked }));
      citiesState ? props.selectedItems({ citiesIn: finalChecked }) : props.selectedItems({ citiesEx: finalChecked })
    }else{
      let finalChecked = [...treeSelectCity.checked]
      let filteredChildren = row.children.map(a => a.value);
      let result  = finalChecked.filter(val => !filteredChildren.includes(val));
      setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked:result}));
      citiesState ? setGeoData(geoData => ({ ...geoData, citiesIn: result })) : setGeoData(geoData => ({ ...geoData, citiesEx: result }));
      citiesState ? props.selectedItems({ citiesIn: result }) : props.selectedItems({ citiesEx: result })
    }
  }else{
    if (row.checked){
      let finalChecked = [...treeSelectCity.checked,...checked]
      finalChecked = finalChecked.filter((item,index) => finalChecked.indexOf(item) === index)
      setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked:finalChecked}));
      citiesState ? setGeoData(geoData => ({ ...geoData, citiesIn: finalChecked })) : setGeoData(geoData => ({ ...geoData, citiesEx: finalChecked }));
      citiesState ? props.selectedItems({ citiesIn: finalChecked }) : props.selectedItems({ citiesEx: finalChecked })
    }else{
      let finalChecked = [...treeSelectCity.checked]
      let index = finalChecked.indexOf(row.value)
      finalChecked.splice(index,1)
      setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked:finalChecked}));
      citiesState ? setGeoData(geoData => ({ ...geoData, citiesIn: finalChecked })) : setGeoData(geoData => ({ ...geoData, citiesEx: finalChecked }));
      citiesState ? props.selectedItems({ citiesIn: finalChecked }) : props.selectedItems({ citiesEx: finalChecked })
    }
  }
}

  return (
    <Row>
      <Col lg="7">
        <div className="mb-3">
          <Label>Country Targeting</Label>
          <div className='row checkbox-tree rmsc'>
            <div className='col-8' ref={myRef}>
              <button
                type="button"
                aria-haspopup="listbox"
                aria-expanded={isOptionsOpen}
                className={'selectionButton ' + (isOptionsOpen ? "expanded" : "")}
                onClick={toggleOptions}
              >
                {treeSelect.checked && treeSelect.checked.length > 0 ? <span style={{ color: 'black' }}>{treeSelect.checked.length + " Countries are Selected"}</span> : "Please Select Countries"}
              </button>
              <Input
                style={{ display: isOptionsOpen ? 'block' : 'none' }}
                placeholder="Search Countries..."
                className='mt-1'
                value={treeSelect.keyword}
                onChange={(event, data) => {
                  onSearchInputChange(event, event.target, treeSelect.keyword.trim()
                    ? keywordFilter(_.cloneDeep(countryData), treeSelect.keyword.trim())
                    : countryData);
                }}
              />
              <ul
                className={`options ${isOptionsOpen ? "show" : ""}`}
                role="listbox"
                tabIndex={-1}
              >
                <CheckboxTree
                  nodes={treeSelect.keyword.trim()
                    ? keywordFilter(_.cloneDeep(countryData), treeSelect.keyword.trim())
                    : countryData}
                  checked={treeSelect.checked}
                  expanded={treeSelect.expanded}
                  onCheck={(checked,row) => {
                    checkValue(row,checked)
                  }}
                  onExpand={expanded => setTreeSelect(treeSelect => ({ ...treeSelect, expanded: expanded }))}
                  icons={{
                    expandClose: <span ><i className='bx bxs-right-arrow mt-1'  ></i></span>,
                    expandOpen: <span ><i className='bx bxs-down-arrow mt-1'></i></span>,
                  }}
                />
              </ul>
            </div>
            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemd"
                checked={countriesState}
                onChange={countryStateChange}
              />
              <label className="form-check-label">
                {countriesState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
              </label>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <Label>City Targeting</Label>
          <div className='row checkbox-tree rmsc'>
            <div className='col-8' ref={cityRef}>
              <button
                type="button"
                aria-haspopup="listbox"
                aria-expanded={isOptionsOpenCity}
                className={'selectionButton ' + (isOptionsOpenCity ? "expanded" : "")}
                onClick={toggleOptionsCity}
              >
                {treeSelectCity.checked && treeSelectCity.checked.length > 0 ? <span style={{ color: 'black' }}>{treeSelectCity.checked.length + " Cities are Selected"}</span> : "Please Select Cities"}
              </button>
              <Input
                style={{ display: isOptionsOpenCity ? 'block' : 'none' }}
                placeholder="Search Cities..."
                className='mt-1'
                value={treeSelectCity.keyword}
                onChange={(event, data) => {
                  onSearchInputCityChange(event, event.target, treeSelectCity.keyword.trim()
                    ? keywordFilterCity(_.cloneDeep(cityData), treeSelectCity.keyword.trim())
                    : cityData);
                }}
              />
              <ul
                className={`options ${isOptionsOpenCity ? "show" : ""}`}
                role="listbox"
                tabIndex={-1}
              >
                <CheckboxTree
                  nodes={treeSelectCity.keyword.trim()
                    ? keywordFilterCity(_.cloneDeep(cityData), treeSelectCity.keyword.trim())
                    : cityData}
                  checked={treeSelectCity.checked}
                  expanded={treeSelectCity.expanded}
                  onCheck={(checked,row) => {
                    checkCityValue(row,checked)
                  }}
                  onExpand={expanded => setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, expanded: expanded }))}
                  icons={{
                    expandClose: <span ><i className='bx bxs-right-arrow mt-1'  ></i></span>,
                    expandOpen: <span ><i className='bx bxs-down-arrow mt-1'></i></span>,
                  }}
                />
              </ul>
            </div>


            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemd"
                checked={citiesState}
                onChange={cityStateChange}
              />
              <label className="form-check-label">
                {citiesState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
              </label>
            </div>
          </div>
        </div>

      </Col>

    </Row>
  )
}



GeoPlayer.propTypes = {}

export default GeoPlayer