import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Input,
  Spinner,
  Label
} from "reactstrap"
import queryString from 'query-string';

import DateRangePickers from '../../../helpers/DateRangePicker'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import {
  getPublisher as onGetPublishers,
  statusPublisher as onStatusPublisher,
  archivePublisher as onArchivePublisher,
  statusPublisherSuccess,
  archivePublisherSuccess,
  addPublisherFail,
  updatePublisherFail,
} from "store/Supply/Publisher/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Create_Publisher from "./Create_Publisher";
import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";
import LoadingTable from "pages/LoaderPages/LoadingTable";
import LineItemDataTable from "Product_Helpers/ProductDataTable"
import ProductDataTableLI from "Product_Helpers/ProductDataTableLI";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var filterCount = 0

let pageNumber = 1
let pageSize = 10
let totalRecords = 0
let searchText = ''

const Publisher = () => {
  const dispatch = useDispatch();
  const [isRight, setIsRight] = useState(false);
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const parsed = queryString.parse(window.location.search);
  const [statusSearch, setStatusSearch] = useState('Active')
  const [isArchived, setIsArchived] = useState(false)
  const [selected, setSelected] = useState([])
  const [createState, setCreateState] = useState(true)
  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));
  const userInfo = JSON.parse(localStorage.getItem('authUser'));

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const [switchLoader, setSwitchLoader] = useState(true)

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {

      setIsRight(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const [filterState, setFilterState] = useState(false)

  const statusChange = (e) => {
    if (e.target.value === 'Active') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (statusSearch === 'Active') {
      filterCount = filterCount + 1
    }
    setStatusSearch(e.target.value)
  }

  const applyFilter = (e) => {
    if (parsed.partnerId) {
      dispatch(onGetPublishers({ partnerID: parsed.partnerId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, type: '',userRole:userInfo.userRole,userID:userInfo.userID }));
    } else {
      dispatch(onGetPublishers({ partnerID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, type: '',userRole:userInfo.userRole,userID:userInfo.userID }));
    }
    if (filterCount > 0) {
      setFilterState(true)
    } else {
      setFilterState(false)
    }
  }

  const [isMenu, setisMenu] = useState(false)
  const toggleMenu = () => {
    setisMenu(!isMenu)
  }

  const clearAllFilter = (e) => {
    setStatusSearch('Active')
    filterCount = 0
    setFilterState(false)
    if (parsed.partnerId) {
      dispatch(onGetPublishers({ partnerID: parsed.partnerId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: 'Active', type: '',userRole:userInfo.userRole,userID:userInfo.userID }));
    } else {
      dispatch(onGetPublishers({ partnerID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: 'Active', type: '',userRole:userInfo.userRole,userID:userInfo.userID }));
    }
  }

  const changeDatePick = (event) => {
    console.log(event)
    console.log("start: ", event.startDate._d);
    console.log("end: ", event.endDate._d);
    setFromDate(event.startDate._d.toISOString());
    setToDate(event.endDate._d.toISOString());
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 5000,
    extendedTimeOut: 1000
  };

  useEffect(() => {
    dispatch(onGetPublishers(null));
    totalRecords = 0
  }, []);

  const selectRow = {
    mode: "checkbox",
    nonSelectable: [],
    selected: selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      console.log(row, isSelect, rowIndex)
      if (isSelect) {
        setSelected([...selected, row.id])
        setSelectedRowsStatus(true)
        selectedIndex.push(row.publisherID)
        selectedItem.push(row.publisherID)
        console.log(selectedIndex)
      } else {
        setSelected(selected.filter(x => x !== row.id))
        let index = selectedIndex.indexOf(row.publisherID)
        selectedIndex.splice(index, 1)
        let index2 = selectedItem.indexOf(row.publisherID)
        selectedItem.splice(index2, 1)
        if (selectedIndex.length === 0) {
          setSelectedRowsStatus(false)
        }
        console.log(selectedIndex)
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      const ids = rows.map(r => r.id);
      if (isSelect) {
        setSelected(ids)
        rows.map((item, index) => {
          selectedIndex.push(item.publisherID)
          selectedItem.push(item.publisherID)
        })
        setSelectedRowsStatus(true)
      } else {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        selectedItem = []
      }

    }
  };

  const handleClose = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
    selectedIndex = []
  }

  const { publishers,paginationData  } = useSelector(state => ({
    publishers: state.publishers.publisherList,
    paginationData: state.publishers.pagination
  }));

  totalRecords = paginationData ? paginationData.total_records ? paginationData.total_records : 0 : 0

  const { loading } = useSelector(state => ({
    loading: state.publishers.loading,
  }));

  const { noData } = useSelector(state => ({
    noData: state.publishers.noData,
  }));

  const { publishersResponse } = useSelector(state => ({
    publishersResponse: state.publishers.response,
  }));

  const { archivePublishersResponse } = useSelector(state => ({
    archivePublishersResponse: state.publishers.archiveMsg,
  }));

  // const { errorMsg, successMsg } = useSelector(state => ({
  //   errorMsg: state.publishers.errorMsg,
  //   successMsg: state.publishers.successMsg,
  // }));

  useEffect(() => {
    if (loading) {
      setSwitchLoader(true)
    }
  }, [loading]);

  console.log(publishersResponse)
  useEffect(() => {
    if (publishersResponse !== null) {
      if (publishersResponse.success) {
        toastr.success('Publisher Status Changed Successfully')
        if (parsed.partnerId) {
          dispatch(onGetPublishers({ partnerID: parsed.partnerId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, type: '',userRole:userInfo.userRole,userID:userInfo.userID }));
        } else {
          dispatch(onGetPublishers({ partnerID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, type: '',userRole:userInfo.userRole,userID:userInfo.userID }));
        }
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        dispatch(statusPublisherSuccess(null));
      }
    }
  }, [dispatch, publishersResponse]);

  useEffect(() => {
    if (archivePublishersResponse !== null) {
      if (archivePublishersResponse.success) {
        toastr.success('Publisher Archived Successfully')
        if (parsed.partnerId) {
          dispatch(onGetPublishers({ partnerID: parsed.partnerId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, type: '',userRole:userInfo.userRole,userID:userInfo.userID }));
        } else {
          dispatch(onGetPublishers({ partnerID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, type: '',userRole:userInfo.userRole,userID:userInfo.userID }));
        }
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        dispatch(archivePublisherSuccess(null));
      }
    }
  }, [dispatch, archivePublishersResponse]);

  const toggleRightCanvas = () => {
    selectedData = ''
    if (parsed.partnerId) {
      dispatch(onGetPublishers({ partnerID: parsed.partnerId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, type: '',userRole:userInfo.userRole,userID:userInfo.userID }));
    } else {
      dispatch(onGetPublishers({ partnerID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, type: '',userRole:userInfo.userRole,userID:userInfo.userID }));
    }
    dispatch(addPublisherFail(""));
    dispatch(updatePublisherFail(""));
    setIsRight(!isRight);
    setCreateState(!createState)
  };

  const toggleEnableScroll = () => {
    setIsEnableScroll(!isEnableScroll);
  };

  const toggleDrawer = (close, publisher) => (event) => {
    console.log(event)
    console.log(publisher)
    if (event === 'Create') {
      toastr.success('Publisher Saved Successfully!...')
    }
    if (event === 'Update') {
      toastr.success('Publisher Updated Successfully!...')
    }
    selectedData = publisher
    setIsArchived(publisher.isPublisherArchived)
    if (parsed.partnerId) {
      dispatch(onGetPublishers({ partnerID: parsed.partnerId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, type: '',userRole:userInfo.userRole,userID:userInfo.userID }));
    } else {
      dispatch(onGetPublishers({ partnerID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, type: '',userRole:userInfo.userRole,userID:userInfo.userID }));
    }
    dispatch(addPublisherFail(""));
    dispatch(updatePublisherFail(""));
    setIsRight(close);
    setCreateState(!createState)
  };

  useEffect(() => {
    if (parsed.partnerId) {
      dispatch(onGetPublishers({ partnerID: parsed.partnerId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, type: '',userRole:userInfo.userRole,userID:userInfo.userID }));
    }
  }, []);

  useEffect(() => {
    if (publishers !== null && !parsed.partnerId) {
      dispatch(onGetPublishers({ partnerID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, type: '',userRole:userInfo.userRole,userID:userInfo.userID }));
    }
  }, []);

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const publisherListColumns = [
    {
      text: "ID",
      headerClasses: "ID",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    {
      text: "ID",
      headerClasses: "ID",
      dataField: "publisherID",
      sort: true,
    },
    {
      dataField: "publisherCreateTime",
      text: "Creation Date",
      sort: true,
    },
    {
      text: "Publisher Name",
      dataField: "publisherName",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, publisher) => (
        <>
          <h5 className="font-size-12 mb-1">
          { (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 
            <Link to={`/AdUnitGroup?publisherId=${publisher.publisherID}&publisherName=${publisher.publisherName}&status=AdUnitGroupCreateByParent`} className="color-dark-blue ">
              {publisher.publisherName}
            </Link> : (UserRolePrivileges && UserRolePrivileges.Supply && UserRolePrivileges.Supply.isAdUnitGroupAccess) ?
                 <Link to={`/AdUnitGroup?publisherId=${publisher.publisherID}&publisherName=${publisher.publisherName}&status=AdUnitGroupCreateByParent`} className="color-dark-blue ">
                 {publisher.publisherName}
               </Link> : publisher.publisherName}
          </h5>
        </>
      ),
    },
    {
      dataField: "publisherSite",
      text: "Publisher Site",
      sort: true,
    },
    {
      dataField: "publisherStatus",
      text: "Status",
      sort: true,
      formatter: (cellContent, publisher) => (
        <>
          <span className={publisher.newStatus === 'Active' ? "active-state" : publisher.newStatus === 'Inactive' ? "inactive-state" : "archived-state"}>{publisher.newStatus}</span>
        </>
      ),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',
      hidden: (UserRolePrivileges.Supply && UserRolePrivileges.Supply.Publishers) && (!UserRolePrivileges.Supply.Publishers.update),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, publisher) => (
        <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
          <span className="text-success " onClick={toggleDrawer(true, publisher)}>
            {publisher.newStatus === 'Archived' &&
              <i
                className="mdi mdi-eye font-size-18"
                id="edittooltip"
              ></i>
            }
            {publisher.newStatus !== 'Archived' &&
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
              ></i>
            }
          </span>
        </div>
      ),
    },
  ];

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const archivePublisher = async () => {
    dispatch(onArchivePublisher({ idList: selectedItem,userRole:userInfo.userRole,userID:userInfo.userID }));
  }

  const statusPublisher = (state) => (event) => {
    console.log(state)
    dispatch(onStatusPublisher({ idList: selectedItem, publisherStatus: state,userRole:userInfo.userRole,userID:userInfo.userID }));
  }

  console.log(publishers)

  if (publishers.length > 0) {
    if (UserRolePrivileges.Supply && UserRolePrivileges.Supply.Publishers) {
      if (!UserRolePrivileges.Supply.Publishers.archive && !UserRolePrivileges.Supply.Publishers.status) {
        publishers.map((item, index) => {
          selectRow.nonSelectable.push(index + 1)
        })
      } else {
        publishers.map((item, index) => {
          if (item.isPublisherArchived) {
            selectRow.nonSelectable.push(index + 1)
          }
        })
      }
    }
  }

  const paginationChange = async (selectedPages) => {
    console.log(selectedPages)
    pageSize = selectedPages.pageSize
    pageNumber = selectedPages.pageNumber
    if (parsed.partnerId) {
      dispatch(onGetPublishers({ partnerID: parsed.partnerId,pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText,filter: statusSearch, type: '',userRole:userInfo.userRole,userID:userInfo.userID }));
    } else {
      dispatch(onGetPublishers({ partnerID: '', pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText,filter: statusSearch, type: '',userRole:userInfo.userRole,userID:userInfo.userID }));
    }
  }

  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      <div className="page-content">
        <MetaTags>
          <title>Publisher | Bidsxchange</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}

          <div className=" d-sm-flex  justify-content-between">
            {parsed.partnerName ? (
              <SupplyBreadCrumb title="Supply" breadcrumbItem="Publisher" partnerName={parsed.partnerName} />
            ) : (
              <SupplyBreadCrumb title="Supply" breadcrumbItem="Publisher" breadList="All Publishers" />
            )}
            {UserRolePrivileges.Supply && UserRolePrivileges.Supply.Publishers && UserRolePrivileges.Supply.Publishers.create &&
              <div className="text-sm-end">
                <div className="btn-group">
                  <Dropdown
                    direction="left"
                    isOpen={isMenu}
                    toggle={toggleMenu}
                  >
                    <DropdownToggle
                      tag="button"
                      className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                    >
                      <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                      {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                        Filter Applied
                      </span> : <span className="d-none font-16 d-sm-inline-block">
                        Filter
                      </span>
                      }
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                      <div className="dropdown-item-text">
                        <div className="row">
                          <h5 className="mb-0">Filters</h5>
                        </div>
                      </div>

                      <DropdownItem divider />
                      <Card>
                        <CardBody aria-hidden="true">
                          <Row >
                            <Col sm="12" className="mt-2">
                              <Label className="form-label">Status</Label>
                              <div className=" text-sm-end">
                                <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                  <option value=''> All</option>
                                  <option value='Active' > Active</option>
                                  <option value='Inactive'> InActive</option>
                                  <option value='Archived'> Archived</option>
                                </Input>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      <DropdownItem divider />
                      <Row>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={clearAllFilter}
                          >
                            Clear ALL
                          </DropdownItem>
                        </Col>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={applyFilter}
                          >
                            Apply Filter
                          </DropdownItem>
                        </Col>
                      </Row>

                    </DropdownMenu>
                  </Dropdown>
                </div> &nbsp;&nbsp;
                <Button
                  color="primary"
                  className="font-16 btn-block btn btn-primary"
                  onClick={toggleRightCanvas}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  New Publisher
                </Button>
              </div>
            }
          </div>
          <ProductDataTableLI statusAccess={(UserRolePrivileges.Supply && UserRolePrivileges.Supply.Publishers && UserRolePrivileges.Supply.Publishers.status) ? true : false}
            archiveAccess={(UserRolePrivileges.Supply && UserRolePrivileges.Supply.Publishers && UserRolePrivileges.Supply.Publishers.archive) ? true : false} loading={loading} data={publishers} columns={publisherListColumns} statusChange={statusPublisher} archiveLineItem={archivePublisher} rowSelection={selectRow} handleClose={handleClose} selectedIndex={selectedIndex} paginationSelection={paginationChange} totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize} />
          
        </Container>
      </div>
      {/* </div> */}

      <Offcanvas isOpen={isRight} direction='end' >
        <OffcanvasHeader toggle={toggleRightCanvas}>
          {selectedData ? 'Edit Publisher' : 'New Publisher'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <Create_Publisher closeCanvas={toggleDrawer(false, '')} selectedPublisher={selectedData} selectedArchived={isArchived} selectedPartner={(parsed.status === 'PublisherCreateByParent') ? parsed : ''} />
        </OffcanvasBody>
      </Offcanvas>


    </React.Fragment>
  )
}

export default Publisher