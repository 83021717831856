/* eslint-disable react/prop-types */
import MetaTags from "react-meta-tags";
import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Spinner,
  Collapse,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  BreadcrumbItem
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import classnames from "classnames"

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

import Sidebar from "components/VerticalLayout/Sidebar";
import Header from "components/VerticalLayout/Header";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Geography from "./Geography";
import NetworkKeywords from "./NetworkKeywords";
import NetworkUrls from "./NetworkUrls";
import AdminSidebar from "../Admin_Sidebar/AdminSidebar";
import Toaster from "pages/Toaster/Toaster";

import {
  addNewNetworkSetting as onADDNetworkSetting,
  updateNetworkSetting as onUpdateNetworkSetting,
  readNetworkSetting as onReadNetworkSetting,
  addNetworkSettingSuccess,
  addNetworkSettingFail,
  updateNetworkSettingFail,
  updateNetworkSettingSuccess
} from "store/Supply/NetworkSettings/actions"
import {
  getCommonDevices as onGetAllContextual,
  getCommonOSs as onGetBlockingData
} from "store/CommonEvents/actions";

import ContextualSettings from "./ContextualSettings";
import ToasterWarning from "pages/Toaster/ToasterWarning";
import Blocking from "./Blocking";
import Widget from "./Geograph";

const NewNetworkSettings = props => {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const getActiveTab = localStorage.getItem('activeTab');
  console.log(parseInt(getActiveTab))
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [submitted, setSubmitted] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loader, setLoader] = useState(false);

  const [contextualData, setContextualData] = useState({})

  const { contextuals } = useSelector(state => ({
    contextuals: state.commonEvents.contextualAllData,
  }));

  useEffect(() => {
    if (contextuals !== null) {
      dispatch(onGetAllContextual());
    }
  }, []);

  useEffect(() => {
    if (contextuals && JSON.stringify(contextuals) !== "{}") {
      setContextualData(contextuals)
    }
  }, [contextuals]);

  const [networkBlockingData, setNetworkBlockingData] = useState({})

  const { networkBlocks } = useSelector(state => ({
    networkBlocks: state.commonEvents.blockingAllData,
  }));

  useEffect(() => {
    if (networkBlocks !== null) {
      dispatch(onGetBlockingData());
    }
  }, []);

  useEffect(() => {
    if (networkBlocks && JSON.stringify(networkBlocks) !== "{}") {
      setNetworkBlockingData(networkBlocks)
    }
  }, [networkBlocks]);

  console.log(activeTab)

  useEffect(() => {
    if (getActiveTab) {
      toggleTab(parseInt(getActiveTab))
    }
  }, []);


  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 3) {
        localStorage.setItem("activeTab", tab);
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 5000,
    extendedTimeOut: 1000
  };


  const [finalData, setFinalData] = useState({
    networkSettingName: '',
    "networkSettingIpMappingIn": [],
    "networkSettingIpMappingEx": [],
    "networkSettingKeywordsIn": [],
    "networkSettingKeywordsEx": [],
    "networkSettingUrlIn": [],
    "networkSettingUrlEx": [],
    countriesIn: [],
    countriesEx: [],
    citiesIn: [],
    citiesEx: [],
    "domainContrlIn": [],
    "domainContrlEx": [],
    "appControlIn": [],
    "appControlEx": [],
    "deviceControlIn": [],
    "deviceControlEx": [],
    "iPV4ControlIn": [],
    "iPV4ControlEx": [],
    "iPV6ControlIn": [],
    "iPV6ControlEx": [],
    "userAgentControlIn": [],
    "userAgentControlEx": [],
  })

  const [geoStates, setGeoStates] = useState({
    countries: false,
    cities: false,
  })

  const [targetStates, setTargetStates] = useState({
    pageURLs: false,
    iPMappings: false,
    keywords: false,
  })

  const [blockingData, setBlockingData] = useState({
    devices: false,
    apps: false,
    domains: false,
    userAgents: false,
    iPV6s: false,
    iPV4s: false,
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFinalData(finalData => ({ ...finalData, [name]: value }));
  }

  const getGeoData = async (selectedGeoData) => {
    setFinalData({ ...finalData, ...selectedGeoData })
  }

  const getTargetingLineItemData = async (selectedLineItemData) => {
    setTargetStates({ ...targetStates, ...selectedLineItemData })
  }

  const getNetworkGeoData = async (selectedGeoData) => {
    setGeoStates({ ...geoStates, ...selectedGeoData })
  }

  const getBlockingData = async (selectedBlockingData) => {
    setBlockingData({ ...blockingData, ...selectedBlockingData })
  }

  useEffect(() => {
    if (props.selectedNetwork !== '') {
      setIsEdit(true)
      setFinalData(props.selectedNetwork)
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    console.log("submit click", finalData)
    let geoTargetingValidation = checkGeoTargetingData()
    let contextualValidation = checkTargetingData()
    let blockingValidation = checkBlockingData()
    debugger
    if (finalData.networkSettingName && geoTargetingValidation && contextualValidation && blockingValidation) {
      setLoader(true)
      if (props.selectedNetwork !== '') {
        dispatch(onUpdateNetworkSetting({
          ...finalData, networkSettingID: props.selectedNetwork.networkSettingID
        }));
      } else {
        dispatch(onADDNetworkSetting({ ...finalData }));
      }
    }
  }

  const checkGeoTargetingData = () => {
    if (geoStates.countries && (finalData.countriesIn.length === 0 && finalData.countriesEx.length === 0)) {
      toastr.error('Please select Countries')
      return false
    } else if (geoStates.cities && (finalData.citiesIn.length === 0 && finalData.citiesEx.length === 0)) {
      toastr.error('Please select Cities')
      return false
    } else {
      return true
    }
  }

  const checkTargetingData = () => {
    if (targetStates.pageURLs && (finalData.networkSettingUrlIn.length === 0 && finalData.networkSettingUrlEx.length === 0)) {
      toastr.error('Please select Page URLs')
      return false
    } else if (targetStates.iPMappings && (finalData.networkSettingIpMappingIn.length === 0 && finalData.networkSettingIpMappingEx.length === 0)) {
      toastr.error('Please select IP Mappings')
      return false
    } else if (targetStates.keywords && (finalData.networkSettingKeywordsIn.length === 0 && finalData.networkSettingKeywordsEx.length === 0)) {
      toastr.error('Please select Keywords')
      return false
    } else {
      return true
    }
  }

  const checkBlockingData = () => {
    if (blockingData.devices && (finalData.deviceControlIn.length === 0 && finalData.deviceControlEx.length === 0)) {
      toastr.error('Please select Devices')
      return false
    } else if (blockingData.apps && (finalData.appControlIn.length === 0 && finalData.appControlEx.length === 0)) {
      toastr.error('Please select Apps')
      return false
    } else if (blockingData.domains && (finalData.domainContrlIn.length === 0 && finalData.domainContrlEx.length === 0)) {
      toastr.error('Please select Domains')
      return false
    } else if (blockingData.userAgents && (finalData.userAgentControlIn.length === 0 && finalData.userAgentControlEx.length === 0)) {
      toastr.error('Please select User Agents')
      return false
    } else if (blockingData.iPV6s && (finalData.iPV6ControlIn.length === 0 && finalData.iPV6ControlEx.length === 0)) {
      toastr.error('Please select IPV6s')
      return false
    } else if (blockingData.iPV4s && (finalData.iPV4ControlIn.length === 0 && finalData.iPV4ControlEx.length === 0)) {
      toastr.error('Please select IPV4s')
      return false
    } else {
      return true
    }
  }

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.NetworkSettings.errorMsg,
    successMsg: state.NetworkSettings.successMsg,
  }));

  console.log(errorMsg, successMsg)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.success) {
        dispatch(addNetworkSettingSuccess(""));
        dispatch(addNetworkSettingFail(""));
        dispatch(updateNetworkSettingFail(""));
        dispatch(updateNetworkSettingSuccess(""));
        setLoader(false)
        if (props.selectedNetwork !== '') {
          props.closeCanvas('Update')
        } else {
          props.closeCanvas('Create')
        }

      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if (errorMsg.status && errorMsg.status === 500) {
      } else {
        dispatch(addNetworkSettingSuccess(""));
        dispatch(addNetworkSettingFail(""));
        dispatch(updateNetworkSettingFail(""));
        dispatch(updateNetworkSettingSuccess(""));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addNetworkSettingSuccess(""));
    dispatch(addNetworkSettingFail(""));
    dispatch(updateNetworkSettingFail(""));
    dispatch(updateNetworkSettingSuccess(""));
    props.closeCanvas('')
  };


  return (
    <React.Fragment>
      {loader &&
        <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
        <Form onSubmit={handleSubmit}>
          <div className="formBodyScroll" style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">Network Setting Name  <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <Input
                  name="networkSettingName"
                  type="text"
                  onChange={handleChange}
                  value={finalData.networkSettingName || ""}
                  className={'form-control' + (submitted && !finalData.networkSettingName ? ' is-invalid' : '')}
                />
                {submitted && !finalData.networkSettingName ? (
                  <FormFeedback type="invalid">{'Network Setting Name is Required'}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Row >
              <Col lg="12" className="p-0">
                <Card>
                  <CardBody className="p-0">
                    <h4 className="card-title m-3">Settings</h4>
                    <div className=" wizard clearfix vertical">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({ current: activeTab === 1 })}
                              onClick={() => {
                                setactiveTab(1)
                              }}
                            // disabled={!(passedSteps || []).includes(1)}
                            >
                              <span className="number">01.</span>Geography
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 2 })}
                              onClick={() => {
                                setactiveTab(2)
                              }}
                            // disabled={!(passedSteps || []).includes(2)}
                            >
                              <span className="number ">02.</span> Contextual
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 3 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 3 })}
                              onClick={() => {
                                setactiveTab(3)
                              }}
                            // disabled={!(passedSteps || []).includes(2)}
                            >
                              <span className="number ">03.</span> Blocking
                            </NavLink>
                          </NavItem>

                        </ul>
                      </div>
                      <div className="content clearfix ">
                        <TabContent activeTab={activeTab} className="body">
                          <TabPane tabId={1}>
                            <div>
                              {/* <Widget selectedArchived={props.selectedArchived} selectedItems={getGeoData} submitted={submitted} targetedSelectedItems={getNetworkGeoData} networkData={props.selectedNetwork} /> */}
                              <Geography selectedArchived={props.selectedArchived} selectedItems={getGeoData} submitted={submitted} targetedSelectedItems={getNetworkGeoData} networkData={props.selectedNetwork} />
                            </div>
                          </TabPane>
                          <TabPane tabId={2}>
                            <div>
                              <ContextualSettings selectedArchived={props.selectedArchived} contextualData={contextualData} selectedItems={getGeoData} targetedSelectedItems={getTargetingLineItemData} submitted={submitted} networkSettingData={props.selectedNetwork} tabId={activeTab} />
                            </div>
                          </TabPane>
                          <TabPane tabId={3}>
                            <div>
                              <Blocking selectedArchived={props.selectedArchived} networkBlockData={networkBlockingData} selectedItems={getGeoData} targetedSelectedItems={getBlockingData} submitted={submitted} networkSettingData={props.selectedNetwork} tabId={activeTab} />
                            </div>
                          </TabPane>

                        </TabContent>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                toggleTab(activeTab - 1)
                              }}
                            >
                              Previous
                            </Link>
                          </li>
                          <li
                            className={activeTab === 2 ? "next disabled" : "next"}
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                toggleTab(activeTab + 1)
                              }}
                            >
                              Next
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>

          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
                &nbsp;&nbsp;
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>}
                &nbsp;&nbsp;
                {isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }

              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
          <Toaster status="error" msg={errorMsg.message} />
        }
      </div>
      {errorMsg !== null && errorMsg !== '' && errorMsg.status && errorMsg.status === 500 &&
        <div className="p-0 justify-content-center d-flex">
          <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')} />
        </div>
      }
    </React.Fragment>
  )
}

NewNetworkSettings.propTypes = {}

export default NewNetworkSettings