/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import Breadcrumbs from "components/Common/Breadcrumb";
import { Col, Container, Row, Card, CardTitle, CardBody, Input, Label, Form, Button, Spinner, } from "reactstrap"
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import axios from "axios";

//redux
import { useSelector, useDispatch } from "react-redux";

import '../../CustomTracking.css'

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// actions
import {
    getCategory as onGetCategories,
} from "store/customTracking/Categories/actions";

var sizes = [{ value: "300x250", label: "300x250" },
{ value: "336x280", label: "336x280" },
{ value: "320x50", label: "320x50" },
{ value: "300x100", label: "300x100" },
{ value: "320x100", label: "320x100" },
{ value: "728x90", label: "728x90" },
{ value: "970x90", label: "970x90" },
{ value: "970x250", label: "970x250" },
{ value: "160x600", label: "160x600" },
{ value: "120x600", label: "120x600" },
{ value: "300x600", label: "300x600" },
]

var RSSSizes = [{ value: "300x250", label: "300x250" },
{ value: "320x100", label: "320x100" },
]



const NativeWidget = props => {
    const dispatch = useDispatch();
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [categoryData, setCategoryData] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [sizeData, setSizeData] = useState(sizes)
    const [selectedSize, setSelectedSize] = useState([]);
    const [response, setResponse] = useState(false)
    const [loader, setLoader] = useState(false)
    const [pageUrl, setPageUrl] = useState('https://elitelifedecor.com/home-decor-ideas-we-bet-no-one-else-will-tell-you/')


    console.log(props)

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 2000,
        extendedTimeOut: 1000
    };


    const [widgetData, setWidgetData] = useState({
        creativeName: '',
        title: '',
        landingPageURL: '',
        preview: '300x250',
        categoryList: []
    });

    const [widgetImageData, setWidgetImageData] = useState([]);

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
        "url": '',
        "requestBody": {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
        }
    }



    const fetchWidgetScrap = () => {
        setLoader(true)
        setResponse(false)
        if (props.widgetType === '1') {
            body.url = "http://10.0.3.69:3048/nativeScrap/scrapUrl"
        } else {
            body.url = "http://10.0.3.69:3048/nativeScrap/pubRssScrap"
        }
        body.requestBody = { ...body.requestBody, url: pageUrl }
        axios.post("/v1.0/connectOP", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data) {
                    let creativeData
                    if (props.widgetType === '1') {
                        creativeData = res.data.creativeData
                    } else {
                        creativeData = res.data.creative_data
                    }

                    let creativeNameAdd = creativeData[0].nativeAd_site.replace('.com', '').toLowerCase() + '-' + creativeData[0].nativeAd_title.replace(/\s+/g, '_').toLowerCase();
                    setWidgetData({
                        creativeName: creativeNameAdd,
                        title: creativeData[0].nativeAd_title,
                        landingPageURL: creativeData[0].creative_lpu,
                        preview: '300x250'
                    })
                    setWidgetImageData(creativeData)
                    setLoader(false)
                    setResponse(true)
                } else {
                    setLoader(false)
                    toastr.error('Invalid URL')
                }
            })
            .catch((err) => {
                console.log("e", err)
                setLoader(false)
            });
    }

    const { categories } = useSelector(state => ({
        categories: state.categories.ctgs,
    }));

    useEffect(() => {
        if (categories) {
            setCategoryData(objectModalCategory(categories))
            console.log(categories)
        }
    }, [categories]);

    useEffect(() => {
        if (categories !== null) {
            dispatch(onGetCategories({ filter: 'Active' }));
        }
    }, []);

    useEffect(() => {
        if (props.widgetType === '2') {
            setSizeData(RSSSizes)
            setPageUrl(props.RSSList ? props.RSSList[0] : '')
            setSelectedSize(objectModal(["300x250", "320x100"]))
            setResponse(false)
        } else {
            setSizeData(sizes)
            setPageUrl(('https://elitelifedecor.com/home-decor-ideas-we-bet-no-one-else-will-tell-you/'))
            setSelectedSize(objectModal(["300x250", "336x280", "320x50", "300x100", "320x100", "728x90", "970x90", "970x250", "160x600", "120x600", "300x600"]))
            setResponse(false)
        }

    }, [props.widgetType]);

    const objectModal = (response) => {
        console.log(response)
        var dataList = []
        response.map((item, index) => {
            let obj = {}
            obj.value = item
            obj.label = item
            dataList.push(obj)
        })
        return dataList
    }

    const objectModalCategory = (response) => {
        console.log(response)
        var dataList = []
        response.map((item, index) => {
            let obj = {}
            obj.value = item.houseAdsCategoryID
            obj.label = item.houseAdsCategoryName
            dataList.push(obj)
        })
        return dataList
    }

    function handleSelectCategory(selectCategory) {
        setSelectedCategory(selectCategory);
      }

    const handleSelectSize = size => {
        setSelectedSize(size);
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setWidgetData(widgetData => ({ ...widgetData, [name]: value }));
    }

    const handleSubmit = async (e) => {
        setLoader(true)
        setResponse(true)
        e.preventDefault();
        let sizeList = []
        selectedSize.map((item) => {
            sizeList.push(item.value)
        })
        let bodyFeed = {
            url: "",
            requestBody: {
                pageUrl: pageUrl,
                lpu: widgetData.landingPageURL,
                sizes: sizeList,
                houseAdsCategoryID: selectedCategory ? selectedCategory.value : '',
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
            }
        }
        if (props.widgetType === '1') {
            bodyFeed.url = "http://127.0.0.1:4009/v1.0/NativeAds/create/creatives"
        } else {
            bodyFeed.url = "http://127.0.0.1:4009/v1.0/NativeAds/create/RssScrapCreatives"
        }
        console.log(bodyFeed)
        axios.post("/v1.0/connectOP", bodyFeed,
            {
                headers: headers
            })
            .then((res) => {
                setLoader(false)
                console.log("result is ==>", res)
                if (res.data.success) {
                    toastr.success('Native Creative is Created Successfully')
                    setResponse(false)

                } else {
                    toastr.error(res.data.message)
                }
            })
            .catch((err) => {
                setLoader(false)
                console.log("e", err)
            });
    }

    return (
        <React.Fragment>
            <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
                <Container fluid={true}>
                    <Card>
                        <CardTitle className="mt-0 card-header bg-transparent border-bottom">
                            {response ? 'Preview Native Ad' : 'Generate Native Ad'}
                        </CardTitle>

                        <CardBody>
                            <Form onSubmit={handleSubmit}>
                                <Row style={{ padding: '1%', height: '55vh', overflow: 'auto', width: '100%' }}>
                                    <div className="mb-3 col-md-6">
                                        <Label className="form-label">Page URL</Label>
                                        {props.widgetType === '1' &&
                                            <Input
                                                name="pageUrl"
                                                type="text"
                                                value={pageUrl}
                                                onChange={(e) => setPageUrl(e.target.value)}
                                            />
                                        }
                                        {props.widgetType === '2' &&
                                            <Input type="select" className="form-select" name="pageUrl" value={widgetData.pageUrl} onChange={(e) => setPageUrl(e.target.value)} >
                                                {props.RSSList && props.RSSList.map((elem) => (
                                                    <option key={elem} value={elem}>{elem}</option>
                                                ))}
                                            </Input>
                                        }

                                    </div>
                                    <div className="mb-3 mt-4 col-md-4">
                                        <div className=" mt-1">
                                            <button
                                                type="button"
                                                className="btn btn-primary save-user"
                                                onClick={fetchWidgetScrap}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                    {response &&
                                        <div className="row">
                                            <div className="col-5" >

                                                <Row style={{ padding: '0% 0%', height: '65vh', overflow: 'auto' }}>
                                                    <Col xs={12}>
                                                        <div className="mb-3">
                                                            <Label className="form-label">Creative Name</Label>
                                                            <Input
                                                                name="creativeName"
                                                                type="text"
                                                                value={widgetData.creativeName}
                                                                readOnly
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label className="form-label">Title </Label>
                                                            <Input
                                                                name="title"
                                                                type="text"
                                                                value={widgetData.title}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label className="form-label"> Size</Label>
                                                            <MultiSelect
                                                                options={sizeData}
                                                                value={selectedSize}
                                                                onChange={handleSelectSize}
                                                                overrideStrings={{ "selectSomeItems": "Choose sizes..." }}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label className="form-label">Landing Page URL</Label>
                                                            <Input
                                                                name="landingPageURL"
                                                                type="text"
                                                                value={widgetData.landingPageURL}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label className="form-label"> Select Category</Label>
                                                            <Select
                                                                value={selectedCategory}
                                                                onChange={s => {
                                                                    handleSelectCategory(s)
                                                                }}
                                                                options={categoryData}
                                                                classNamePrefix="select2-selection"
                                                                className={(submitted && !adUnit.houseAdsAdUnitPublisher ? ' is-invalid' : '')}
                                                                isDisabled={props.selectedArchived}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </div>
                                            <div className="col-7">
                                                <div className="row">
                                                    <div className="mb-3 col-md-10">
                                                        <Label className="form-label">Preview</Label>
                                                        {props.widgetType === '1' &&
                                                            <Input type="select" className="form-select w-100" name="preview" value={widgetData.preview} onChange={handleChange} >
                                                                <option value='' disabled> Select</option>
                                                                <option value="300x250">300x250 </option>
                                                                <option value="336x280">336x280</option>
                                                                <option value="320x50">320x50</option>
                                                                <option value="320x100">320x100 </option>
                                                                <option value="300x100">300x100</option>
                                                                <option value="728x90">728x90</option>
                                                                <option value="970x90">970x90</option>
                                                                <option value="970x250">970x250</option>
                                                                <option value="160x600">160x600</option>
                                                                <option value="120x600">120x600</option>
                                                                <option value="300x600">300x600</option>
                                                            </Input>
                                                        }
                                                        {props.widgetType === '2' &&
                                                            <Input type="select" className="form-select w-100" name="preview" value={widgetData.preview} onChange={handleChange} >
                                                                <option value='' disabled> Select</option>
                                                                <option value="300x250">300x250 </option>
                                                                <option value="320x100">320x100 </option>
                                                            </Input>
                                                        }
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="text-sm-end col-md-4">
                                                            {(widgetData.preview === '320x50' || widgetData.preview === '300x100' || widgetData.preview === '320x100') ?
                                                                <i className="bx bx-mobile " style={{ fontSize: '3pc', marginTop: '1pc' }}></i> : <i className="bx bx-desktop " style={{ fontSize: '3pc', marginTop: '1pc' }}></i>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>

                                                {widgetData.preview === '300x250' &&
                                                    <div className="row mt-3 m-0" id="desktop-frame-300x250" style={{ border: '2px solid #80808082', paddingTop: '10px', borderRadius: '5px' }}>
                                                        <div className="col-md-12">
                                                            <div className="vl"></div>
                                                            <div className="row">
                                                                <div className="col-md-5 mt-2" >
                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>

                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                </div>
                                                                <div className="col-md-7" id="previewImage-desktop-300x250" style={{ margin: 'auto' }}>
                                                                    <img src={widgetImageData.length ? widgetImageData[0].nativeAd_image : ''} alt="image" style={{ width: '-webkit-fill-available' }} height="250"></img>
                                                                    <h5 className="mt-1" style={{ fontWeight: '700' }}>{widgetImageData[0].nativeAd_title}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {widgetData.preview === '320x100' &&
                                                    <div className="row mt-3 m-0" id="desktop-frame-320x100" style={{ border: '2px solid #80808082', paddingTop: '10px', borderRadius: '5px' }}>
                                                        <div className="col-md-8" style={{ left: '18%' }}>
                                                            <div className="vl-mobile"></div>
                                                            <div className="vl1"></div>
                                                            <div className="vl2"></div>
                                                            <div className="row mb-3">
                                                                <div className="col-md-6" id="previewImage-desktop-320x100" style={{ margin: 'auto', marginTop: '10px' }}>
                                                                    <img src={widgetImageData.length ? widgetImageData[0].nativeAd_image : ''} alt="image" style={{ width: '-webkit-fill-available' }} height="100"></img>
                                                                </div>
                                                                <div className="col-md-6" style={{ margin: 'auto', marginTop: '10px' }}>
                                                                    <h5 className="mt-1" style={{ fontWeight: '700' }}>{widgetImageData[0].nativeAd_title}</h5>
                                                                </div>
                                                            </div>

                                                            <div className="vl1" style={{ marginTop: '5px' }}></div>
                                                            <div className="vl2"></div>
                                                            <div className="vl2"></div>
                                                        </div>
                                                    </div>
                                                }
                                                {widgetData.preview === '336x280' &&
                                                    <div className="row mt-3 m-0" id="desktop-frame-336x280" style={{ border: '2px solid #80808082', paddingTop: '10px', borderRadius: '5px' }}>
                                                        <div className="col-md-12">
                                                            <div className="vl"></div>
                                                            <div className="row">
                                                                <div className="col-md-5 mt-2" >
                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>

                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                </div>
                                                                <div className="col-md-7" id="previewImage-desktop-336x280" style={{ margin: 'auto' }}>
                                                                    <img src={widgetImageData.length ? widgetImageData[0].nativeAd_image : ''} alt="image" style={{ width: '-webkit-fill-available' }} height="250"></img>
                                                                    <h5 className="mt-1" style={{ fontWeight: '700' }}>{widgetImageData[0].nativeAd_title}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {widgetData.preview === '320x50' &&
                                                    <div className="row mt-3 m-0" id="desktop-frame-320x50" style={{ border: '2px solid #80808082', paddingTop: '10px', borderRadius: '5px' }}>
                                                        <div className="col-md-8" style={{ left: '18%' }}>
                                                            <div className="vl-mobile"></div>
                                                            <div className="vl1"></div>
                                                            <div className="vl2"></div>
                                                            <div className="row mb-3">
                                                                <div className="col-md-5" id="previewImage-desktop-320x50" style={{ margin: 'auto', marginTop: '20px' }}>
                                                                    <img src={widgetImageData.length ? widgetImageData[0].nativeAd_image : ''} alt="image" style={{ width: '-webkit-fill-available' }} height="80"></img>
                                                                </div>
                                                                <div className="col-md-7" style={{ margin: 'auto', marginTop: '20px' }}>
                                                                    <h5 className="mt-1" style={{ fontWeight: '700' }}>{widgetImageData[0].nativeAd_title}</h5>
                                                                </div>
                                                            </div>

                                                            <div className="vl1" style={{ marginTop: '5px' }}></div>
                                                            <div className="vl2"></div>
                                                            <div className="vl2"></div>
                                                        </div>
                                                    </div>
                                                }
                                                {widgetData.preview === '300x100' &&
                                                    <div className="row mt-3 m-0" id="desktop-frame-300x100" style={{ border: '2px solid #80808082', paddingTop: '10px', borderRadius: '5px' }}>
                                                        <div className="col-md-8" style={{ left: '18%' }}>
                                                            <div className="vl-mobile"></div>
                                                            <div className="vl1"></div>
                                                            <div className="vl2"></div>
                                                            <div className="row mb-3">
                                                                <div className="col-md-6" id="previewImage-desktop-300x100" style={{ margin: 'auto', marginTop: '10px' }}>
                                                                    <img src={widgetImageData.length ? widgetImageData[0].nativeAd_image : ''} alt="image" style={{ width: '-webkit-fill-available' }} height="100"></img>
                                                                </div>
                                                                <div className="col-md-6" style={{ margin: 'auto', marginTop: '10px' }}>
                                                                    <h5 className="mt-1" style={{ fontWeight: '700' }}>{widgetImageData[0].nativeAd_title}</h5>
                                                                </div>
                                                            </div>

                                                            <div className="vl1" style={{ marginTop: '5px' }}></div>
                                                            <div className="vl2"></div>
                                                            <div className="vl2"></div>
                                                        </div>
                                                    </div>
                                                }
                                                {widgetData.preview === '728x90' &&
                                                    <div className="row mt-3 m-0" id="desktop-frame-728x90" style={{ border: '2px solid #80808082', paddingTop: '10px', borderRadius: '5px' }}>
                                                        <div className="col-md-12">
                                                            <div className="vl"></div>
                                                            <div className="row">
                                                                <div className="col-md-5" style={{ marginTop: '5px' }}>
                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>

                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                </div>
                                                                <div className="col-md-7" >
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className="col-2" id="previewImage-desktop-728x90" style={{ margin: 'auto', marginTop: '5px' }}>
                                                                    <img src={widgetImageData.length ? widgetImageData[0].nativeAd_image : ''} alt="image" style={{ width: '-webkit-fill-available' }} height="100"></img>
                                                                </div>
                                                                <div className="col-4">
                                                                    <h5 className="mt-1" style={{ fontWeight: '600', fontSize: '14px' }}>{widgetImageData[0].nativeAd_title}</h5>
                                                                    <span className="mt-1" style={{ fontSize: '12px' }}> {widgetImageData[0].nativeAd_description.length > 100 ?
                                                                        `${widgetImageData[0].nativeAd_description.substring(0, 60)}...` : widgetImageData[0].nativeAd_description
                                                                    }</span>
                                                                </div>
                                                                <div className="col-2" id="previewImage-desktop-728x90" style={{ margin: 'auto', marginTop: '5px' }}>
                                                                    <img src={widgetImageData.length ? widgetImageData[1].nativeAd_image : ''} alt="image" style={{ width: '-webkit-fill-available' }} height="100"></img>
                                                                </div>
                                                                <div className="col-4">
                                                                    <h5 className="mt-1" style={{ fontWeight: '600', fontSize: '14px' }}>{widgetImageData[1].nativeAd_title}</h5>
                                                                    <span className="mt-1" style={{ fontSize: '12px' }}> {widgetImageData[1].nativeAd_description.length > 100 ?
                                                                        `${widgetImageData[1].nativeAd_description.substring(0, 60)}...` : widgetImageData[1].nativeAd_description
                                                                    }</span>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: '10px' }}>
                                                                <div className="col-md-12">
                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {widgetData.preview === '970x90' &&
                                                    <div className="row mt-3 m-0" id="desktop-frame-970x90" style={{ border: '2px solid #80808082', paddingTop: '10px', borderRadius: '5px' }}>
                                                        <div className="col-md-12">
                                                            <div className="vl"></div>
                                                            <div className='row'>
                                                                <div className="col-3" id="previewImage-desktop-728x90" style={{ margin: 'auto', marginTop: '5px' }}>
                                                                    <img src={widgetImageData.length ? widgetImageData[0].nativeAd_image : ''} alt="image" style={{ width: '-webkit-fill-available' }} height="100"></img>
                                                                </div>
                                                                <div className="col-3">
                                                                    <h5 className="mt-1" style={{ fontWeight: '600', fontSize: '12px' }}>{widgetImageData[0].nativeAd_title}</h5>
                                                                    <span className="mt-1" style={{ fontSize: '10px' }}> {widgetImageData[0].nativeAd_description.length > 50 ?
                                                                        `${widgetImageData[0].nativeAd_description.substring(0, 50)}...` : widgetImageData[0].nativeAd_description
                                                                    }</span>
                                                                </div>
                                                                <div className="col-3" id="previewImage-desktop-728x90" style={{ margin: 'auto', marginTop: '5px' }}>
                                                                    <img src={widgetImageData.length ? widgetImageData[1].nativeAd_image : ''} alt="image" style={{ width: '-webkit-fill-available' }} height="100"></img>
                                                                </div>
                                                                <div className="col-3">
                                                                    <h5 className="mt-1" style={{ fontWeight: '600', fontSize: '12px' }}>{widgetImageData[1].nativeAd_title}</h5>
                                                                    <span className="mt-1" style={{ fontSize: '10px' }}> {widgetImageData[1].nativeAd_description.length > 50 ?
                                                                        `${widgetImageData[1].nativeAd_description.substring(0, 50)}...` : widgetImageData[1].nativeAd_description
                                                                    }</span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-5" style={{ marginTop: '5px' }}>
                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>

                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                </div>
                                                                <div className="col-md-7" >
                                                                </div>
                                                            </div>

                                                            <div className="row" style={{ marginTop: '10px' }}>
                                                                <div className="col-md-12">
                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {widgetData.preview === '970x250' &&
                                                    <div className="row mt-3 m-0" id="desktop-frame-970x250" style={{ border: '2px solid #80808082', paddingTop: '10px', borderRadius: '5px' }}>
                                                        <div className="col-md-12">
                                                            <div className="vl"></div>
                                                            <div className='row'>
                                                                <div className="col-3" id="previewImage-desktop-728x90" style={{ margin: 'auto', marginTop: '5px' }}>
                                                                    <img src={widgetImageData.length ? widgetImageData[0].nativeAd_image : ''} alt="image" style={{ width: '-webkit-fill-available' }} height="170"></img>
                                                                </div>
                                                                <div className="col-3">
                                                                    <h5 className="mt-1 text-center" style={{ fontWeight: '600', fontSize: '14px' }}>{widgetImageData[0].nativeAd_title}</h5>
                                                                    <h5 className="mt-1 text-center" style={{ fontWeight: '400', fontSize: '12px' }}> {widgetImageData[0].nativeAd_description.length > 150 ?
                                                                        `${widgetImageData[0].nativeAd_description.substring(0, 150)}...` : widgetImageData[0].nativeAd_description
                                                                    }</h5>
                                                                </div>
                                                                <div className="col-3" id="previewImage-desktop-728x90" style={{ margin: 'auto', marginTop: '5px' }}>
                                                                    <img src={widgetImageData.length ? widgetImageData[1].nativeAd_image : ''} alt="image" style={{ width: '-webkit-fill-available' }} height="170"></img>
                                                                </div>
                                                                <div className="col-3">
                                                                    <h5 className="mt-1 text-center" style={{ fontWeight: '600', fontSize: '14px' }}>{widgetImageData[1].nativeAd_title}</h5>
                                                                    <h5 className="mt-1 text-center" style={{ fontWeight: '400', fontSize: '12px' }}> {widgetImageData[1].nativeAd_description.length > 150 ?
                                                                        `${widgetImageData[1].nativeAd_description.substring(0, 150)}...` : widgetImageData[1].nativeAd_description
                                                                    }</h5>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-5" style={{ marginTop: '5px' }}>
                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>

                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                </div>
                                                                <div className="col-md-7" >
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: '10px' }}>
                                                                <div className="col-md-12">
                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {widgetData.preview === '160x600' &&
                                                    <div className="row mt-3 m-0" id="desktop-frame-160x600" style={{ border: '2px solid #80808082', paddingTop: '10px', borderRadius: '5px' }}>
                                                        <div className="col-md-12">
                                                            <div className="vl"></div>
                                                            <div className="row">
                                                                <div className="col-md-8" style={{ marginTop: '5px' }}>
                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>

                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>

                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>

                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>

                                                                </div>
                                                                <div className="col-md-4" id="previewImage-desktop-120x600" style={{ marginTop: '5px' }}>
                                                                    <img src={widgetImageData.length ? widgetImageData[0].nativeAd_image : ''} alt="image" style={{ width: '-webkit-fill-available' }} height="250"></img>
                                                                    <h5 className="mt-1 text-center" style={{ fontWeight: '600' }}>{widgetImageData[1].nativeAd_title}</h5>
                                                                    <h5 className="mt-1 text-center" style={{ fontWeight: '400', fontSize: '14px' }}> {widgetImageData[1].nativeAd_description.length > 200 ?
                                                                        `${widgetImageData[1].nativeAd_description.substring(0, 200)}...` : widgetImageData[1].nativeAd_description
                                                                    }</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {widgetData.preview === '120x600' &&
                                                    <div className="row mt-3 m-0" id="desktop-frame-120x600" style={{ border: '2px solid #80808082', paddingTop: '10px', borderRadius: '5px' }}>
                                                        <div className="col-md-12">
                                                            <div className="vl"></div>
                                                            <div className="row">
                                                                <div className="col-md-8" style={{ marginTop: '5px' }}>
                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>

                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>

                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>

                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>

                                                                </div>
                                                                <div className="col-md-4" id="previewImage-desktop-120x600" style={{ marginTop: '5px', padding: '0px 12px 25px 25px' }}>
                                                                    <img src={widgetImageData.length ? widgetImageData[0].nativeAd_image : ''} alt="image" style={{ width: '-webkit-fill-available' }} height="250"></img>
                                                                    <h5 className="mt-1 text-center" style={{ fontWeight: '600' }}>{widgetImageData[1].nativeAd_title}</h5>
                                                                    <h5 className="mt-1 text-center" style={{ fontWeight: '400', fontSize: '14px' }}> {widgetImageData[1].nativeAd_description.length > 200 ?
                                                                        `${widgetImageData[1].nativeAd_description.substring(0, 200)}...` : widgetImageData[1].nativeAd_description
                                                                    }</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {widgetData.preview === '300x600' &&
                                                    <div className="row mt-3 m-0" id="desktop-frame-300x600" style={{ border: '2px solid #80808082', paddingTop: '10px', borderRadius: '5px' }}>
                                                        <div className="col-md-12">
                                                            <div className="vl"></div>
                                                            <div className="row">
                                                                <div className="col-md-5" style={{ marginTop: '5px' }}>
                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>

                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>

                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>

                                                                    <div className="vl1"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>
                                                                    <div className="vl2"></div>

                                                                </div>
                                                                <div className="col-md-7" id="previewImage-desktop-300x600" style={{ margin: 'auto', marginTop: '5px' }}>
                                                                    <img src={widgetImageData.length ? widgetImageData[0].nativeAd_image : ''} alt="image" style={{ width: '-webkit-fill-available' }} height="300"></img>
                                                                    <h5 className="mt-1 text-center" style={{ fontWeight: '600' }}>{widgetImageData[1].nativeAd_title}</h5>
                                                                    <h5 className="mt-1 text-center" style={{ fontWeight: '400', fontSize: '14px' }}> {widgetImageData[1].nativeAd_description.length > 200 ?
                                                                        `${widgetImageData[1].nativeAd_description.substring(0, 200)}...` : widgetImageData[1].nativeAd_description
                                                                    }</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    }
                                    {loader &&
                                        <>
                                            <Spinner className="ms-2 loader" color="primary" />
                                            <br />
                                            <h5 className="text-center mt-5" style={{ padding: '15%', fontSize: '25px' }}></h5>
                                            <br />
                                        </>
                                    }
                                </Row>
                                {response &&
                                    <Row className="drawer-footer" style={{ bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
                                        <Col>
                                            <div className="text-start">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary save-user"
                                                >
                                                    Upload
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment >
    )
}

NativeWidget.propTypes = {}

export default NativeWidget