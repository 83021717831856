import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Dashboard 
import Dashboard from "./dashboard/reducer";

//Partner Management
import partners from "./PartnerManagement/reducer"

//Roles Management
import roles from "./RoleManagement/reducer"

//DEMAND

//advertisers
import advertisers from "./Demand/Advertiser/reducer"

//IOGroup
import iOGroups from  "./Demand/IOGroup/reducer"

//Bidder
import bidders from  "./Demand/Bidder/reducer"

//IOs
import iOSs from "./Demand/IOs/reducer"

//LineItems
import lineItems from "./Demand/LineItem/reducer"

//TagController
import tcs from "./Demand/TagController/reducer"

//Creatives
import creatives from "./Demand/Creative/reducer"

//SUPPLY

//Publisher
import publishers from "./Supply/Publisher/reducer"

//AdUnitGroup
import adUnitGroups from "./Supply/AdUnitGroup/reducer"

//AdUnit
import adUnits from "./Supply/AdUnit/reducer"

//ChildAdUnit
import childAdUnits from "./Supply/ChildAdUnit/reducer"

//HighImpactSetting
import highImpactSettings from "./Supply/HighImpactSettings/reducer"

//Stbr Settings
import StbrSettings from "./Supply/StbrSettings/reducer"

//PlayerSetting
import playerSettings from "./Supply/PlayerSetting/reducer"

//sizes
import commonEvents from "./CommonEvents/reducer"

//ContextualSettings
import contextualData from "./Contextual_Settings/ContextualData/reducer"
import brandAffinitys from "./Contextual_Settings/Brand_Affinity/reducer"
import interests from "./Contextual_Settings/Interests/reducer"
import keywords from "./Contextual_Settings/Keywords/reducer"
import iPMappings from "./Contextual_Settings/IPMap/reducer"
import pageUrls from "./Contextual_Settings/PageUrls/reducer"
import websiteCategorizations from "./Contextual_Settings/WebsiteCategorization/reducer"
import netBlockings from "./Contextual_Settings/NetBlocking/reducer"

import videoContents from "./Media/VideoContent/reducer";

//playlist
import playlistsReducer from "./Media/Playlist/reducer"

//marketplace
import marketContents from "./Media/MarketPlace/reducer"

//Notifcations
import pub_notifications from "./Notifications/Publisher_Notification/reducer"

//Custom Tracking

//Categories
import categories from "./customTracking/Categories/reducer"

//Categories
import adPublishers from "./customTracking/HouseAdPub/reducer"

//Cretives
import creative from "./customTracking/Creatives/reducer"

//Ad Units
import adUnit from "./customTracking/AdUnits/reducer"

//Proxy AdUnits
import  proxyAdUnit  from "./Proxy/ProxyAdUnits/reducer"

//Publisher Console
import scheduleReports from "./PublisherConsole/ScheduleReport/reducer"

//Agency Console
import agencyScheduleReports from "./AgencyConsole/ScheduleReport/reducer"

//Admin Console Support Ticket
import supportTicketAdmins from "./Support_Ticket/Admin_Ticket/Admin_Support/reducer"

//Publisher Console Support Ticket
import supportTickets from "./Support_Ticket/Publisher_Ticket/Publisher_Support/reducer"

//Admin Reports
import  adminReports  from "./Reports/AdminReports/reducer"

//Billing
import  billsHistory  from "./Billing/reducer"

//Logs
import  userLogs  from "./Logs/reducer"

//Network Settings
import NetworkSettings from "./Supply/NetworkSettings/reducer"

//publisher media
import pubVideoContents from "./Media_Publisher/VideoContent/reducer"
import pubPlaylistsReducer from "./Media_Publisher/Playlist/reducer"

//Header Bidding
import headerBids from "./HeaderBidding/HeaderBid/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Dashboard,
  advertisers,
  partners,
  roles,
  iOGroups,
  bidders,
  iOSs,
  lineItems,
  creatives,
  publishers,
  adUnitGroups,
  adUnits,
  childAdUnits,
  highImpactSettings,
  StbrSettings,
  playerSettings,
  commonEvents,
  brandAffinitys,
  interests,
  keywords,
  iPMappings,
  pageUrls,
  websiteCategorizations,
  videoContents,
  playlistsReducer,
  marketContents,
  pub_notifications,
  categories,
  creative,
  adUnit,
  proxyAdUnit,
  tcs,
  adPublishers,
  scheduleReports,
  agencyScheduleReports,
  supportTickets,
  supportTicketAdmins,
  adminReports,
  billsHistory,
  userLogs,
  NetworkSettings,
  netBlockings,
  contextualData,
  pubVideoContents,
  pubPlaylistsReducer,
  headerBids
})

export default rootReducer
