import React, { useEffect, useState, useRef, useCallback } from "react";
import MetaTags from 'react-meta-tags';
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Input,
  Spinner,
  Label, Modal, ModalBody, Collapse
} from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import {
  getAdvertisers as onGetAdvertisers,
  statusAdvertiser as onStatusAdvertiser,
  archiveAdvertiser as onArchiveAdvertiser,
  statusAdvertiserSuccess,
  archiveAdvertiserSuccess,
  addAdvertiserFail,
  updateAdvertiserFail,
} from "store/Demand/Advertiser/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Create_Advertiser from "./Create_Advertiser";

//Import SCSS
import './Advertiser.scss'
import LoadingTable from "pages/LoaderPages/LoadingTable";
import LineItemDataTable from "Product_Helpers/ProductDataTable"
import ProductDataTableLI from "Product_Helpers/ProductDataTableLI";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var loader = false
var filterCount = 0

let pageNumber = 1
let pageSize = 10
let totalRecords = 0
let searchText = ''

const PagesStarter = () => {
  const dispatch = useDispatch();
  const [isRight, setIsRight] = useState(false);
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const [statusSearch, setStatusSearch] = useState('Active')
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [isArchived, setIsArchived] = useState(false)
  const [selected, setSelected] = useState([])
  const [addAdvertiser, setAddAdvertiser] = useState(false)

  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const [showAlert, setShowAlert] = useState(false);
  
  const changeDatePick = (event) => {
    // console.log(event)
    // console.log("start: ", event.startDate._d);
    // console.log("end: ", event.endDate._d);
    setFromDate(event.startDate._d.toISOString());
    setToDate(event.endDate._d.toISOString());
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };

  useEffect(() => {
    dispatch(onGetAdvertisers(null));
    totalRecords = 0
  }, []);

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      selectedData = ''
      dispatch(addAdvertiserFail(""));
      dispatch(updateAdvertiserFail(""));
      dispatch(onGetAdvertisers({ userRole: userInfo.userRole, userID: userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch }));

      setIsRight(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const selectRow = {
    mode: "checkbox",
    nonSelectable: [],
    selected: selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelected([...selected, row.id])
        setSelectedRowsStatus(true)
        selectedIndex.push(row.advertiserID)
        selectedItem.push(row.advertiserID)
      } else {
        setSelected(selected.filter(x => x !== row.id))
        let index = selectedIndex.indexOf(row.advertiserID)
        selectedIndex.splice(index, 1)
        let index2 = selectedItem.indexOf(row.advertiserID)
        selectedItem.splice(index2, 1)
        if (selectedIndex.length === 0) {
          setSelectedRowsStatus(false)
        }
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      const ids = rows.map(r => r.id);
      if (isSelect) {
        setSelected(ids)
        rows.map((item, index) => {
          selectedIndex.push(item.advertiserID)
          selectedItem.push(item.advertiserID)
        })
        setSelectedRowsStatus(true)
      } else {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        selectedItem = []
      }
    }
  };

  const [filterState, setFilterState] = useState(false)

  const statusChange = (e) => {
    if (e.target.value === 'Active') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (statusSearch === 'Active') {
      filterCount = filterCount + 1
    }
    setStatusSearch(e.target.value)
  }

  const applyFilter = (e) => {
    dispatch(onGetAdvertisers({ userRole: userInfo.userRole, userID: userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch }));
    if (filterCount > 0) {
      setFilterState(true)
    } else {
      setFilterState(false)
    }
  }

  const [isMenu, setisMenu] = useState(false)
  const toggleMenu = () => {
    setisMenu(!isMenu)
  }

  const clearAllFilter = (e) => {
    setStatusSearch('Active')
    filterCount = 0
    setFilterState(false)
    dispatch(onGetAdvertisers({ userRole: userInfo.userRole, userID: userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: 'Active' }));
  }

  const handleClose = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
    selectedIndex = []
  }

  const { advertisers,paginationData } = useSelector(state => ({
    advertisers: state.advertisers.advs,
    paginationData: state.advertisers.pagination
  }));

  totalRecords = paginationData ? paginationData.total_records ? paginationData.total_records : 0 : 0

  const { loading } = useSelector(state => ({
    loading: state.advertisers.loading,
  }));

  const { noData } = useSelector(state => ({
    noData: state.advertisers.noData,
  }));

  const { advertisersResponse } = useSelector(state => ({
    advertisersResponse: state.advertisers.response,
  }));

  const { archiveAdvertisersResponse } = useSelector(state => ({
    archiveAdvertisersResponse: state.advertisers.archiveMsg,
  }));

  // console.log(advertisersResponse, loading)
  useEffect(() => {
    if (advertisersResponse !== null) {
      if (advertisersResponse.success) {
        toastr.success('Advertiser Status Changed Successfully')
        dispatch(onGetAdvertisers({ userRole: userInfo.userRole, userID: userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch }));

        dispatch(statusAdvertiserSuccess(null));
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []

      }
    }
  }, [dispatch, advertisersResponse]);

  useEffect(() => {
    if (archiveAdvertisersResponse !== null) {
      if (archiveAdvertisersResponse.success) {
        toastr.success('Advertiser Archived Successfully')
        dispatch(onGetAdvertisers({ userRole: userInfo.userRole, userID: userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch }));

        dispatch(archiveAdvertiserSuccess(null));
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []

      }
    }
  }, [dispatch, archiveAdvertisersResponse]);

  const toggleRightCanvas = () => {
    selectedData = ''
    // dispatch(addAdvertiserFail(""));
    // dispatch(updateAdvertiserFail(""));
    // dispatch(onGetAdvertisers({ userRole:userInfo.userRole,userID:userInfo.userID,filter: statusSearch }));
    setIsRight(!isRight);
  };

  const toggleEnableScroll = () => {
    setIsEnableScroll(!isEnableScroll);
  };

  const toggleDrawer = (close, advertiser) => (event) => {
    // console.log(event)
    if (event === 'Create') {
      toastr.success('Advertiser Saved Successfully!...')

    }
    if (event === 'Update') {
      toastr.success('Advertiser Updated Successfully!...')

    }
    selectedData = advertiser
    setIsArchived(advertiser.isAdvertiserArchived)
    dispatch(onGetAdvertisers({ userRole: userInfo.userRole, userID: userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch }));

    dispatch(addAdvertiserFail(""));
    dispatch(updateAdvertiserFail(""));
    setIsRight(close);
  };

  useEffect(() => {
    if (advertisers !== null) {
      // loader = true
      dispatch(onGetAdvertisers({ userRole: userInfo.userRole, userID: userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch }));
    }
  }, []);


  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const advertiserListColumns = [
    {
      text: "ID",
      headerClasses: "ID",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    {
      text: "ID",
      headerClasses: "ID",
      dataField: "advertiserID",
      sort: true,
      formatter: (cellContent, advertiser) => (
        <>
          <span>{advertiser.advertiserID}</span>
        </>
      ),
    },
    {
      dataField: "advertiserCreateTime",
      text: "Creation Date",
      sort: true,
    },

    {
      text: "Advertiser Name",
      dataField: "advertiserName",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, advertiser) => (
        <>
          <h5 className="font-size-12 mb-1">
         { (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 
             <Link to={`/Insertion_Order_Group?advertiserId=${advertiser.advertiserID}&advertiserName=${advertiser.advertiserName}&status=IOGroupCreateByParent`} className="color-dark-blue ">
             {advertiser.advertiserName}
           </Link>
          : (UserRolePrivileges && UserRolePrivileges.Demand && UserRolePrivileges.Demand.isIOGroupAccess) ?     <Link to={`/Insertion_Order_Group?advertiserId=${advertiser.advertiserID}&advertiserName=${advertiser.advertiserName}&status=IOGroupCreateByParent`} className="color-dark-blue ">
          {advertiser.advertiserName}
        </Link> :  advertiser.advertiserName }
          </h5>
        </>
      ),
    },

    {
      dataField: "advertiserStatus",
      text: "Status",
      // sort: true,
      formatter: (cellContent, advertiser) => (
        <>
          <span className={advertiser.newStatus === 'Active' ? "active-state" : advertiser.newStatus === 'Inactive' ? "inactive-state" : "archived-state"}>{advertiser.newStatus}</span>
        </>
      ),
    },

    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',
      hidden: (UserRolePrivileges.Demand && UserRolePrivileges.Demand.Advertisers) && (!UserRolePrivileges.Demand.Advertisers.update),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, advertiser) => (
        <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
          <span className="text-success " onClick={toggleDrawer(true, advertiser)}>
            {advertiser.newStatus === 'Archived' &&
              <i
                className="mdi mdi-eye font-size-18"
                id="edittooltip"
              ></i>
            }
            {advertiser.newStatus !== 'Archived' &&
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
              ></i>
            }
          </span>
        </div>
      ),
    },
  ];

  const Columns = [
    {
      title: "ID",
      field: "advertiserID",
    },
    {
      field: "advertiserCreateTime",
      title: "Creation Date",
    },

    {
      title: "Advertiser Name",
      field: "advertiserName",
    },

    {
      field: "advertiserStatus",
      title: "Status",
    },

  ];

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };



  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const archiveAdvertiser = async () => {
    setShowAlert(false)
    dispatch(onArchiveAdvertiser({ idList: selectedItem, userRole: userInfo.userRole, userID: userInfo.userID }));
  }

  const statusAdvertiser = (state) => (event) => {
    // console.log(state)
    dispatch(onStatusAdvertiser({ idList: selectedItem, advertiserStatus: state, userRole: userInfo.userRole, userID: userInfo.userID }));
  }

  // console.log(advertisers)

  if (advertisers.length > 0) {
    if (UserRolePrivileges.Demand && UserRolePrivileges.Demand.Advertisers) {
      if (!UserRolePrivileges.Demand.Advertisers.archive && !UserRolePrivileges.Demand.Advertisers.status) {
        advertisers.map((item, index) => {
          selectRow.nonSelectable.push(index + 1)
        })
      } else {
        advertisers.map((item, index) => {
          if (item.isAdvertiserArchived) {
            selectRow.nonSelectable.push(index + 1)
          }
        })
      }
    }
  }

  const paginationChange = async (selectedPages) => {
    console.log(selectedPages)
    pageSize = selectedPages.pageSize
    pageNumber = selectedPages.pageNumber
    dispatch(onGetAdvertisers({ userRole: userInfo.userRole, userID: userInfo.userID,filter: statusSearch,pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText }));
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Advertiser | Bidsxchange</title>
        </MetaTags>
        {/* {loading &&
            <Spinner className="ms-2 loader" color="primary" />
          } */}
        <Container fluid style={{ pointerEvents: (loading) ? 'none' : 'unset' }}>
          <div className=" d-sm-flex  justify-content-between">
            <Breadcrumbs title="Demand" breadcrumbItem="Advertiser" breadList="All Advertisers" />
            {UserRolePrivileges.Demand && UserRolePrivileges.Demand.Advertisers && UserRolePrivileges.Demand.Advertisers.create &&
              <div className=" text-sm-end">
                <div className="btn-group">
                  <Dropdown
                    direction="left"
                    isOpen={isMenu}
                    toggle={toggleMenu}
                  >
                    <DropdownToggle
                      tag="button"
                      className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                    >
                      <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                      {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                        Filter Applied
                      </span> : <span className="d-none font-16 d-sm-inline-block">
                        Filter
                      </span>
                      }
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                      <div className="dropdown-item-text">
                        <div className="row">
                          <h5 className="mb-0">Filters</h5>
                        </div>
                      </div>

                      <DropdownItem divider />
                      <Card>
                        <CardBody aria-hidden="true">
                          <Row >
                            <Col sm="12" className="mt-2">
                              <Label className="form-label">Status</Label>
                              <div className=" text-sm-end">
                                <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                  <option value=''> All</option>
                                  <option value='Active' > Active</option>
                                  <option value='Inactive'> InActive</option>
                                  <option value='Archived'> Archived</option>
                                </Input>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      <DropdownItem divider />
                      <Row>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={clearAllFilter}
                          >
                            Clear ALL
                          </DropdownItem>
                        </Col>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={applyFilter}
                          >
                            Apply Filter
                          </DropdownItem>
                        </Col>
                      </Row>

                    </DropdownMenu>
                  </Dropdown>
                </div> &nbsp;&nbsp;
                <Button
                  color="primary"
                  className="font-16 btn-block btn btn-primary"
                  onClick={toggleRightCanvas}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Add Advertiser
                </Button>
              </div>
            }
          </div>

          <Collapse isOpen={addAdvertiser}>
            <Card id="addAdvertiser">
              <CardBody aria-hidden="true">
                <Row>
                  <Col sm="6">
                    <Label className="form-label">Advertiser Name</Label>
                    <Input type="text" />
                  </Col>
                  <Col className="d-flex justify-content-end align-items-center">
                    <div className="text-start">
                      &nbsp;&nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary save-user"
                      >
                        Save
                      </button>
                      &nbsp;&nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary save-user"
                        onClick={() => setNextTab(true)}
                      >
                        Save & Add IO Group
                      </button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Collapse>
            <ProductDataTableLI statusAccess={(UserRolePrivileges.Demand && UserRolePrivileges.Demand.Advertisers && UserRolePrivileges.Demand.Advertisers.status) ? true : false}
            archiveAccess={(UserRolePrivileges.Demand && UserRolePrivileges.Demand.Advertisers && UserRolePrivileges.Demand.Advertisers.archive) ? true : false}  loading={loading} data={advertisers} columns={advertiserListColumns} statusChange={statusAdvertiser} archiveLineItem={archiveAdvertiser} rowSelection={selectRow} handleClose={handleClose} selectedIndex={selectedIndex} paginationSelection={paginationChange} totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize} />
        </Container>
      </div>

      <Offcanvas isOpen={isRight} direction='end'  >
        <OffcanvasHeader toggle={toggleRightCanvas}>
          {selectedData ? 'Edit Advertiser' : 'Add Advertiser'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <Create_Advertiser closeCanvas={toggleDrawer(false, '')} selectedAdvertiser={selectedData} selectedArchived={isArchived} />
        </OffcanvasBody>
      </Offcanvas>


      <Modal size="sm" isOpen={showAlert} toggle={() => { setShowAlert(!showAlert) }} backdrop={'static'} centered={true}
        id="staticBackdrop"
      >
        <div className="modal-content">
          <ModalBody className="px-2 py-4 text-center ">
            <i className="bx bx-error-circle text-warning fon-size-32" style={{ fontSize: '2rem' }}></i> &nbsp;
            <h5 className="text-muted font-size-16 mb-4">  Are you sure you want to Archive this Advertiser?</h5>

            <div className="hstack gap-2 justify-content-center mb-0">
              <button type="button" className="btn btn-light" onClick={() => { setShowAlert(!showAlert) }}>No</button>
              <button type="button" className="btn btn-primary" onClick={archiveAdvertiser}>Yes</button>
            </div>
          </ModalBody>
        </div>
      </Modal>


    </React.Fragment>
  )
}

export default withRouter(PagesStarter)


