/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Spinner } from "reactstrap";
import PropTypes from "prop-types";
import axios from "axios";
import Select from "react-select";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// actions
import {
  addNewUser as onADDUser,
  updateUser as onUpdateUser,
  addUserSuccess,
  addUserFail,
  updateUserSuccess,
  updateUserFail,
} from "store/PartnerManagement/actions";

import {
  getRoles as onGetRoles,
} from "store/RoleManagement/actions";


import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";

import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";




const New_User = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  console.log(props)
  const [submitted, setSubmitted] = useState(false);
  const [loader, setLoader] = useState(false);
  const [forcePassword, setForcePassword] = useState(false);
  const [userRolesData, setUserRolesData] = useState([])
  const [selectedUserRoles, setSelectedUserRoles] = useState(null);

  const [user, setUser] = useState({
    userName: '',
    password: generatePassword(),
    userRole: '',
    userEmail: '',
    confirmEmail: '',
    companyID: userInfo.companyID,
    generatePasswordEmail: forcePassword,
    inventoryType: 'display'
  });

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 1000,
    extendedTimeOut: 1000
  };

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.partners.errorMsg,
    successMsg: state.partners.successMsg,
  }));

  console.log(errorMsg, successMsg)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.success) {
        dispatch(addUserSuccess(""));
        dispatch(addUserFail(null));
        dispatch(updateUserSuccess(""));
        dispatch(updateUserFail(null));
        setLoader(false)
        if (props.selectedUser !== '') {
          props.closeCanvas('Update')
        } else {
          props.closeCanvas('Create')
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if (errorMsg.status && errorMsg.status === 500) {
      } else {
        dispatch(addUserSuccess(""));
        dispatch(addUserFail(null));
        dispatch(updateUserSuccess(""));
        dispatch(updateUserFail(null)); f
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addUserSuccess(""));
    dispatch(addUserFail(null));
    dispatch(updateUserSuccess(""));
    dispatch(updateUserFail(null));
    props.closeCanvas('')
  };

  useEffect(() => {
    if (props.selectedUser !== '') {
      setIsEdit(true)
      setUser(props.selectedUser)
      setSelectedUserRoles({ value: props.selectedUser.userRole, label: props.selectedUser.userRole });
    }
  }, []);

  const reGeneratePassword = () => {
    setUser(user => ({ ...user, password: generatePassword() }));
  }

  function generatePassword() {

    var keyListAlpha = "qwertyuiopasdfghjklzxcvbnm",
      keyListAlphaUpper = "QWERTYUIOPASDFGHJKLZXCVBNM",
      keyListInt = "1234567890",
      keyListSpec = "!@#$%^&*",
      password = '';
    var len = Math.ceil(7 / 2);
    len = len - 1;
    var lenSpec = 7 - 2 * len;

    for (let i = 0; i < len; i++) {
      password += keyListAlpha.charAt(Math.floor(Math.random() * keyListAlpha.length));
      password += keyListAlphaUpper.charAt(Math.floor(Math.random() * keyListAlphaUpper.length));
      password += keyListInt.charAt(Math.floor(Math.random() * keyListInt.length));
    }

    for (let i = 0; i < lenSpec; i++)
      password += keyListSpec.charAt(Math.floor(Math.random() * keyListSpec.length));

    password = password.split('').sort(function () { return 0.5 - Math.random() }).join('');

    return password;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser(user => ({ ...user, [name]: value }));
  }

  const handleCheck = (type) => (e) => {
    setUser(user => ({ ...user, inventoryType: type }));
  }

  const { roles } = useSelector(state => ({
    roles: state.roles.roles,
  }));

  useEffect(() => {
    if (roles !== null) {
      dispatch(onGetRoles({ filter: 'Active' }));
    }
  }, []);

  useEffect(() => {
    if (roles && roles.length > 0) {
      setUserRolesData(objectModal(roles))
      console.log(roles)
    }
  }, [roles]);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.roleName
      obj.label = item.roleName
      dataList.push(obj)
    })
    return dataList
  }

  function handleSelectUserRoles(selectUserRoles) {
    setUser(user => ({ ...user, userRole: selectUserRoles.value }));
    setSelectedUserRoles(selectUserRoles);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    console.log(user)
    if (props.selectedUser === '' && user.confirmEmail !== user.userEmail) {
      toastr.error('Confirm Email is must match with User Email')
      return
    }
    if (user.userName && user.userEmail && user.userRole) {
      console.log("this If condition==============");
      setLoader(true)
      if (props.selectedUser !== '') {
        dispatch(onUpdateUser({ ...user, password: '', userName: user.userName.trim(), userEmail: user.userEmail.trim() }));
      } else {
        dispatch(onADDUser({ ...user, userName: user.userName.trim(), userEmail: user.userEmail.trim() }));
      }
    }
  }
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    navigator.clipboard.writeText(user.password)
  };

  return (

    <React.Fragment>
      {loader &&
        <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
        <Form onSubmit={handleSubmit}  >
          <Row style={{ padding: '3%', height: '85vh', overflow: 'auto', width: '100%' }}>
            <Col xl={12} className="mb-2">
              <div className="mb-3">
                <Label className="form-label">User Name <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <Input
                  name="userName"
                  onChange={handleChange}
                  value={user.userName}
                  className={'form-control' + (submitted && !user.userName ? ' is-invalid' : '')}
                />
                {submitted && !user.userName && (
                  <FormFeedback type="invalid">{'User Name is required'}</FormFeedback>
                )}
              </div>
              <div className="mb-3">
                <Label className="form-label">User Email <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <Input
                  name="userEmail"
                  type="email"
                  onChange={handleChange}
                  value={user.userEmail}
                  className={'form-control' + (submitted && !user.userEmail ? ' is-invalid' : '')}
                />
                {submitted && !user.userEmail && (
                  <FormFeedback type="invalid">{'User Email is required'}</FormFeedback>
                )}
              </div>
              {props.selectedUser === '' &&
                <div className="mb-3">
                  <Label className="form-label">Confirm User Email <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                  <Input
                    name="confirmEmail"
                    type="email"
                    onChange={handleChange}
                    value={user.confirmEmail}
                    className={'form-control' + (submitted && !user.confirmEmail ? ' is-invalid' : '')}
                  />
                  {submitted && !user.confirmEmail && (
                    <FormFeedback type="invalid">{'Confirm Email is required'}</FormFeedback>
                  )}
                  {submitted && !(user.confirmEmail === user.userEmail) && (
                    <FormFeedback type="invalid">{'Confirm Email is must match with User Email'}</FormFeedback>
                  )}
                </div>
              }
              {props.selectedUser === '' &&
                <div className="mb-3">
                  <Label className="form-label">User Password <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                  <div className="row">
                    <div className="col-9">
                      <Input
                        name="password"
                        type="text"
                        value={user.password}
                        readOnly
                      />
                      <span className="passwordIcon" style={{cursor:'pointer'}} >
                        <   i onClick={togglePasswordVisiblity}  id="Popoverdismiss1" className='bx bx-copy'></i>
                      </span>
                      <ReactTooltip
                        anchorSelect="#Popoverdismiss1"
                        place="top"
                        variant="dark"
                        openOnClick="true"
                        delayHide={800}
                        content="Copied"
                      />
                    </div>
                    <div className="col-3">
                      <button
                        type="button"
                        className="btn btn-outline-primary w-100"
                        // eslint-disable-next-line react/prop-types
                        onClick={reGeneratePassword}
                      >
                        Generate Password
                      </button>
                    </div>
                  </div>
                </div>
              }
              <div className="mb-3">
                <div className="form-check mt-2 mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={forcePassword}
                    onChange={() =>(setForcePassword(!forcePassword),setUser(user => ({ ...user, generatePasswordEmail: !forcePassword })))}
                    id="forceUserToChange"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="forceUserToChange"
                  >
                    Force user(s) to change password on login
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <Label className="form-label">User Role <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                {/* <Input
                  name="userRole"
                  type="select"
                  onChange={handleChange}
                  value={user.userRole}
                  className={'form-control form-select' + (submitted && !user.userRole ? ' is-invalid' : '')}
                >
                  <option value=''>Select</option>
                  {roles && roles.map((elem) => (
                     <option key={elem.roleID} value={elem.roleName}>{elem.roleName}</option>
                  ))}
                </Input> */}
                <Select
                  value={selectedUserRoles}
                  onChange={s => {
                    handleSelectUserRoles(s)
                  }}
                  options={userRolesData}
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived}
                />
                {submitted && !user.userRole &&
                  <div className="invalid-feedback">User Role is required</div>
                }
              </div>
              <div className="mb-3">
                <Label className="form-label">Inventory Type <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>

                <div className="row">

                  <Col md={3}>
                    <div className="form-check mt-2 mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="inventoryType"
                        checked={user.inventoryType === 'display'}
                        onChange={handleCheck('display')}
                        id='display'
                      />
                      <label
                        className="form-check-label"
                        htmlFor='display'
                      >
                        Display
                      </label>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="form-check mt-2 mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="inventoryType"
                        checked={user.inventoryType === 'video'}
                        onChange={handleCheck('video')}
                        id='video'
                      />
                      <label
                        className="form-check-label"
                        htmlFor='video'
                      >
                        Video
                      </label>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="form-check mt-2 mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="inventoryType"
                        checked={user.inventoryType === 'both'}
                        onChange={handleCheck('both')}
                        id='both'
                      />
                      <label
                        className="form-check-label"
                        htmlFor='both'
                      >
                        Both
                      </label>
                    </div>
                  </Col>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">
                {isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }

                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>

              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
          <Toaster status="error" msg={errorMsg.message} />
        }
      </div>
      {errorMsg !== null && errorMsg !== '' && errorMsg.status && errorMsg.status === 500 &&
        <div className="p-0 justify-content-center d-flex">
          <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')} />
        </div>
      }

    </React.Fragment>
  );
};

export default New_User

New_User.propTypes = {
  history: PropTypes.object,
};