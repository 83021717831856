import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    Spinner,
    Label
} from "reactstrap"
import queryString from 'query-string';
import axios from "axios";

import DateRangePickers from '../../../helpers/DateRangePicker'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import {
    getBidders as onGetBidders,
    statusBidder as onStatusBidder,
    archiveBidder as onArchiveBidder,
    statusBidderSuccess,
    archiveBidderSuccess,
    addBidderFail,
    updateBidderFail,
} from "store/Demand/Bidder/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import LoadingTable from "pages/LoaderPages/LoadingTable";
import NewBidder from './NewBidder';

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var filterCount = 0

const Bidder = props => {
    const dispatch = useDispatch();
    const [isRight, setIsRight] = useState(false);
    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
    const parsed = queryString.parse(window.location.search);
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [statusSearch, setStatusSearch] = useState('Active')
    const [isArchived, setIsArchived] = useState(false)
    const [selected, setSelected] = useState([])

    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());

    const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));

    const [switchLoader, setSwitchLoader] = useState(true)

    const handleSwitch = () => {
        setSwitchLoader(!switchLoader)
    }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            setIsRight(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);


    const changeDatePick = (event) => {
        console.log(event)
        console.log("start: ", event.startDate._d);
        console.log("end: ", event.endDate._d);
        setFromDate(event.startDate._d.toISOString());
        setToDate(event.endDate._d.toISOString());
    }

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const Headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
        "url": '',
        "requestBody": {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            name: userInfo.name,
        }
    }

    useEffect(() => {
        dispatch(onGetBidders(null));
    }, []);

    const selectRow = {
        mode: "checkbox",
        nonSelectable: [],
        selected: selected,
        onSelect: (row, isSelect, rowIndex, e) => {
            console.log(row, isSelect, rowIndex)
            if (isSelect) {
                setSelected([...selected, row.id])
                setSelectedRowsStatus(true)
                selectedIndex.push(row.bidderID)
                selectedItem.push(row.bidderID)
                console.log(selectedIndex)
            } else {
                setSelected(selected.filter(x => x !== row.id))
                let index = selectedIndex.indexOf(row.bidderID)
                selectedIndex.splice(index, 1)
                let index2 = selectedItem.indexOf(row.bidderID)
                selectedItem.splice(index2, 1)
                if (selectedIndex.length === 0) {
                    setSelectedRowsStatus(false)
                }
                console.log(selectedIndex)
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            const ids = rows.map(r => r.id);
            if (isSelect) {
                setSelected(ids)
                rows.map((item, index) => {
                    selectedIndex.push(item.bidderID)
                    selectedItem.push(item.bidderID)
                })
                setSelectedRowsStatus(true)
            } else {
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
                selectedItem = []
            }
        }
    };

    const handleClose = () => {
        setSelectedRowsStatus(false)
        selectedItem = []
        setSelected([])
        selectedIndex = []
    }

    var { bidders } = useSelector(state => ({
        bidders: state.bidders.bidders,
    }));

    const { loading } = useSelector(state => ({
        loading: state.bidders.loading,
    }));

    const { noData } = useSelector(state => ({
        noData: state.bidders.noData,
    }));

    const { biddersResponse } = useSelector(state => ({
        biddersResponse: state.bidders.response,
    }));

    const { archiveBiddersResponse } = useSelector(state => ({
        archiveBiddersResponse: state.bidders.archiveMsg,
    }));

    useEffect(() => {
        if (loading) {
            setSwitchLoader(true)
        }
    }, [loading]);

    console.log(biddersResponse)
    useEffect(() => {
        if (biddersResponse !== null) {
            if (biddersResponse.success) {
                toastr.success('Bidder Status Changed Successfully')
                dispatch(onGetBidders({ filter: statusSearch, }));
                selectedItem = []
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
                dispatch(statusBidderSuccess(null));
            }
        }
    }, [dispatch, biddersResponse]);

    useEffect(() => {
        if (archiveBiddersResponse !== null) {
            if (archiveBiddersResponse.success) {
                toastr.success('Bidder Archived Successfully')
                dispatch(onGetBidders({ filter: statusSearch }));
                selectedItem = []
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
                dispatch(archiveBidderSuccess(null));
            }
        }
    }, [dispatch, archiveBiddersResponse]);

    const toggleRightCanvas = () => {
        selectedData = ''
        dispatch(onGetBidders({ filter: statusSearch }));
        dispatch(addBidderFail(""));
        dispatch(updateBidderFail(""));
        setIsRight(!isRight);
    };

    const toggleEnableScroll = () => {
        setIsEnableScroll(!isEnableScroll);
    };

    const toggleDrawer = (close, bidder) => (event) => {
        console.log(event)
        if (event === 'Create') {
            toastr.success('Bidder Saved Successfully!...')
        }
        if (event === 'Update') {
            toastr.success('Bidder Updated Successfully!...')
        }
        selectedData = bidder
        setIsArchived(bidder.isBidderArchived)
        dispatch(onGetBidders({ filter: statusSearch }));
        dispatch(addBidderFail(""));
        dispatch(updateBidderFail(""));
        setIsRight(close);
    };

console.log(bidders)
    useEffect(() => {
        if (bidders !== null ) {
            dispatch(onGetBidders({ filter: statusSearch }));
        }
    }, []);

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const bidderListColumns = [
        {
            text: "ID",
 headerClasses:"ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            text: "ID",
 headerClasses:"ID",
            dataField: "bidderID",
            sort: true,
            formatter: (cellContent, bidder) => (
                <>
                    <span>{bidder.bidderID}</span>
                </>
            ),
        },
        {
            dataField: "bidderCreateTime",
            text: "Creation Date",
            sort: true,
        },
        {
            text: "Header Bidder Name",
            dataField: "bidderName",
            sort: true,
        },
        // {
        //     dataField: "bidderStatus",
        //     text: "Status",
        //     sort: true,
        //     formatter: (cellContent, bidder) => (
        //         <>
        //             <span className={bidder.newStatus === 'Active' ? "active-state" : bidder.newStatus === 'Inactive' ? "inactive-state" : "archived-state"}>{bidder.newStatus}</span>
        //         </>
        //     ),
        // },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, bidder) => (
                <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
                    <span className="text-success " onClick={toggleDrawer(true, bidder)}>
                        {bidder.newStatus === 'Archived' &&
                            <i
                                className="mdi mdi-eye font-size-18"
                                id="edittooltip"
                            ></i>
                        }
                        {bidder.newStatus !== 'Archived' &&
                            <i
                                className="mdi mdi-pencil font-size-18"
                                id="edittooltip"
                            ></i>
                        }
                    </span>
                </div>
            ),
        },
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );

    const { SearchBar } = Search;
    const pageOptions = {
        paginationSize: 4,
        pageStartIndex: 1,
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: true, // Hide the going to First and Last page button
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };

    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const archiveBidder = async () => {
        dispatch(onArchiveBidder({ idList: selectedItem }));
    }

    const statusBidder = (state) => (event) => {
        console.log(state)
        dispatch(onStatusBidder({ idList: selectedItem, bidderStatus: state }));
    }

    const [filterState, setFilterState] = useState(false)

    const statusChange = (e) => {
        if (e.target.value === 'Active') {
            filterCount = filterCount > 0 ? filterCount - 1 : 0
        }
        if (statusSearch === 'Active') {
            filterCount = filterCount + 1
        }
        setStatusSearch(e.target.value)
    }

    const applyFilter = (e) => {
        dispatch(onGetBidders({ filter: statusSearch }));
        if (filterCount > 0) {
            setFilterState(true)
        } else {
            setFilterState(false)
        }
    }

    const [isMenu, setisMenu] = useState(false)
    const toggleMenu = () => {
        setisMenu(!isMenu)
    }

    const clearAllFilter = (e) => {
        setStatusSearch('Active')
        filterCount = 0
        setFilterState(false)
        dispatch(onGetBidders({ filter: 'Active' }));
    }

    console.log(bidders)

    if (bidders.length > 0) {
        bidders.map((item, index) => {
            if (item.isBidderArchived) {
                selectRow.nonSelectable.push(index + 1)
            }
        })
    }

    return (
        <React.Fragment>
            {/* <Sidebar />
        <Header />
        <div className="main-content"> */}
            <div className="page-content">
                <MetaTags>
                    <title>Header Bidder | Bidsxchange</title>
                </MetaTags>
                <Container fluid={true}>
                    {/* Render Breadcrumbs */}

                    <div className=" d-sm-flex  justify-content-between">

                        <Breadcrumbs title="Demand" breadcrumbItem="Header Bidder" breadList="All Header Bidders" />
                        <div className="text-sm-end">
                            <div className="btn-group">
                                <Dropdown
                                    direction="left"
                                    isOpen={isMenu}
                                    toggle={toggleMenu}
                                >
                                    <DropdownToggle
                                        tag="button"
                                        className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                                    >
                                        <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                                        {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                                            Filter Applied
                                        </span> : <span className="d-none font-16 d-sm-inline-block">
                                            Filter
                                        </span>
                                        }
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                                        <div className="dropdown-item-text">
                                            <div className="row">
                                                <h5 className="mb-0">Filters</h5>
                                            </div>
                                        </div>

                                        <DropdownItem divider />
                                        <Card>
                                            <CardBody aria-hidden="true">
                                                <Row >
                                                    <Col sm="12" className="mt-2">
                                                        <Label className="form-label">Status</Label>
                                                        <div className=" text-sm-end">
                                                            <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                                                <option value=''> All</option>
                                                                <option value='Active' > Active</option>
                                                                <option value='Inactive'> InActive</option>
                                                                <option value='Archived'> Archived</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>

                                        <DropdownItem divider />
                                        <Row>
                                            <Col sm="6">
                                                <DropdownItem
                                                    className="text-primary text-center"
                                                    onClick={clearAllFilter}
                                                >
                                                    Clear ALL
                                                </DropdownItem>
                                            </Col>
                                            <Col sm="6">
                                                <DropdownItem
                                                    className="text-primary text-center"
                                                    onClick={applyFilter}
                                                >
                                                    Apply Filter
                                                </DropdownItem>
                                            </Col>
                                        </Row>

                                    </DropdownMenu>
                                </Dropdown>
                            </div> &nbsp;&nbsp;
                            <Button
                                color="primary"
                                className="font-16 btn-block btn btn-primary"
                                onClick={toggleRightCanvas}
                            >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                Add Header Bidder
                            </Button>
                        </div>
                    </div>


                    {switchLoader &&
                        <LoadingTable loading={loading} targetLoading={handleSwitch} />
                    }
                    {!switchLoader &&
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>

                                        <ToolkitProvider
                                            keyField={keyField}
                                            data={bidders}
                                            columns={bidderListColumns}
                                            // bootstrap4
                                            search
                                        >
                                            {toolkitProps => (
                                                <React.Fragment>
                                                    <Row className="mb-2">
                                                        <Col sm="4">
                                                            <div className="search-box ms-2 mb-2 d-inline-block">
                                                                <div className="position-relative">
                                                                    <SearchBar {...toolkitProps.searchProps} />
                                                                    <i className="bx bx-search-alt search-icon" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col sm="4"></Col>
                                                        <Col sm="4" className="date-range ">
                                                            <div className="text-sm-end">
                                                                <DateRangePickers datePick={changeDatePick} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl="12">
                                                            {selectedRowsStatus && selectedIndex.length > 0 &&
                                                                <div className="row m-0 React-Table-Selection">
                                                                    {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                                                                    <div style={{ padding: '0.5% 2%' }}>

                                                                        {/* <button color="primary" className="btn btn-primary " onClick={statusBidder(true)}>Activate</button>
                                                                        <button color="primary" className="btn btn-primary " onClick={statusBidder(true)}>
                                                                            Activate
                                                                        </button>

                                                                        &nbsp;  &nbsp;

                                                                        <button color="primary" className="btn btn-primary " onClick={statusBidder(false)}>
                                                                            DeActivate
                                                                        </button>

                                                                        &nbsp;  &nbsp; */}

                                                                        <Button color="primary" className="btn btn-primary " onClick={archiveBidder} >
                                                                            Archive
                                                                        </Button>

                                                                        <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                                                            className="mdi mdi-close-thick font-size-18"
                                                                        ></i></Button>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col xl="12" className="table-responsive">
                                                            <BootstrapTable
                                                                keyField={keyField}
                                                                responsive
                                                                bordered={false}
                                                                striped={false}
                                                                defaultSorted={defaultSorted}
                                                                selectRow={selectRow}
                                                                classes={
                                                                    "table align-middle table-nowrap"
                                                                }
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                pagination={paginationFactory(pageOptions)}
                                                            // ref={node}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>
                                            )}
                                        </ToolkitProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }

                </Container>
            </div>
            {/* </div> */}

            <Offcanvas isOpen={isRight} direction='end' >
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {selectedData ? 'Edit Bidder' : 'Add Bidder'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <NewBidder closeCanvas={toggleDrawer(false, '')} selectedArchived={isArchived} selectedBidder={selectedData}  />
                </OffcanvasBody>
            </Offcanvas>
        </React.Fragment>
    )
}

Bidder.propTypes = {}

export default Bidder