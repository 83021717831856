import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import MetaTags from 'react-meta-tags';
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Input,
  Spinner,
  Label,
  Modal,
} from "reactstrap"

import DateRangePickers from '../../../helpers/DateRangePicker'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import {
  getTcs as onGetTcs,
  statusTc as onStatusTc,
  archiveTc as onArchiveTc,
  readTc as onReadTc,
  statusTcSuccess,
  archiveTcSuccess,
  addTcFail,
  updateTcFail,
} from "store/Demand/TagController/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import Create_TagController from "./Create_TagController";
import LoadingTable from "pages/LoaderPages/LoadingTable";
import New_TagController from "./New_TagController";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var filterCount = 0

const TagController = () => {
  const dispatch = useDispatch();
  const [isRight, setIsRight] = useState(false);
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const [statusSearch, setStatusSearch] = useState('Live')
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [isArchived, setIsArchived] = useState(false)
  const [selected, setSelected] = useState([])
  const [popoverdismiss, setpopoverdismiss] = useState(false);
  const [jsTagModalState, setJsTagModalState] = useState(false);
  const inputRef = useRef();

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));

  const [switchLoader, setSwitchLoader] = useState(true)

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setIsRight(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);


  const changeDatePick = (event) => {
    console.log(event)
    console.log("start: ", event.startDate._d);
    console.log("end: ", event.endDate._d);
    setFromDate(event.startDate._d.toISOString());
    setToDate(event.endDate._d.toISOString());
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };

  useEffect(() => {
    dispatch(onGetTcs(null));
  }, []);

  const selectRow = {
    mode: "checkbox",
    nonSelectable: [],
    selected: selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelected([...selected, row.id])
        setSelectedRowsStatus(true)
        selectedIndex.push(row.tagControllerID)
        selectedItem.push(row.tagControllerID)
      } else {
        setSelected(selected.filter(x => x !== row.id))
        let index = selectedIndex.indexOf(row.tagControllerID)
        selectedIndex.splice(index, 1)
        let index2 = selectedItem.indexOf(row.tagControllerID)
        selectedItem.splice(index2, 1)
        if (selectedIndex.length === 0) {
          setSelectedRowsStatus(false)
        }
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      const ids = rows.map(r => r.id);
      if (isSelect) {
        setSelected(ids)
        rows.map((item, index) => {
          selectedIndex.push(item.tagControllerID)
          selectedItem.push(item.tagControllerID)
        })
        setSelectedRowsStatus(true)
      } else {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        selectedItem = []
      }
    }
  };

  const [filterState, setFilterState] = useState(false)

  const statusChange = (e) => {
    if (e.target.value === 'Live') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (statusSearch === 'Live') {
      filterCount = filterCount + 1
    }
    setStatusSearch(e.target.value)
  }

  const applyFilter = (e) => {
    dispatch(onGetTcs({ filter: statusSearch }));
    if (filterCount > 0) {
      setFilterState(true)
    } else {
      setFilterState(false)
    }
  }

  const [isMenu, setisMenu] = useState(false)
  const toggleMenu = () => {
    setisMenu(!isMenu)
  }

  const clearAllFilter = (e) => {
    setStatusSearch('Live')
    filterCount = 0
    setFilterState(false)
    dispatch(onGetTcs({ filter: 'Live' }));
  }

  const handleClose = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
    selectedIndex = []
  }

  const { tcs } = useSelector(state => ({
    tcs: state.tcs.tcsList,
  }));

  const { loading } = useSelector(state => ({
    loading: state.tcs.loading,
  }));

  const { tcsResponse } = useSelector(state => ({
    tcsResponse: state.tcs.response,
  }));

  const { archiveTcsResponse } = useSelector(state => ({
    archiveTcsResponse: state.tcs.archiveMsg,
  }));

  const { readTCData } = useSelector(state => ({
    readTCData: state.tcs.tc,
  }));

  // const { errorMsg, successMsg } = useSelector(state => ({
  //   errorMsg: state.tcs.errorMsg,
  //   successMsg: state.tcs.successMsg,
  // }));

  console.log(readTCData)

  useEffect(() => {
    if (loading) {
      setSwitchLoader(true)
    }
  }, [loading]);

  console.log(tcsResponse)
  useEffect(() => {
    if (tcsResponse !== null) {
      if (tcsResponse.success) {
        toastr.success('Tc Status Changed Successfully')
        dispatch(onGetTcs({ filter: statusSearch }));
        dispatch(statusTcSuccess(null));
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
      }
    }
  }, [dispatch, tcsResponse]);

  useEffect(() => {
    if (archiveTcsResponse !== null) {
      if (archiveTcsResponse.success) {
        toastr.success('Tc Archived Successfully')
        dispatch(onGetTcs({ filter: statusSearch }));
        dispatch(archiveTcSuccess(null));
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
      }
    }
  }, [dispatch, archiveTcsResponse]);

  const toggleRightCanvas = () => {
    selectedData = ''
    dispatch(addTcFail(""));
    dispatch(updateTcFail(""));
    dispatch(onGetTcs({ filter: statusSearch }));
    setIsRight(!isRight);
  };

  const toggleEnableScroll = () => {
    setIsEnableScroll(!isEnableScroll);
  };

  const toggleDrawer = (close, tc) => (event) => {
    console.log(event)
    if (event === 'Create') {
      toastr.success('Tc Saved Successfully!...')
    }
    if (event === 'Update') {
      toastr.success('Tc Updated Successfully!...')
    }
    selectedData = tc
    setIsArchived(tc.isTCArchived)
    dispatch(onGetTcs({ filter: statusSearch }));
    dispatch(addTcFail(""));
    dispatch(updateTcFail(""));
    setIsRight(close);
  };

  useEffect(() => {
    if (tcs !== null) {
      dispatch(onGetTcs({ filter: statusSearch }));
    }
  }, []);

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const tcListColumns = [
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "tagControllerID",
      sort: true,
      formatter: (cellContent, tc) => (
        <>
          <span>{tc.tagControllerID}</span>
        </>
      ),
    },
    {
      dataField: "tagControllerCreateTime",
      text: "Creation Date",
      sort: true,
    },

    {
      text: "TC Name",
      dataField: "tagControllerName",
      sort: true,
    },
    {
      dataField: "tagControllerStatus",
      text: "Status",
      sort: true,
      formatter: (cellContent, tc) => (
        <>
          <span className={tc.tagControllerStatus === 'Live' ? "active-state" : tc.tagControllerStatus === 'Paused' ? "inactive-state" : "archived-state"}>{tc.tagControllerStatus}</span>
        </>

      ),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',
      hidden: (UserRolePrivileges.TagController && UserRolePrivileges.TagController.TCs) && (!UserRolePrivileges.TagController.TCs.update),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, tc) => (
        <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
          <span className="text-success " onClick={toggleDrawer(true, tc)}>
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
              ></i>
          </span>
          <span onClick={() => { tog_standard(tc) }} style={{ cursor: 'pointer' }}>
            <i
              className="bx bx-copy-alt font-size-18"
              id="edittooltip"
            ></i>
          </span>
        </div>
      ),
    },
  ];

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };



  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const archiveTc = async () => {
    dispatch(onArchiveTc({ idList: selectedItem }));
  }

  const statusTc = (state) => (event) => {
    console.log(state)
    dispatch(onStatusTc({ idList: selectedItem, tagControllerStatus: state }));
  }

  console.log(tcs)

  // useEffect(() => {
  //   if (readTCData !== null) {
  //     if (readTCData.success) {
  //       setAdUnitData(readTCData.response)
  //     }
  //   }
  // }, [readTCData]);

  function tog_standard(tc) {
    console.log(tc)
    if (tc !== '') {
      dispatch(onReadTc(tc.tagControllerID));
      setJsTagModalState(!jsTagModalState);
    } else {
      setJsTagModalState(!jsTagModalState);
    }
  }

  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([readTCData.response.generatedTag], {
      type: "text/plain"
    });
    element.href = URL.createObjectURL(file);
    element.download = (readTCData.response.tagControllerName + ".txt");
    document.body.appendChild(element);
    element.click();
  };

  const copyTag = (e) => {
    setpopoverdismiss(!popoverdismiss)
    navigator.clipboard.writeText(readTCData.response.generatedTag)
  }

  // if (tcs.length > 0) {
  //   if (UserRolePrivileges.TagController && UserRolePrivileges.TagController.TCs) {
  //     if (!UserRolePrivileges.TagController.TCs.archive && !UserRolePrivileges.TagController.TCs.status) {
  //       tcs.map((item, index) => {
  //         selectRow.nonSelectable.push(index + 1)
  //       })
  //     } else {
  //       tcs.map((item, index) => {
  //         if (item.isTCArchived) {
  //           selectRow.nonSelectable.push(index + 1)
  //         }
  //       })
  //     }
  //   }
  // }


  //   const articleForm = document.getElementById('article-form');

  // articleForm.addEventListener('submit', async (event) => {
  //   event.preventDefault();

  //   const articleUrl = document.getElementById('article-generatedTag').value;

  //   const articleContent = await getArticleContent(articleUrl);
  //   const summary = await summarizeArticle(articleContent);
  //   const audioContent = await textToSpeech(summary);
  //   playAudio(audioContent);
  // });

  // async function getArticleContent(articleUrl) {
  //   const apiKey = 'your-api-key';
  //   const response = await fetch(`https://mercury.postlight.com/parser?generatedTag=${articleUrl}`, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'x-api-key': apiKey
  //     }
  //   });

  //   const json = await response.json();
  //   return json.content;
  // }

  // async function summarizeArticle(articleContent) {
  //   const apiKey = 'your-api-key';
  //   const response = await fetch(`https://api.smmry.com?SM_API_KEY=${apiKey}&SM_LENGTH=5&SM_URL=${encodeURIComponent(articleUrl)}`);

  //   const json = await response.json();
  //   return json.sm_api_content;
  // }

  // async function textToSpeech(text) {
  //   const apiKey = 'your-api-key';
  //   const response = await fetch(`https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=${apiKey}`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       input: {
  //         text
  //       },
  //       voice: {
  //         languageCode: 'en-US',
  //         ssmlGender: 'FEMALE'
  //       },
  //       audioConfig: {
  //         audioEncoding: 'MP3'
  //       }
  //     })
  //   });

  //   const json = await response.json();
  //   const audioContent = json.audioContent;
  //   return audioContent;
  // }

  // function playAudio(audioContent) {
  //   const audioBlob = new Blob([audioContent], {type: 'audio/mp3'});
  //   const audioUrl = URL.createObjectURL(audioBlob);

  //   const audioElement = document.createElement('audio');
  //   audioElement.src = audioUrl;
  //   audioElement.controls = true;

  //   document.body.appendChild(audioElement);
  // }

  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      <div className="page-content">
        <MetaTags>
          <title>Tag Controller | Bidsxchange</title>
        </MetaTags>
        <Container fluid >
          {/* Render Breadcrumbs */}
          <div className=" d-sm-flex  justify-content-between">
            <Breadcrumbs title="TagController" breadcrumbItem="Tag Controller" />
            {UserRolePrivileges.TagController && UserRolePrivileges.TagController.TCs && UserRolePrivileges.TagController.TCs.create &&
              <div className=" text-sm-end">
                          <div className="btn-group">
                  <Dropdown
                    direction="left"
                    isOpen={isMenu}
                    toggle={toggleMenu}
                  >
                    <DropdownToggle
                      tag="button"
                      className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                    >
                      <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                      {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                        Filter Applied
                      </span> : <span className="d-none font-16 d-sm-inline-block">
                        Filter
                      </span>
                      }
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                      <div className="dropdown-item-text">
                        <div className="row">
                          <h5 className="mb-0">Filters</h5>
                        </div>
                      </div>

                      <DropdownItem divider />
                      <Card>
                        <CardBody aria-hidden="true">
                          <Row >
                            <Col sm="12" className="mt-2">
                              <Label className="form-label">Status</Label>
                              <div className=" text-sm-end">
                                <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                  <option value=''> All</option>
                                  <option value='Live' > Live</option>
                                  <option value='Paused'> Paused</option>
                                </Input>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      <DropdownItem divider />
                      <Row>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={clearAllFilter}
                          >
                            Clear ALL
                          </DropdownItem>
                        </Col>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={applyFilter}
                          >
                            Apply Filter
                          </DropdownItem>
                        </Col>
                      </Row>

                    </DropdownMenu>
                  </Dropdown>
                </div> &nbsp;&nbsp;
                <Button
                  color="primary"
                  className="font-16 btn-block btn btn-primary"
                  onClick={toggleRightCanvas}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Add Tag Controller
                </Button>
              </div>
            }
          </div>
          {switchLoader &&
            <LoadingTable loading={loading} targetLoading={handleSwitch} />
          }
          {!switchLoader &&
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody aria-hidden="true">
                          <ToolkitProvider
                            keyField={keyField}
                            data={tcs}
                            columns={tcListColumns}
                            // bootstrap4
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar {...toolkitProps.searchProps} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="4"></Col>
                                  <Col sm="4" className="date-range ">
                                    <div className="text-sm-end">
                                      <DateRangePickers datePick={changeDatePick} />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    {selectedRowsStatus && selectedIndex.length > 0 &&
                                      <div className="row m-0 React-Table-Selection">
                                        {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                                        <div style={{ padding: '0.5% 2%' }}>

                                          {/* <button color="primary" className="btn btn-primary " onClick={statusTc(true)}>Activate</button> */}
                                          {UserRolePrivileges.TagController && UserRolePrivileges.TagController.TCs && UserRolePrivileges.TagController.TCs.status &&
                                            <button color="primary" className="btn btn-primary " onClick={statusTc(true)}>
                                              Live
                                            </button>
                                          }
                                          &nbsp;  &nbsp;
                                          {UserRolePrivileges.TagController && UserRolePrivileges.TagController.TCs && UserRolePrivileges.TagController.TCs.status &&
                                            <button color="primary" className="btn btn-primary " onClick={statusTc(false)}>
                                              Pause
                                            </button>
                                          }
                                          <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                            className="mdi mdi-close-thick font-size-18"
                                          ></i></Button>
                                        </div>
                                      </div>
                                    }
                                  </Col>
                                  <Col xl="12" className="table-responsive">
                                      <BootstrapTable
                                        keyField={keyField}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        pagination={paginationFactory(pageOptions)}
                                        noDataIndication={<h5 className="text-center">No Data</h5>}
                                      // ref={node}
                                      />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
          {/* // commented by vasu */}
          {/* <form id="article-form">
                <label htmlFor="article-generatedTag">Enter Article URL:</label>
                <input type="text" id="article-generatedTag" name="article-generatedTag" />
                <button type="submit">Summarize and Play</button>
              </form> */}
        </Container>
      </div>
      {/* </div> */}

      <Offcanvas isOpen={isRight} direction='end'  >
        {/* <Row className="d-flex" style={{borderBottom: '1px solid #9e9e9e',width:'100%',padding:'20px'}}>
           <button type="button" onClick={() => {  setIsRight(false)}}  className="btn-close" aria-label="Close"></button>
           &nbsp;&nbsp; 
          New Tc
          </Row> */}
        <OffcanvasHeader toggle={toggleRightCanvas}>
          {selectedData ? 'Edit Tag Controller' : 'Add Tag Controller'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <New_TagController closeCanvas={toggleDrawer(false, '')} selectedTc={selectedData} selectedArchived={isArchived} />
        </OffcanvasBody>
      </Offcanvas>

      {readTCData &&
        <Modal
          size="lg"
          isOpen={jsTagModalState}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Ad Unit : {readTCData.response.tagControllerName}
            </h5>
            <button
              type="button"
              onClick={() => {
                setJsTagModalState(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h5>JS Tag</h5>
            <br />
            <Input
              ref={inputRef}
              type="textarea"
              id="textarea"
              rows="8"
              readOnly
              name="jsTag" value={readTCData.response.generatedTag}
            />
            <br />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                setJsTagModalState(false);
              }}
              className="btn btn-secondary "
              data-dismiss="modal"
            >
              Close
            </button>
            <Button
              color="primary"
              id="Popoverdismiss2"
              className="font-16 btn-block btn btn-primary"
              onClick={copyTag}
              onMouseLeave={() => {
                setpopoverdismiss(!popoverdismiss)
              }}
            >
              <i className="bx bx-copy me-1" />
              Copy Tag
            </Button>
            <ReactTooltip 
            anchorSelect="#Popoverdismiss2"
            place="top"
            variant="dark"
            openOnClick="true"
            delayHide={800}
            content="Copied"
          />
            {/* <UncontrolledPopover
              trigger="focus"
              target="Popoverdismiss"
              placement="top"
              isOpen={popoverdismiss}
            >
              <PopoverHeader>Tag Copied</PopoverHeader>
            </UncontrolledPopover> */}
            <Button
              color="primary"
              className="font-16 btn-block btn btn-primary"
              onClick={downloadTxtFile}
            >
              <i className="bx bx-download me-1" />
              Download Tag
            </Button>
          </div>
        </Modal>
      }
    </React.Fragment>
  )
}

export default TagController


