import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Input
} from "reactstrap"

import DateRangePickers from '../../../helpers/DateRangePicker'
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

const Settings = props => {
    const dispatch = useDispatch();
    const [isRight, setIsRight] = useState(false);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [settings, setAnalyticalReports] = useState([])
  
    const changeDatePick = (event) => {
        console.log(event)
        console.log("start: ", event.startDate._d);
        console.log("end: ", event.endDate._d);
        setFromDate(event.startDate._d.toISOString());
        setToDate(event.endDate._d.toISOString());
    }
  
    toastr.options = {
        positionClass: "toast-top-right",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 1000,
        extendedTimeOut: 1000
      };
  
    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };
  
    const keyField = "id";
  
    const settingsListColumns = [
        {
            text: "ID",
 headerClasses:"ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            dataField: "creativeCategory",
            text: "Category",
            sort: true,
        },
  
        {
            dataField: "creativeRotationPreferences",
            text: "Rotation Preferences",
            sort: false,
        },
        {
          dataField: "action",
          text: "Action",
          sort: false,
        },
    ];
  
    const { SearchBar } = Search;
    const sizePerPage = 10;
    const pageOptions = {
        sizePerPage: 10,
        totalSize: settings.length, // replace later with size(settings),
        custom: true,
    };
    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];
  return (
    <React.Fragment>
    {/* <Sidebar />
    <Header />
    <div className="main-content"> */}
        <div className="page-content">
            <MetaTags>
                <title>Settings | Bidsxchange</title>
            </MetaTags>
            <Container fluid={true}>
                <Breadcrumbs title="Custom Tracking" breadcrumbItem="Settings" />
                <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField={keyField}
                                            columns={settingsListColumns}
                                            data={settings}
                                        >
                                            {({ paginationProps, paginationTableProps }) => {
                                                return (
                                                    <ToolkitProvider
                                                        keyField={keyField}
                                                        data={settings}
                                                        columns={settingsListColumns}
                                                        // bootstrap4
                                                        search
                                                    >
                                                        {toolkitProps => (
                                                            <React.Fragment>
                                                         <Row className="mb-2">
                                                            <Col sm="3">
                                                            <div className="search-box ms-2 mb-2 d-inline-block">
                                                                <div className="position-relative">
                                                                <SearchBar {...toolkitProps.searchProps} />
                                                                <i className="bx bx-search-alt search-icon" />
                                                                </div>
                                                            </div>
                                                            </Col>
                                                            <Col sm="5">
                                                            <div className=" text-sm-end">
                                                                <DateRangePickers datePick={changeDatePick} />
                                                            </div>
                                                            </Col>
                                                            <Col sm="2">
                                                            <div className=" text-sm-end">
                                                                <Input type="select" className="form-select" >
                                                                <option value=''> Select Creative Category</option>
                                                                <option value='test'> Test</option>
                                                                </Input>
                                                            </div>
                                                            </Col>
                                                            <Col sm="2">
                                                            <div className=" text-sm-end">
                                                                <Input type="select" className="form-select" >
                                                                <option value=''> Filter by Rotation Preferences</option>
                                                                <option value='Active' > All Active Creatives</option>
                                                                <option value='7days'>All Creatives uploaded in last 7 days</option>
                                                                <option value='30days'>All Creatives uploaded in last 30 days</option>
                                                                <option value='60days'>All Creatives uploaded in last 60 days</option>
                                                                </Input>
                                                            </div>
                                                            </Col>
                                                        </Row>
                                                                <Row>
                                                                    <Col xl="12">
                                                                        <div className="table-responsive">
                                                                            <BootstrapTable
                                                                                keyField={keyField}
                                                                                responsive
                                                                                bordered={false}
                                                                                striped={false}
                                                                                defaultSorted={defaultSorted}
                                                                                classes={
                                                                                    "table align-middle table-nowrap"
                                                                                }
                                                                                headerWrapperClasses={"thead-light"}
                                                                                {...toolkitProps.baseProps}
                                                                                {...paginationTableProps}
                                                                                noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                            // ref={node}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="align-items-md-center mt-30">
                                                                    <Col className="inner-custom-pagination d-flex">
                                                                        <div className="d-inline">
                                                                            <SizePerPageDropdownStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                        <div className="text-md-right ms-auto">
                                                                            <PaginationListStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                                );
                                            }}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                </Row>
            </Container>
        </div>
    {/* </div> */}
</React.Fragment >
  )
}

Settings.propTypes = {}

export default Settings