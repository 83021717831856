import React, { useState, useRef } from 'react';
import queryString from 'query-string';
import { Row, Col, Container, Input, Label, Form } from "reactstrap";
import { Link } from 'react-router-dom';
import axios from "axios";
import PasswordStrengthIndicator from "pages/Authentication/PasswordStrengthIndicator";
import CarouselPage from "pages/Authentication/CarouselPage";
import MetaTags from "react-meta-tags";
// import images
import logodark from "assets/images/favicons.png";

const ResetPassword = () => {
  const [inputs, setInputs] = useState({
    newPassword: '',
    confirmPassword: ''
  });
  const parsed = queryString.parse(window.location.search);
  const [passwordFocused, setPasswordFocused] = useState(false)
  const [passwordValidity, setPasswordValidity] = useState({
    passwordLength: false,
    containNumbers: false,
    isUpperCase: false,
    isLowerCase: false,
    requireSymbols: false
  });

  const [submitted, setSubmitted] = useState(false);
  const { newPassword, confirmPassword } = inputs;
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [open, setOpen] = useState(false);
  const linkRef = useRef();

  const handleChange = (e) => {
    setPasswordMatch(false)
    const { name, value } = e.target;
    let targetValue = e.target.value;
    console.log(e.target.name)
    setInputs(inputs => ({ ...inputs, [name]: value }));
    if (e.target.name === 'newPassword') {
      console.log(e.target.name)
      var matches = targetValue.match(/\d+/g);
      var upperCaseMatch = targetValue.match(/[A-Z]/);
      var lowerCaseMatch = targetValue.match(/[a-z]/);
      var symbolMatch = new RegExp(/[^A-Z a-z 0-9]/);
      setPasswordValidity({
        passwordLength: targetValue.length > 7 ? true : false,
        containNumbers: matches != null ? true : false,
        isUpperCase: upperCaseMatch != null ? true : false,
        isLowerCase: lowerCaseMatch != null ? true : false,
        requireSymbols: symbolMatch.test(targetValue) ? true : false
      })
    }
  }

  const buttonStatus = passwordValidity.passwordLength && passwordValidity.containNumbers && passwordValidity.isUpperCase && passwordValidity.isLowerCase && passwordValidity.requireSymbols ? false : true;

  const handleSubmit = (e) => {
    setPasswordMatch(false)
    e.preventDefault();
    setSubmitted(true);
    if (newPassword && confirmPassword) {
      console.log(inputs)
      if (newPassword === confirmPassword) {
        let item = { password: newPassword, token: parsed.token }

        axios.post("/v1.0/resetPassword", item,
          {
            headers: {
              "Content-Type": "application/json",
              "Accept": 'application/json'
            }
          })
          .then((res) => {
            console.log("result", res)
            if (res.data.isURLActive) {
              linkRef.current.click()
            } else {
              setOpen(true);
            }

          })
          .catch((err) => {
            console.log("e", err)
          });
      } else {
        setPasswordMatch(true)
        return false
      }
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Reset Password | Bidsxchange
        </title>
      </MetaTags>
      <Container fluid className="p-0">
        <Row className="g-0">
          <CarouselPage />
          <Col md={5}>
            <div className="auth-full-page-content p-md-5 p-4">
              <div className="w-50" style={{ margin: "auto" }}>
                <div className="d-flex flex-column h-100">
                  <div className="mb-1 mb-md-2">
                    <h4>
                      <img
                        src={logodark}
                        alt=""
                        height="30"
                        className="auth-logo-dark"
                      />&nbsp;&nbsp;
                    </h4>
                  </div>
                  <div className="my-auto">
                    <div>
                      <h5 className="text-primary"> Reset Password</h5>
                    </div>
                    <div className="mt-4">
                      <Form className="form-horizontal"
                        onSubmit={handleSubmit}
                      >
                        <div className="mb-3">
                          <Label className="form-label">New Password <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                          <Input type="password" name="newPassword" value={newPassword} onFocus={() => setPasswordFocused(true)} onChange={handleChange} className={'form-control' + (submitted && !newPassword ? ' is-invalid' : '')} />
                          {submitted && !newPassword &&
                            <div className="invalid-feedback">New Password is required</div>
                          }
                          {passwordFocused && buttonStatus && <PasswordStrengthIndicator validity={passwordValidity} />}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Confirm Password <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                          <input type="password" name="confirmPassword" value={confirmPassword} onChange={handleChange} className={'form-control' + (submitted && !confirmPassword ? ' is-invalid' : '')} />
                          {submitted &&
                            <div className="invalid-feedback">Confirm Password is required</div>
                          }
                        </div>
                        <div className=" form-row m-0">
                          {passwordMatch &&
                            <span className="invalid-message">Password confirmation must match with Password</span>
                          }
                        </div>
                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-md "
                              type="submit"
                              disabled={buttonStatus}
                            >
                              Submit
                            </button>
                          </Col>
                        </Row>
                      </Form>

                      <div className="mt-5 text-center">
                        <p>
                          Already Changed Password &nbsp;
                          <Link to="login" className="font-weight-medium text-primary">
                            Login
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <footer className="footer" style={{ textAlign: 'end' }}>
            <Container fluid={true}>
              <Row>
                <Col md={4}>    <div className="text-sm-start d-flex"> 2015 - {new Date().getFullYear()} © Bidsxchange. All rights reserved.</div></Col>
                <Col md={4}>
                  <div className="text-center">
                    <h6 style={{ letterSpacing: '6px' }}>BIDSXCHANGE</h6>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="text-sm-end d-flex">
                    <Col >
                      <a href="https://streamlyn.com/cookie-policy/" rel="noreferrer" className="under-line-hyper-link" target="_blank">Cookie Policy</a>
                      &nbsp;|&nbsp;
                      <a href="https://streamlyn.com/privacy-policy/" rel="noreferrer" className="under-line-hyper-link" target="_blank">Privacy Policy</a>
                    </Col>
                  </div>
                </Col>
              </Row>
            </Container>
          </footer>
        </Row>

      </Container>
      <Link ref={linkRef} to="login"></Link>
    </React.Fragment>
  );
}

export default ResetPassword