/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback } from "reactstrap";
import { WithContext as ReactTags } from 'react-tag-input';

//redux
import { useSelector, useDispatch } from "react-redux";


var list = []
var keywordsList = []

const NetworkKeywords = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [tags, setTags] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [keyword, setKeyword] = useState({
    keywordGroup: '',
    keywordList: [],
  });
  console.log(props)

  const [controls, setControls] = useState('indidual')

  const handleCheck = (event) => {
    setControls(event.target.name);
  };

    useEffect(() => {
      if(props.selectedKeyword  !== '' ){
        list = []
        keywordsList = []
        console.log(props.selectedKeyword)
        setIsEdit(true)
        setKeyword(props.selectedKeyword)
        if( props.selectedKeyword.keywordList &&  props.selectedKeyword.keywordList.length > 0 ){
          setControls('list');
        }
        keywordsList = props.selectedKeyword.keywordList.map((item, index) =>
        Object.assign({}, { text: item, id: item })
    )
    console.log(keywordsList)
    props.selectedKeyword.keywordList.map((item, index) => {
        list.push(item)
        return list
    })
    setTags(keywordsList)
      } else {
        list = []
        keywordsList = []
      }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setKeyword(keyword => ({ ...keyword, [name]: value }));
    props.selectedItems({ [name]: value })
  }

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
    list.splice(i, 1)
    setKeyword(keyword => ({ ...keyword, keywordList: list }));
    props.selectedItems({ keywordList: list })
  };

  const handleAddition = tag => {
    list.push(tag.id)
    setTags([...tags, tag]);
    setKeyword(keyword => ({ ...keyword, keywordList: list }));
    props.selectedItems({ keywordList: list })
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

  }



  return (
    <React.Fragment>
      <div className="col-12">
          <Row form style={{ padding: '3%' }}>
            <Col xs={12}>
                  <div className="mb-3">
                    <Label className="form-label">Keyword Group Name</Label>
                    <Input
                      type="text" name="keywordGroup" value={keyword.keywordGroup} onChange={handleChange} className={'form-control'}
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Keyword List</Label>

                    <ReactTags
                      tags={tags}
                      handleDelete={handleDelete}
                      handleAddition={handleAddition}
                      inputFieldPosition="inline"
                      style={{ width: '100%' }}
                      autocomplete
                      placeholder="Press enter to add new Keyword"
                    />
                  </div>
            </Col>
          </Row>
      </div>
    </React.Fragment>
  );
};

NetworkKeywords.propTypes = {}

export default NetworkKeywords