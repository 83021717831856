/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import MetaTags from 'react-meta-tags';
import axios from "axios";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    Spinner,
    Label, Form,FormFeedback,    TabContent,
    TabPane,    Nav,NavItem,NavLink,
} from "reactstrap"

import jsPDF from "jspdf";
import "jspdf-autotable";

import DateRangePickers from 'helpers/DateRangePicker'
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';

import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import moment from "moment";

import excelFile from 'assets/files/AdsTxtFile.xlsx'
import PDFFile from 'assets/files/AdsTxtEntries.pdf'

import {
    getAnalyticsDisplayReports as onGetAnalyticsDisplayReport,
} from "store/Proxy/ProxyAdUnits/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";
import ReportQueryHistory from "./ReportQueryHistory";

import classnames from "classnames";


var fromDate = moment().subtract(6, 'days')
var toDate = moment()
var loader = false

const Display_Analytics = () => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const dispatch = useDispatch();
    const [reportOpen, setReportOpen] = useState(false);
    const [btnprimary1, setBtnprimary1] = useState(false)
    const [requestTotal, setRequestTotal] = useState(0)
    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 2000,
        extendedTimeOut: 1000
    };

    const [selectAll, setSelectAll] = useState(false)
    const [selectMetrice, setSelectMetrice] = useState(false)
    const [range, setRange] = useState('Day')
    // const [loader, setLoader] = useState(false)
    const [selectAllMTC, setSelectAllMTC] = useState(false)
    const [fileName,setFileName] = useState('')
    const [submitted, setSubmitted] = useState(false);

    const [customActiveTab, setcustomActiveTab] = useState("1");

    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
          setcustomActiveTab(tab);
        }
      };

    const [selectAllDM, setSelectAllDM] = useState({
        advertiser: false,
        insertion_order: false,
        line_item: false,
        creative_name: false,
        country: false,
        device:false,
        ad_unit:false,
        partner:false,
        site:false,
        Device:false,
    });

    const [filters, setFilters] = useState({
        advertiser: '',
        insertion_order: '',
        line_item: '',
        creative_name: '',
        country: '',
        device:'',
        ad_unit:'',
        partner:'',
        site:'',
    });

    const [filterData, setFilterData] = useState({
        advertisers: [],
        insertion_orders: [],
        line_items: [],
        creatives: [],
        countries: [],
        devices:[],
        ad_units:[],
        partners:[],
        sites:[],
    })

    const [selectedMTC, setSelectedMTC] = useState({
        ad_requests: false,
        viewable_requests: false,
        impressions: false,
        refreshed_impressions: false,
        viewable_refreshed_impressions: false,
        unfilled_impressions: false,
        viewability: false,
        clicks: false,
        ctr: false,
        fill_rate: false,
    });

    const changeFilter = (e) => {
        const { name, value } = e.target;
        setFilters(filters => ({ ...filters, [name]: value }));
        // changeData(e)
    }

    const changeMTCSelect = (e) => {
        setSelectAllMTC(e.target.checked)
        setSelectedMTC({
            ad_requests: e.target.checked,
            // viewable_requests: e.target.checked,
            impressions: e.target.checked,
            // refreshed_impressions: e.target.checked,
            // viewable_refreshed_impressions: e.target.checked,
            // unfilled_impressions: e.target.checked,
            // viewability: e.target.checked,
            // clicks: e.target.checked,
            // ctr: e.target.checked,
            // fill_rate: e.target.checked,
        })
    }

    const changeSelectionMTC = (e) => {
        const { name, value } = e.target;
        setSelectedMTC(selectedMTC => ({ ...selectedMTC, [name]: e.target.checked }));
        let newMTC = { ...selectedMTC, [name]: e.target.checked }
        let MtcArr = Object.values(newMTC)
        if (!MtcArr.includes(false)) {
            setSelectAllMTC(true)
        } else {
            setSelectAllMTC(false)
        }
    }

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
        "url": '',
        "requestBody": {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            name:userInfo.name,
        }
    }

    useEffect(() => {
        body.url = 'http://127.0.0.1:4011/bsrv/filter'

        axios.post("/v1.0/connectOP",body,{
            headers: headers
        })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data) {
                    setFilterData(res.data)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });


            
        fromDate = moment().subtract(6, 'days')
        toDate = moment()
        console.log("start: ", fromDate);
        console.log("end: ", toDate);
    }, []);

    const changeData = (data) => {
        const { name, value } = data.target;
        body.url = "http://127.0.0.1:2003/v1.0/Query/impressions/DisplayGetSpecificFilter"
        body.requestBody = {
            ...body.requestBody,
            "advertiser": name === 'advertiser' ? value : '',
            "insertion_order": name === 'insertion_order' ? value : '',
            "line_item": name === 'line_item' ? value : '',
            "creative_name": name === 'creative_name' ? value : '',
            "country": name === 'country' ? value : '',
            device:name === 'device' ? value : '',
            ad_unit:name === 'ad_unit' ? value : '',
            partner:name === 'partner' ? value : '',
            site:name === 'site' ? value : '',
        }
        axios.post("/v1.0/connectOP", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data) {
                    setFilterData({ ...filterData, ...res.data })
                    console.log(filterData)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    };

    const changeDMSelect = (e) => {
        setSelectAll(e.target.checked)
        setSelectAllDM({
            advertiser: e.target.checked,
            insertion_order: e.target.checked,
            line_item: e.target.checked,
            creative_name: e.target.checked,
            country: e.target.checked,
            device:e.target.checked,
            ad_unit:e.target.checked,
            partner:e.target.checked,
            site:e.target.checked,
        })
    }

    const changeSelection = (e) => {
        setSelectAll(false)
        const { name, value } = e.target;
        setSelectAllDM(selectAllDM => ({ ...selectAllDM, [name]: e.target.checked }));
    }

    const changeRange = (e) => {
        setRange(e.target.value)
    }

    const changeDatePick = (event) => {
        console.log(event)
        console.log("start: ", event.startDate._d);
        console.log("end: ", event.endDate._d);
        fromDate = event.startDate._d.toISOString();
        toDate = event.endDate._d.toISOString();
        // setFromDate(event.startDate._d.toISOString());
        // setToDate(event.endDate._d.toISOString());
    }

    const { errorMsg, successMsg } = useSelector(state => ({
        errorMsg: state.proxyAdUnit.errorMsg,
        successMsg: state.proxyAdUnit.successMsg,
    }));

    console.log(errorMsg, successMsg)

    const { analyticalDisplayReports } = useSelector(state => ({
        analyticalDisplayReports: state.proxyAdUnit.analyticsDisplayRpt,
    }));

    console.log(analyticalDisplayReports)

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const analyticalDisplayReportsColumns = [
        {
            text: "No",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
    ];

    const createColoumn = () => {

    }

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );

    const { SearchBar } = Search;
    const pageOptions = {
        totalSize: analyticalDisplayReports.length,
        custom: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    };

    const defaultSorted = [
        {
            dataField: "requests", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        let DimArr = Object.values(selectAllDM)
        let MtcArr = Object.values(selectedMTC)
        if (!DimArr.includes(true) || !MtcArr.includes(true)) {
            toastr.warning('Please select atleast one Dimensions and Metrices')
        }
        else {
            if (fileName) {
                       // setLoader(true)
            loader = true

            let query = {
                "dimensions": selectAllDM,
                "startDate": moment(fromDate).format("YYYY-MM-DD"),
                "endDate": moment(toDate).format("YYYY-MM-DD"),
                "filters": filters,
                "metrices": selectedMTC,
                "range": range,
                "reportType": "BidServ Display Analytics Report",
                userName:userInfo.username,
                role:userInfo.userRole,
                "timeZone": "EST",
                fileName:fileName
            }
            console.log(query)
            dispatch(onGetAnalyticsDisplayReport({ ...query }));
            setReportOpen(true)
            }
        }
    }

    if (analyticalDisplayReports.length > 0) {
        let keys = [];
        for (var k in analyticalDisplayReports[0]) keys.push(k);
        if(keys.includes("hour")){
            analyticalDisplayReports.map((item, index) => {
                item.hour = parseInt(item.hour)
            })
        }
        console.log(keys)
        if (keys.includes("Date")) {
            analyticalDisplayReportsColumns.push({
                dataField: "Date",
                text: "Date",
                sort: true,
            })
        }
        if (keys.includes("Month")) {
            analyticalDisplayReportsColumns.push({
                dataField: "Month",
                text: "Month",
                sort: true,
            })
        }
        if (keys.includes("hour")) {
            analyticalDisplayReportsColumns.push({
                dataField: "hour",
                text: "Hour",
                sort: true,
            })
        }
        if (keys.includes("adv")) {
            analyticalDisplayReportsColumns.push({
                dataField: "adv",
                text: "Advertiser",
                sort: true,
                style:{wordBreak: 'break-word'}
            })
        }
        if (keys.includes("io")) {
            analyticalDisplayReportsColumns.push({
                dataField: "io",
                text: "Insertion Order",
                sort: true,
            })
        }
        if (keys.includes("line_item")) {
            analyticalDisplayReportsColumns.push({
                dataField: "line_item",
                text: "Line Item",
                sort: true,
                style:{wordBreak: 'break-word'}
            })
        }
        if (keys.includes("creative_name")) {
            analyticalDisplayReportsColumns.push({
                dataField: "creative_name",
                text: "Creative",
                sort: true,
                style:{wordBreak: 'break-word'}
            })
        }
        if (keys.includes("country")) {
            analyticalDisplayReportsColumns.push({
                dataField: "country",
                text: "Country",
                sort: true,
                style:{wordBreak: 'break-word'}
            })
        }
        if (keys.includes("device")) {
            analyticalDisplayReportsColumns.push({
                dataField: "device",
                text: "Device",
                sort: true,
                style:{wordBreak: 'break-word'}
            })
        }
        if (keys.includes("ad_unit")) {
            analyticalDisplayReportsColumns.push({
                dataField: "ad_unit",
                text: "Ad Unit",
                sort: true,
                style:{wordBreak: 'break-word'}
            })
        }
        if (keys.includes("partner")) {
            analyticalDisplayReportsColumns.push({
                dataField: "partner",
                text: "Publisher Partner",
                sort: true,
                style:{wordBreak: 'break-word'}
            })
        }
        if (keys.includes("site")) {
            analyticalDisplayReportsColumns.push({
                dataField: "site",
                text: "Site",
                sort: true,
                style:{wordBreak: 'break-word'}
            })
        }
        if (keys.includes("ad_requests")) {
            analyticalDisplayReportsColumns.push({
                dataField: "ad_requests",
                text: "Ad Requests",
                sort: true,
                style:{wordBreak: 'break-word'}
            })
        }
        if (keys.includes("viewable_requests")) {
            analyticalDisplayReportsColumns.push({
                dataField: "viewable_requests",
                text: "Viewable Requests",
                sort: true,
            })
        }
        if (keys.includes("impressions")) {
            analyticalDisplayReportsColumns.push({
                dataField: "impressions",
                text: "Impressions",
                sort: true,
            })
        }
        if (keys.includes("refreshed_impressions")) {
            analyticalDisplayReportsColumns.push({
                dataField: "refreshed_impressions",
                text: "Refreshed Impressions",
                sort: true,
            })
        }
        if (keys.includes("viewable_refreshed_impressions")) {
            analyticalDisplayReportsColumns.push({
                dataField: "viewable_refreshed_impressions",
                text: "Viewable Ref Impressions",
                sort: true,
            })
        }
        if (keys.includes("unfilled_impressions")) {
            analyticalDisplayReportsColumns.push({
                dataField: "unfilled_impressions",
                text: "Unfilled Impressions",
                sort: true,
            })
        }
        if (keys.includes("viewability")) {
            analyticalDisplayReportsColumns.push({
                dataField: "viewability",
                text: "Viewability",
                sort: true,
            })
        }
        if (keys.includes("clicks")) {
            analyticalDisplayReportsColumns.push({
                dataField: "clicks",
                text: "Clicks",
                sort: true,
            })
        }
        if (keys.includes("ctr")) {
            analyticalDisplayReportsColumns.push({
                dataField: "ctr",
                text: "CTR",
                sort: true,
            })
        }
        if (keys.includes("fill_rate")) {
            analyticalDisplayReportsColumns.push({
                dataField: "fill_rate",
                text: "Fill Rate",
                sort: true,
            })
        }

        loader = false
        console.log(analyticalDisplayReportsColumns)
    } else {
        loader = false
    }

    useEffect(() => {
        console.log(analyticalDisplayReports)

        if (analyticalDisplayReports !== null && analyticalDisplayReports.length > 0) {
            // setLoader(false)
            loader = false
            let totalRequest = 0
            analyticalDisplayReports.map((item, index) => {
                totalRequest = totalRequest + item.requests
            })
            setRequestTotal(totalRequest)
        } else {
            loader = false
        }

    }, [analyticalDisplayReports]);

    var CSVProp
    const CSVClick = () => {
        CSVProp.onExport();
    }

    const MyExportCSV = (csvprops) => {
        CSVProp = csvprops
        return (<></>);
    };

    const copyTable = () => {
        console.log(CSVProp, JSON.stringify(CSVProp))
        navigator.clipboard.writeText(CSVProp)
        // const elTable = document.querySelector('table');

        // let range, sel;

        // if (document.createRange && window.getSelection) {

        //   range = document.createRange();
        //   sel = window.getSelection();
        //   sel.removeAllRanges();

        //   try {
        //     range.selectNodeContents(elTable);
        //     sel.addRange(range);
        //   } catch (e) {
        //     range.selectNode(elTable);
        //     sel.addRange(range);
        //   }

        //   document.execCommand('copy');
        // }

        // sel.removeAllRanges();

        console.log('Element Copied! Paste it in a file')

    }

    const exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Proxy AdUnit Report";
        const headers = [];

        // const data = this.state.people.map(elt=> [elt.name, elt.profession]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("report.pdf")
    }

    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header /> */}
                <div className="page-content">
                    <MetaTags>
                        <title>Display Analytics Report | Bidsxchange</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Nav tabs className="nav-tabs-custom nav-justified" style={{width:'20pc',marginLeft:'auto'}}>
                            <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                active: customActiveTab === "1",
                                })}
                                onClick={() => {
                                toggleCustom("1");
                                }}
                            >
                                <span className="d-block d-sm-none">
                                <i className="fas fa-home"></i>
                                </span>
                                <span className="d-none d-sm-block">Report</span>
                            </NavLink>
                            </NavItem>
                            <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                active: customActiveTab === "2",
                                })}
                                onClick={() => {
                                toggleCustom("2");
                                }}
                            >
                                <span className="d-block d-sm-none">
                                <i className="far fa-user"></i>
                                </span>
                                <span className="d-none d-sm-block">Report List</span>
                            </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent
                            activeTab={customActiveTab}
                            className="p-3 text-muted"
                        >
                            <TabPane tabId="1">
                            <Row>
                                <Col sm="12">
                                {!reportOpen &&
                                    <Container fluid>
                                        {/* Render Breadcrumbs */}
                                        {/* style={{position:'absolute',top:'-3pc',width:'max-content'}} */}
                                        <div className=" d-sm-flex  justify-content-between" >
                                            <SupplyBreadCrumb title="Proxy" breadcrumbItem="Display Analytics Report Query " />
                                        </div>
                                        <Row>
                                            <div className="col-12">
                                                <Card>
                                                    <CardBody>
                                                        <Form onSubmit={handleSubmit}>
                                                            <Row style={{ padding: '1% 3%', }}>
                                                                <Col xs={4} >
                                                                    <div className="mb-3">
                                                                        <h5>SELECT DATE</h5> <br />
                                                                        <DateRangePickers datePick={changeDatePick} startDate={fromDate} endDate={toDate} />
                                                                    </div>
                                                                    <br />
                                                                    <h5>FILTERS</h5>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label">Advertiser</Label>
                                                                        <Input
                                                                            name="advertiser"
                                                                            type="select"
                                                                            className="form-select"
                                                                            value={filters.advertiser}
                                                                            onChange={changeFilter}
                                                                        >
                                                                            <option value=''>Select...</option>
                                                                            {filterData.advertisers.map((elem) => (
                                                                                <option key={elem} value={elem}>{elem}</option>
                                                                            ))}
                                                                        </Input>
                                                                    </div>
                                                            
                                                                    <div className="mb-3">
                                                                        <Label className="form-label">Insertion Order</Label>
                                                                        <Input
                                                                            name="insertion_order"
                                                                            type="select"
                                                                            className="form-select"
                                                                            value={filters.insertion_order}
                                                                            onChange={changeFilter}
                                                                        >
                                                                            <option value=''>Select...</option>
                                                                            {filterData.insertion_orders.map((elem) => (
                                                                                <option key={elem} value={elem}>{elem}</option>
                                                                            ))}
                                                                        </Input>
                                                                    </div>
                                                        
                                                                    <div className="mb-3">
                                                                        <Label className="form-label">Line Item </Label>
                                                                        <Input
                                                                            name="line_item"
                                                                            type="select"
                                                                            className="form-select"
                                                                            value={filters.line_item}
                                                                            onChange={changeFilter}
                                                                        >
                                                                            <option value=''>Select...</option>
                                                                            {filterData.line_items.map((elem) => (
                                                                                <option key={elem} value={elem}>{elem}</option>
                                                                            ))}
                                                                        </Input>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label">Creative</Label>
                                                                        <Input
                                                                            name="creative_name"
                                                                            type="select"
                                                                            className="form-select"
                                                                            value={filters.creative_name}
                                                                            onChange={changeFilter}
                                                                        >
                                                                            <option value=''>Select...</option>
                                                                            {filterData.creatives.map((elem) => (
                                                                                <option key={elem} value={elem}>{elem}</option>
                                                                            ))}
                                                                        </Input>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label">Country</Label>
                                                                        <Input
                                                                            name="country"
                                                                            type="select"
                                                                            className="form-select"
                                                                            value={filters.country}
                                                                            onChange={changeFilter}
                                                                        >
                                                                            <option value=''>Select...</option>
                                                                            {filterData.countries.map((elem) => (
                                                                                <option key={elem} value={elem}>{elem}</option>
                                                                            ))}
                                                                        </Input>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label">Device</Label>
                                                                        <Input
                                                                            name="device"
                                                                            type="select"
                                                                            className="form-select"
                                                                            value={filters.device}
                                                                            onChange={changeFilter}
                                                                        >
                                                                            <option value=''>Select...</option>
                                                                            {filterData.devices.map((elem) => (
                                                                                <option key={elem} value={elem}>{elem}</option>
                                                                            ))}
                                                                        </Input>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <Label>Ad Unit</Label>
                                                                        <Input
                                                                            name="ad_unit"
                                                                            type="select"
                                                                            className="form-select"
                                                                            value={filters.ad_unit}
                                                                            onChange={changeFilter}
                                                                        >
                                                                            <option value=''>Select...</option>
                                                                            {filterData.ad_units.map((elem) => (
                                                                                <option key={elem} value={elem}>{elem}</option>
                                                                            ))}
                                                                        </Input>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label">Publisher Partner </Label>
                                                                        <Input
                                                                            name="partner"
                                                                            type="select"
                                                                            className="form-select"
                                                                            value={filters.partner}
                                                                            onChange={changeFilter}
                                                                        >
                                                                            <option value=''>Select...</option>
                                                                            {filterData.partners.map((elem) => (
                                                                                <option key={elem} value={elem}>{elem}</option>
                                                                            ))}
                                                                        </Input>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label">Site</Label>
                                                                        <Input
                                                                            name="site"
                                                                            type="select"
                                                                            className="form-select"
                                                                            value={filters.site}
                                                                            onChange={changeFilter}
                                                                        >
                                                                            <option value=''>Select...</option>
                                                                            {filterData.sites.map((elem) => (
                                                                                <option key={elem} value={elem}>{elem}</option>
                                                                            ))}
                                                                        </Input>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label">File Name</Label>
                                                                        <Input
                                                                            name="fileName"
                                                                            type="text"
                                                                            value={fileName}
                                                                            onChange={(e)=> setFileName(e.target.value)}
                                                                            className={'form-control' + (submitted && !fileName ? ' is-invalid' : '')}
                                                                        >
                                                                        </Input>
                                                                        {submitted && !fileName ? (
                                                                            <FormFeedback type="invalid">{'File Name is required'}</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                                <Col xs={4} style={{ padding: '0% 3%', }}>

                                                                    <div className="mb-3">
                                                                        <h5> SELECT RANGE</h5><br />
                                                                        <Input
                                                                            name="range"
                                                                            type="select"
                                                                            className="form-select"
                                                                            onChange={changeRange}
                                                                            value={range}
                                                                        >
                                                                            <option value=''>Select</option>
                                                                            <option value='Month'>Month</option>
                                                                            <option value='Day'>Day</option>
                                                                            <option value='Cumulative'>Cumulative</option>
                                                                        </Input>
                                                                    </div>
                                                                    <br />
                                                                    <h5 className="mb-3 ">DIMENSIONS</h5>
                                                                    <div className="form-check mt-2 mb-4">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={selectAll}
                                                                            onChange={changeDMSelect}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                        >
                                                                            Select All
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mt-2 mb-4">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={selectAllDM.advertiser}
                                                                            name="advertiser"
                                                                            onChange={changeSelection}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                        >
                                                                            Advertiser
                                                                        </label>
                                                                    </div>
                                                    
                                                                    <div className="form-check mt-2 mb-4">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={selectAllDM.insertion_order}
                                                                            name="insertion_order"
                                                                            onChange={changeSelection}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                        >
                                                                            Insertion Order
                                                                        </label>
                                                                    </div>
                                                        
                                                                    <div className="form-check mt-2 mb-4">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={selectAllDM.line_item}
                                                                            name="line_item"
                                                                            onChange={changeSelection}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                        >
                                                                            Line Item 
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mt-2 mb-4">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={selectAllDM.creative_name}
                                                                            name="creative_name"
                                                                            onChange={changeSelection}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                        >
                                                                            Creative 
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mt-2 mb-4">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={selectAllDM.country}
                                                                            name="country"
                                                                            onChange={changeSelection}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                        >
                                                                            Country
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mt-2 mb-4">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={selectAllDM.device}
                                                                            name="device"
                                                                            onChange={changeSelection}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                        >
                                                                            Device
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mt-2 mb-4">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={selectAllDM.ad_unit}
                                                                            name="ad_unit"
                                                                            onChange={changeSelection}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                        >
                                                                            Ad Unit
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mt-2 mb-4">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={selectAllDM.partner}
                                                                            name="partner"
                                                                            onChange={changeSelection}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                        >
                                                                            Publisher Partner
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mt-2 mb-4">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={selectAllDM.site}
                                                                            name="site"
                                                                            onChange={changeSelection}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                        >
                                                                            Site
                                                                        </label>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={4} style={{ padding: '0 3%', }}>
                                                                    <div className="mb-3">
                                                                        <h5> SELECT Time Zone</h5><br />
                                                                        <Input
                                                                            name="timeZone"
                                                                            type="select"
                                                                            className="form-select"
                                                                            defaultValue={'EST'}
                                                                        >
                                                                            <option value='' disabled>Select</option>
                                                                            <option value='EST'>EST</option>
                                                                        </Input>
                                                                    </div>
                                                                    <br />
                                                                    <h5 className="mb-3">METRICES</h5>
                                                                    <div className="form-check mt-2 mb-4">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={selectAllMTC}
                                                                            onChange={changeMTCSelect}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                        >
                                                                            Select All
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mt-2 mb-4">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={selectedMTC.ad_requests}
                                                                            name="ad_requests"
                                                                            onChange={changeSelectionMTC}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                        >
                                                                            Ad Requests
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mt-2 mb-4">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={selectedMTC.viewable_requests}
                                                                            name="viewable_requests"
                                                                            onChange={changeSelectionMTC}
                                                                            disabled
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                        >
                                                                        Viewable Requests
                                                                        </label>
                                                                    </div>

                                                                    <div className="form-check mt-2 mb-4">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={selectedMTC.impressions}
                                                                            name="impressions"
                                                                            onChange={changeSelectionMTC}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                        >
                                                                            Impressions
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mt-2 mb-4">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={selectedMTC.refreshed_impressions}
                                                                            name="refreshed_impressions"
                                                                            onChange={changeSelectionMTC}
                                                                            disabled
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                        >
                                                                            Refreshed Impressions
                                                                        </label>
                                                                    </div>

                                                                    <div className="form-check mt-2 mb-4">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={selectedMTC.viewable_refreshed_impressions}
                                                                            name="viewable_refreshed_impressions"
                                                                            onChange={changeSelectionMTC}
                                                                            disabled
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                        >
                                                                            Viewable Ref Impressions
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mt-2 mb-4">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={selectedMTC.unfilled_impressions}
                                                                            name="unfilled_impressions"
                                                                            onChange={changeSelectionMTC}
                                                                            disabled
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                        >
                                                                        Unfilled Impressions
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mt-2 mb-4">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={selectedMTC.viewability}
                                                                            name="viewability"
                                                                            onChange={changeSelectionMTC}
                                                                            disabled
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                        >
                                                                            Viewability
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mt-2 mb-4">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={selectedMTC.clicks}
                                                                            name="clicks"
                                                                            onChange={changeSelectionMTC}
                                                                            disabled
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                        >
                                                                            Clicks
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mt-2 mb-4">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={selectedMTC.ctr}
                                                                            name="ctr"
                                                                            onChange={changeSelectionMTC}
                                                                            disabled
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                        >
                                                                            CTR
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mt-2 mb-4">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={selectedMTC.fill_rate}
                                                                            name="fill_rate"
                                                                            onChange={changeSelectionMTC}
                                                                            disabled
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                        >
                                                                            Fill Rate
                                                                        </label>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <div className=" text-sm-end">
                                                                        <button
                                                                            type="submit"
                                                                            className="btn btn-primary save-user"
                                                                        >
                                                                            Run Report
                                                                        </button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        </Row>
                                    </Container>
                                }
                                {reportOpen &&
                                    <Container fluid={true}>
                                        <div className=" d-sm-flex  justify-content-between" >
                                            <SupplyBreadCrumb title="Report" breadcrumbItem="Display Analytical Report "  />
                                            <div className=" text-sm-end">
                                                <div className="btn-group">
                                                    <Dropdown
                                                        isOpen={btnprimary1}
                                                        toggle={() => setBtnprimary1(!btnprimary1)}
                                                    >
                                                        <DropdownToggle tag="button" className="btn btn-primary">
                                                            Export
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem onClick={copyTable}>Copy</DropdownItem>
                                                            <DropdownItem onClick={CSVClick}>CSV</DropdownItem>
                                                            <DropdownItem onClick={exportPDF}>PDF</DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </div>
                                                &nbsp;&nbsp;&nbsp;
                                                <Button
                                                    color="primary"
                                                    className="font-16 btn-block btn btn-primary"
                                                    onClick={(e) => setReportOpen(false)}
                                                >
                                                    <i className="mdi mdi-plus-circle-outline me-1" />
                                                    Modify Report
                                                </Button>
                                            </div>
                                        </div>
                                        <Row>
                                            <Col lg="12">
                                                <Card>
                                                    <CardBody aria-hidden="true">
                                                        {loader &&
                                                            <>
                                                                <Spinner className="ms-2 loader" color="primary" />
                                                                <br />
                                                                <h5 className="text-center mt-5" style={{ padding: '15%', fontSize: '25px' }}></h5>
                                                                <br />
                                                            </>
                                                        }
                                                        {analyticalDisplayReports.length === 0 && !loader &&
                                                            <h5 className="text-center" style={{ padding: '15%', fontSize: '25px' }}>No Data</h5>
                                                        }
                                                        {analyticalDisplayReports.length > 0 && !loader &&
                                                            <PaginationProvider
                                                                pagination={paginationFactory(pageOptions)}
                                                                keyField={keyField}
                                                                data={analyticalDisplayReports}
                                                                columns={analyticalDisplayReportsColumns}
                                                            >
                                                                {({ paginationProps, paginationTableProps }) => {
                                                                    return (
                                                                        <ToolkitProvider
                                                                            keyField={keyField}
                                                                            data={analyticalDisplayReports}
                                                                            columns={analyticalDisplayReportsColumns}
                                                                            // bootstrap4
                                                                            search
                                                                            exportCSV={{
                                                                                ignoreFooter: false
                                                                            }}
                                                                        >
                                                                            {toolkitProps => (
                                                                                <React.Fragment>
                                                                                    <Row className="mb-2">
                                                                                        <Col sm="8">
                                                                                            <div className="search-box ms-2 mb-2 d-inline-block">
                                                                                                <div className="position-relative">
                                                                                                    <SearchBar {...toolkitProps.searchProps} />
                                                                                                    <i className="bx bx-search-alt search-icon" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col sm="1">
                                                                                            <MyExportCSV {...toolkitProps.csvProps} />
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col xl="12">
                                                                                            <div className="table-responsive">
                                                                                                <BootstrapTable
                                                                                                    keyField={keyField}
                                                                                                    responsive
                                                                                                    bordered={false}
                                                                                                    striped={false}
                                                                                                    defaultSorted={defaultSorted}
                                                                                                    classes={
                                                                                                        "table align-middle table-nowrap"
                                                                                                    }
                                                                                                    headerWrapperClasses={"thead-light"}
                                                                                                    {...toolkitProps.baseProps}
                                                                                                    {...paginationTableProps}
                                                                                                    noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                                                // pagination={paginationFactory(pageOptions)}
                                                                                                // ref={node}
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row className="align-items-md-center mt-30">
                                                                                        <Col className="inner-custom-pagination d-flex">
                                                                                            <div className="d-inline">
                                                                                                <PaginationTotalStandalone
                                                                                                    {...paginationProps}
                                                                                                />
                                                                                            </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                            <div className="text-md-center d-inline " style={{ marginLeft: '5%' }} >
                                                                                                <span>Show Rows : </span> &nbsp;&nbsp;
                                                                                                <SizePerPageDropdownStandalone
                                                                                                    {...paginationProps}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="text-md-right ms-auto">
                                                                                                <PaginationListStandalone
                                                                                                    {...paginationProps}
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </React.Fragment>
                                                                            )}
                                                                        </ToolkitProvider>
                                                                    );
                                                                }}
                                                            </PaginationProvider>
                                                        }
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Container>
                                }
                                </Col>
                            </Row>
                            </TabPane>
                            <TabPane tabId="2">
                            <Row>
                                <Col sm="12">
                                    <ReportQueryHistory />
                                </Col>
                            </Row>
                            </TabPane>
                        </TabContent>
                    </Container>


                </div>
        </React.Fragment>
    )
}


export default Display_Analytics